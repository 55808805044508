/**
 * @description A static class for initializing the 1DS analytis telemetry capturing.
 * Learn more about 1DS Web Analytics at: https://martech.azurewebsites.net/website-tools/oneds/get-started#add1ds
 */

import { OneDSActionTypes } from "Shared/constants";

/**
 * Type for 1ds setup configuration
 */
interface IOneDsConfig {
    AppId: string;
    TenantKey: string;
    Locale:string
}

/**
 * @description configurating for 1ds
 * {AppId} Biz Apps Demo hub app id whitelisted with 1ds team
 * {TenantKey} Unique Tennat key generated by 1DS team for Biz Apps Demo Hub
 */
const OneDsConfig: IOneDsConfig = {
    AppId: 'bizAppsDemoHub',
    TenantKey: '667be5c6b73e4cf1a451b73f6b6dbd1b-c8eba0a5-f525-443a-81fa-295002eee9e1-7146',
    Locale:'en-us' //TODO: to be changed in future. Site supports en-us only for now.
}

/**
 * @description Static class for initializing the One DS capturing for the portal.
 */
export class OneDsAnalyticaProvider {

    private static analytics: any = new window.oneDS.ApplicationInsights();    

    private static GetConfig(isAutheticated:boolean): any {

        let config = {
            instrumentationKey: OneDsConfig.TenantKey,
            webAnalyticsConfiguration: {
                coreData: {
                    appId: OneDsConfig.AppId,
                    market: OneDsConfig.Locale
                },
                isLoggedIn: isAutheticated,
                urlCollectQuery: true,
                autoCapture: {
                    scroll: true,
                    pageView: true,
                    onLoad: true,
                    onUnload: true,
                    click: true,
                    resize: true,
                    jsError: true
                }
            }
        }

        return config;
    };    

    public static Initialize(isAutheticated: boolean = false) {
        this.analytics.initialize(this.GetConfig(isAutheticated), []);
    }

    public static CapturePageAction(event: EventTarget | null, actionType: string | undefined) {
        if (!actionType) {
            actionType = OneDSActionTypes.ClickLeft;
        }
        let overrideValues = { actionType };
        this.analytics.capturePageAction(event, overrideValues);
    }
}
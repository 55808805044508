export enum RequestStatus {
    OK = 200,
    ACCEPTED = 202,
    CREATED = 201,
    NOCONTENT = 204,
    UNAUTHORIZED = 401,
    BADREQUEST = 400,
    FORBIDDEN = 403,
    ERROR = 500,
    DEMOACCOUNTMISMATCH = 309,
    BAPAPIFAILED = 310,
    TENANTUSERSVALIDATIONFAILED = 311
}
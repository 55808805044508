import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';

// TODO: Make this generic to be used in other places as well.
export const generateExcelFromJSON = (jsonData: any): void => {
    // const wb = XLSX.utils.book_new();
     const fileName = jsonData?.entityName;

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    addReadmeSheet(workbook);

    addExcelJsSheet(workbook, jsonData?.entityName, jsonData?.dataGenerated, jsonData);

    // Write the workbook to a buffer
    workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
        // Convert the buffer to a Blob
        const blob = new Blob([buffer], { type: 'application/octet-stream' });

        // Save the Blob as a file using FileSaver.js
        FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });
}

// Function to convert string to ArrayBuffer
function s2ab(s: string): ArrayBuffer {
    const buf: ArrayBuffer = new ArrayBuffer(s.length);
    const view: Uint8Array = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

/**
 * 
 * This method is to add a Readme sheet that will help TS to know the instructions for importing the data into dataverse
 * @param workbook Workbook object
 */
const addReadmeSheet = (workbook: ExcelJS.Workbook) => {
    let readmeSheetName = 'Readme';
    let readMeSheetData = [
        { StepNo: '1', Description: 'The data in the excel contains information for the entities you selected to export, along with their dependent entities.' },
        { StepNo: '2', Description: 'Review the columns in the exported Excel file. Each sheet in the workbook corresponds to an entity, and the columns correspond to the attributes of that entity.' },
        { StepNo: '3', Description: 'Go to your Dataverse environment. Navigate to the entity you want to import data into and export it into an Excel file. This will serve as your template.' },
        { StepNo: '4', Description: 'Open the template file and the exported file side by side. Copy the data from the exported file to the template file, making sure to match the column names.' },
        { StepNo: '5', Description: 'Once you have copied all the data, save the template file.' },
        { StepNo: '6', Description: 'Go back to your Dataverse environment. Navigate to the entity you want to import data into and select the option to import data.' },
        { StepNo: '7', Description: 'Upload the template file with the copied data. Follow the prompts to complete the import process.' },
        { StepNo: '8', Description: 'Repeat the process for each entity you want to import data into.' },
        { StepNo: '9', Description: 'After importing, verify the data in your Dataverse environment to ensure it has been imported correctly.' }
    ];

    // const ws = XLSX.utils.json_to_sheet(readMeSheetData);
    // XLSX.utils.book_append_sheet(workbook, ws, readmeSheetName);

     // Add a worksheet to the workbook
     const worksheet = workbook.addWorksheet("ReadMe");  // Add a worksheet to the workbook

     // Convert JSON data to an array of arrays
     const rows = jsonToRows(readMeSheetData);

     // Add rows to the worksheet
     rows.forEach((row, rowIndex)  => {
         const worksheetRow = worksheet.addRow(row);
         // Apply styles to headers (first row)
         if (rowIndex === 0) {
             worksheetRow.eachCell((cell1: ExcelJS.Cell) => {
                 cell1.fill = {
                     type: 'pattern',
                     pattern: 'solid',
                     fgColor: { argb: '0A3463' } // Dark blue fill color
                 };
                 cell1.font = {
                     color: { argb: 'FFFFFF' } // White text color
                 };
             });
         }
     });

     // Set column widths
    worksheet.columns.forEach((column1: Partial<ExcelJS.Column>) => {
        column1.width = 20;
    });

}

// Function to add custom styles to the first row of a worksheet
const addStylesToFirstRow = (worksheet: XLSX.WorkSheet) => {
    // Create a new style for the first row
    const style = {
        fill: { fgColor: { rgb: "000080" } }, // Dark blue fill color
        font: { color: { rgb: "FFFFFF" } } // White text color
    };

    // Get the range of the first row
    const range = XLSX.utils.decode_range(worksheet['!ref'] || '');
    const firstRowRange = {
        s: { c: range.s.c, r: 0 }, // Start from the first column and first row
        e: { c: range.e.c, r: 0 }  // End at the last column and first row
    };

    // Apply the style to each cell in the first row
    for (let C = firstRowRange.s.c; C <= firstRowRange.e.c; ++C) {
        const address = XLSX.utils.encode_cell({ c: C, r: 0 }); // Column C, Row 0
        const cell = worksheet[address];
        if (!cell) continue; // Skip if the cell is empty
        cell.s = style;
    }
};

const addSheet = (workbook: XLSX.WorkBook, sheetName: any, sheetData: any, linkedSheetData?: any) => {
    try {
        let entityData = sheetData ? JSON.parse(sheetData) : {
            Entities: [
                { NoData: 'No data found for this entity' }
            ]
        };
        let sheetTableData = entityData.Entities;
        const ws = XLSX.utils.json_to_sheet(sheetTableData, {header: [], skipHeader: false});

        // Set the width of each column
        ws['!cols'] = Array(Object.keys(sheetTableData[0]).length).fill({wch: 20});

        // Highlight the first row with dark blue
        const range = XLSX.utils.decode_range(ws['!ref'] || '');
        console.log('Range:', range);
        for(let C = range.s.c; C <= range.e.c; ++C) {
            const address = XLSX.utils.encode_col(C) + "1"; // row 1
            console.log('Address:', address);
            if(!ws[address]) continue;
            ws[address].s = {
                patternType: "solid",
                fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "000080" },
                bgColor: { indexed: 64 },
                font: { rgb: "FFFFFF", bold: true }
            };
            console.log('Cell style:', ws[address].s);
        }

        XLSX.utils.book_append_sheet(workbook, ws, sheetName);

        // Apply styles to the first row of the worksheet
        addStylesToFirstRow(ws);

        // add nested sheet
        if (linkedSheetData?.linkedDataGenerated) {
            const linkedEntityData = linkedSheetData?.linkedDataGenerated;
            for (let i = 0; i < linkedEntityData.length; i++) {
                addSheet(workbook, linkedEntityData[i]?.entityName, linkedEntityData[i]?.dataGenerated);
            }
        }

    } catch (e) {
        // TODO: log error
    }
}

const addExcelJsSheet = (workbook: ExcelJS.Workbook, sheetName: any, sheetData: any, linkedSheetData?: any) => {
    try {
        let entityData = sheetData ? JSON.parse(sheetData) : {
            Entities: [
                { NoData: 'No data found for this entity' }
            ]
        };
        let sheetTableData = entityData.Entities;
        // Add a worksheet to the workbook
        const worksheet = workbook.addWorksheet(sheetName);  // Add a worksheet to the workbook

        // Convert JSON data to an array of arrays
        const rows = jsonToRows(sheetTableData);

        // Add rows to the worksheet
        rows.forEach((row, rowIndex)  => {
            const worksheetRow = worksheet.addRow(row);
            // Apply styles to headers (first row)
            if (rowIndex === 0) {
                worksheetRow.eachCell(cell => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '0A3463' } // Dark blue fill color
                    };
                    cell.font = {
                        color: { argb: 'FFFFFF' } // White text color
                    };
                });
            }
        });

        // Set column widths
        worksheet.columns.forEach(column => {
            column.width = 20;
        });

        // add nested sheet
        if (linkedSheetData?.linkedDataGenerated) {
            const linkedEntityData = linkedSheetData?.linkedDataGenerated;
            for (let i = 0; i < linkedEntityData.length; i++) {
                addExcelJsSheet(workbook, linkedEntityData[i]?.entityName, linkedEntityData[i]?.dataGenerated, linkedEntityData[i]);
            }
        }

    } catch (e) {
        // TODO: log error
    }
}


// Function to convert JSON data to an array of arrays
const jsonToRows = (jsonData: any[]) => {
    const rows: any[] = [];
    // Get headers from the first object
    const headers = Object.keys(jsonData[0]).map(header => header.replace(/-LOOKUP/g, '').replace(/\\/g, ''));
    // Add headers as the first row
    rows.push(headers);
    // Add data rows
    jsonData.forEach(obj => {
        const row: any[] = [];
        headers.forEach(header => {
            // CodeQL [SM02383] False Positive: CodeQL wrongly detected. Already applied Global flag for the replace as per the mentioned TSG.
            let value = obj[header.replace(/\//g, '\\/')];
            row.push(value);
        });
        rows.push(row);
    });
    return rows;
};
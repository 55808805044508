import { createContext, useContext, PropsWithChildren } from 'react';
import { useGlobalContextProvider } from './useGlobalContextProvider';

export type GlobalContextType = {
    roles: string[];
    featureFlags: string[];
    isUserRolesLoading: boolean;
    isContentLoading: boolean;
    validAccess: boolean;
    error: Error | null;
};

const GlobalContext = createContext<GlobalContextType>({
    roles: [],
    featureFlags: [],
    isUserRolesLoading: true,
    isContentLoading: true,
    validAccess: false,
    error: null
});

export const useGlobalContext = () => useContext(GlobalContext);

type GlobalContextProviderProps = PropsWithChildren<{}>;

export function GlobalContextProvider({ children }: GlobalContextProviderProps): React.ReactElement {
    const { roles, featureFlags, isUserRolesLoading, isContentLoading, validAccess, error} = useGlobalContextProvider();

    return (
        <GlobalContext.Provider value={{ roles, featureFlags, isUserRolesLoading, isContentLoading, validAccess, error }}>
            {children}
        </GlobalContext.Provider>
    );
};
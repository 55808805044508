import { AuthProvider } from 'Providers/Authentication';
import { createEnableMocksFn } from 'Shared/utils/mock-utilities';
import { apiSuccessResponse, apiFailureResponse, getErrorResponse } from '../base/getApiResponse';
import { ApiName } from '../base/enums';
import { ApiResult } from '../base/models';
import { create } from '../base/client';
import { UserContract } from './userSvcContract';

const client = create(
    ApiName.Users,
    createEnableMocksFn(async () => import('./userSvc.mock'))
);

const tenantAllowList: Set<string | undefined> = new Set<string>();
process.env.REACT_APP_TENANT_ALLOW_LIST?.split(',').forEach(tenantId => tenantAllowList.add(tenantId));

export class UserService implements UserContract {
    registerSeller = async (): Promise<ApiResult<boolean>> => {
        try {
            let apiEndpoint = 'users/validateseller';
            const response = await client.get(apiEndpoint);
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }

    isWhitelistedTenantUser = (): boolean => {
        var currentUser = AuthProvider.getActiveAccount();
        return tenantAllowList.has(currentUser?.tenantId);
    }
}
import { Routes, Route } from 'react-router-dom';
import {
    HomePage,
    NewDemoRequest,
    FAQs,
    NotFound,
    Unauthorized,
    AnnouncementsPage,
    Error,
    pageRoutes,
    DemoRequests,
    DemoList,
    AllDemoList,
    TemplateDetailPage,
    CopilotAssetsPage
} from 'Pages';
import { AuthorizationProvider } from 'Providers/Authorization/AuthorizationProvider';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<AuthorizationProvider />}>
                <Route path={pageRoutes.home} element={<HomePage />} />
                <Route path={pageRoutes.demos.root} element={<DemoRequests />} >
                    <Route path={pageRoutes.demos.mydemos} element={<DemoList />} /> {/*needed for default /demos sub navigation route for first tab (mydemos) */}
                    <Route path={pageRoutes.demos.allDemos} element={<AllDemoList />} />
                </Route>
                <Route path={pageRoutes.newDemo} element={<NewDemoRequest />} />
                <Route path={pageRoutes.faq} element={<FAQs />} />
                <Route path={pageRoutes.announcements} element={<AnnouncementsPage />} />
                <Route path={pageRoutes.templateDetail} element={<TemplateDetailPage />} />
                <Route path={pageRoutes.survey} element={<HomePage />} />
                <Route path={pageRoutes.copilotAssets} element={<CopilotAssetsPage />} />
            </Route>
            {/* unauthorized */}
            <Route path={pageRoutes.unauthorized} element={<Unauthorized />} />
            {/* error */}
            <Route path={pageRoutes.error} element={<Error />} />
            {/* catch all */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
import { PrimaryButton } from '@fluentui/react';
import { useNavigate, useLocation } from 'react-router-dom'
import styles from './systempages.module.scss';
import * as Constants from 'Shared/constants';
import { pageRoutes } from 'Pages';

export const Error = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    // const message = state?.errorModel?.message || 'Something went wrong. Please try again later.';
    const message = 'Something went wrong. Please try again later.';

    // Append correlation id to the error message
    const correlationId = `CorrelationId: ${state?.errorModel?.correlationId || sessionStorage.getItem(Constants.CORRELATION_ID)}`;

    const handleBackButtonClick = () => {
        navigate(pageRoutes.home);
    };

    return (
        <div style={{ backgroundImage: "url('/images/home/systempage.png')", backgroundRepeat: 'no-repeat' }} className={styles.sytemPagesContainer}>
            <div>
                <h1>
                    500
                </h1>
            </div>
            <div className={styles.message}>
                <h3>{message}</h3>
                <span>{correlationId}</span>
            </div>
            <div>
                <PrimaryButton text="Back to Home page" id='btnBackToHomeFromError'
                    allowDisabledFocus
                    onClick={handleBackButtonClick}
                />
            </div>
        </div>
    )
};
import {
    IStackTokens
} from '@fluentui/react';
import { Template } from 'Models';
import { IFormModel } from 'Shared/hooks/useForm';
import {
    DemoRequest,
    DemoRequestSvc,
} from 'Services';
import { NewDemoRequestFields, operationName } from 'Shared/constants';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { logAndSetApiSuccessMetrics, logErrorAndSetApiFailureMetrics } from 'Shared/telemetry/telemetryHelper';
import { telemetryMessages } from 'Services/base/telemetryMessages';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { ErrorCode, Metrics } from './telemetry';

interface ValidationRule {
    demoAdminEmail: RegExp;
}

interface INewRequestformValidationFields {
    tenantUserName?: string;
    demoEnvironmentName?: string;
    userConsent?: string;
}

const bypassValidTenantValidation: string | undefined = process.env.REACT_APP_BYPASS_ALLOWED_DEMO_TS_TENANT;

const validationRule: ValidationRule = {
    demoAdminEmail: new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'), // valid email 
}

export const MIN_LENGTH: number = 2;
export const MAX_LENGTH: number = 100;

export const successCodes = {
    ok: 200,
    accepted: 202
}

export const errorCodes = {
    badRequest: 400,
    forbidden: 403
}

export const dependencyErrorCodes = {
    bapApiValidationFailed: 'BapApiValidationFailed',
    adminAccountMismatch: 'DemoAccountMismatch',
    tenantUsersValidationFailed: 'TenantUsersValidationFailed'
}

export const errorTypes = {
    badRequest: 'BAD_REQUEST',
    unauthorized: 'UNAUTHORIZED',
    notFound: 'NOT_FOUND',
    contextSwitchFailure: 'CONTEXT_SWITCH_FAILURE',
    adminAccountValidation: 'ADMIN_ACCOUNT_VALIDATION',
    bapApiValidation: 'DEMO_REQUEST_VALIDATION'
}

export interface NewDemoFormState {
    formReady?: boolean;
    selectedTemplate?: Template;
    selectedDemoRequest?: DemoRequest | null;
}

export interface FormValidationState {
    allowedTenantsForTemplate?: string[];
    tenantsList?: string[];
    environmentNameRegularExpress?: RegExp;
    environmentNameErrorMessage?: string;
}

export interface FormSubmissionState {
    serverError?: any;
    isValidFormInput?: boolean;
    validatingDemoRequest?: boolean;
    demoProvisioningError?: DemoProvisioningError;
}

export interface DemoProvisioningError {
    errorType?: string;
    errorMessage: string;
}

export interface RequestForm {
    selectedTemplate?: Template;
    tenantUserName: string;
    demoEnvironmentName: string;
    userConsent?: boolean; // User consent is not needed for now, this is just a placeholder to be used in future.
}

export const formModel: IFormModel = {
    props: (): RequestForm => ({
        tenantUserName: '',
        demoEnvironmentName: '',
        userConsent: true
    })
}

export const getDemoProvisioningError = (type: string, message: string): DemoProvisioningError => {
    const error: DemoProvisioningError = {
        errorType: type,
        errorMessage: message
    }
    return error;
}

export const getDemoRequestId = () => {
    return window.location.hash.replace('#', ''); // TODO: Implement it in a better way
}

export const getDemoRequest = async () => {
    let telemetryProps: TelemetryProperties = {
        operationName: operationName.DemoRequestSvcGetDemoRequestById,
        exceptionType: ExceptionType.DemoServiceException,
        defaultErrorCode: ErrorCode.FailedToGetDemoRequestById
    }

    const demoRequestId = getDemoRequestId();

    if (demoRequestId && demoRequestId.length) {
        var demoRequest = await DemoRequestSvc.getDemoRequestById(demoRequestId);

        if (!demoRequest.hasError) {
            // Log trace and Set metrics
            logAndSetApiSuccessMetrics(demoRequest, { ...telemetryProps, message: telemetryMessages.getDemoRequestbyIdSuccess }, Metrics.DemoRequestByIdAPICounter);

            return demoRequest?.data;
        } else {
            // Log exception and metrics
            logErrorAndSetApiFailureMetrics(demoRequest, { ...telemetryProps, message: telemetryMessages.getDemoRequestbyIdError }, Metrics.DemoRequestByIdAPIFailure);

            return;
        }
    }
    return null;
}

export const validateNewRequestForm = (field: string, values: any, formValidationState: FormValidationState, errors: any) => {
    let allValidation: INewRequestformValidationFields = {
        ...errors
    }

    if (field === NewDemoRequestFields.demoEnvironmentName || !field) {
        allValidation.demoEnvironmentName = getEnvironmentNameValidationMessage(values.demoEnvironmentName, formValidationState);
    }
    if (field === NewDemoRequestFields.tenantUserName || !field) {
        allValidation.tenantUserName = getDemoAdminValidationMessage(values.tenantUserName, validationRule.demoAdminEmail, formValidationState);
    }
    if (field === NewDemoRequestFields.userConsent || !field) {
        allValidation.userConsent = values.userConsent ? '' : 'Required.';
    }
    return allValidation;
}

export const stackTokens: IStackTokens = { childrenGap: 16 };

export const dependencyErrCodeMapping =
{
    [dependencyErrorCodes.adminAccountMismatch]: ErrorCode.ProvisionDemoAdminAccountMismatch,
    [dependencyErrorCodes.bapApiValidationFailed]: ErrorCode.ProvisionDemoBapApiValidationFailed,
    [dependencyErrorCodes.tenantUsersValidationFailed]: ErrorCode.ProvisionDemoTenantUsersValidationFailed
}

const getEnvironmentNameValidationMessage = (input: string, formvalidationState: FormValidationState) => {
    let commonValidationCheck = validateCommonRules(input);

    if (commonValidationCheck) {
        return commonValidationCheck;
    }

    if (input.trim().length < MIN_LENGTH) {
        return `Minimum characters length should be: ${MIN_LENGTH}`;
    }

    if (input.trim().length > MAX_LENGTH) {
        return `Maximum ${MAX_LENGTH} are allowed.`;
    }

    if (!formvalidationState.environmentNameRegularExpress) {
        return formvalidationState.environmentNameErrorMessage;
    }
    else {
        return formvalidationState.environmentNameRegularExpress.test(input.replace(/\s/g, ""))
            ? '' : formvalidationState.environmentNameErrorMessage;
    }
}

const getDemoAdminValidationMessage = (input: string, rule: RegExp, formvalidationState: FormValidationState) => {
    let commonValidationCheck = validateCommonRules(input);

    if (commonValidationCheck) {
        return commonValidationCheck;
    }

    if (!rule.test(input)) {
        return messages.demoAdminInvalidEmailValidationErrorMessage;
    }

    if (bypassValidTenantValidation?.toLowerCase() === 'false') {
        let tenantDomainName = input.trim().toLowerCase().split('@')[1].split('.')[0];

        if (!formvalidationState.tenantsList || !formvalidationState.tenantsList.find(x => tenantDomainName.startsWith(x.toLowerCase()))) {
            return messages.demoAdminTenantValidationErrorMessage;
        }

        if (!formvalidationState.allowedTenantsForTemplate || !formvalidationState.allowedTenantsForTemplate?.find(x => tenantDomainName.startsWith(x.toLowerCase()))) {
            return messages.demoAdminAllowedTenantForTemplateValidationErrorMessage;
        }
    }
    return '';
}

const validateCommonRules = (input: string) => {

    if (!input || input.trim().length === 0) {
        return 'Required';
    }
}

export const messages = {
    environmentNameValidationErrorMessage: 'Please use only letters, numbers, and certain special characters (- or _) when entering your environment name.',
    demoAdminInvalidEmailValidationErrorMessage: 'Please enter a valid email address.',
    demoAdminAllowedTenantForTemplateValidationErrorMessage: 'The tenant information provided is not valid or supported by this template.',
    demoAdminTenantValidationErrorMessage: 'Please enter a valid username of your tenant (example: admin@D365DemoTsxxxxx.onmicrosoft.com, admin@D365DemoPPxxxxx.onmicrosoft.com).',
    adminAccountMismatch: 'The signed-in account {signedin-account} does not match with the current demo request admin account. Please sign off and try again by signing-in using the {demo-request-admin} account.',
    invalidDemoRequest: 'No record found for the given Demo Request id',
    demoFormFailingtoLoad: 'An error occurred while initializing the new demo provisioning form',
    demoFromSubmissionFailed: 'An error occurred while submitting the demo provisioning request',
    accessRequestSubmissionFailed: 'An error occurred while submitting the access request for shared demo environment',
    switchingUserContextError: 'An error occurred while trying to sign in using tenant admin credentials. Please try again.',
    switchingUserContextErrorForLogging: 'Sign in popup was closed manually.',
    switchingUserContextSuccess: 'Successfully signed in using tenant admin credentials.',
    badRequestErrorMessage: 'Unexpected error occurred while processing your demo provisioning request. Please validate the provided input, and try again.',
    loaderNewFormLoading: 'Loading New form...',
    loaderClickthroughRequestSubmission: 'Submitting request to Pin clickthrough. Please wait...',
    loaderRequestSubmission: 'Submitting demo environment creation request. Please wait...',
    loaderSharedDemoRequestSubmission: 'Submitting access request for shared demo environment. Please wait...',
    pageHeading: 'Provision pre-configured demo',
    pageDescription: 'To deploy {template-name} into your tenant, please enter the information below',
    templateDescription: 'Demo environment to support cross-application scenarios for Customer Experience and Service. This template includes applications, configurations and demo data to support the "Modernize Service Experience" solution play',
    labelProductIcons: 'Products included:',
    labelEnvironmentName: 'Name your environment',
    labelDemoAdmin: 'Enter your 12-month dedicated tenant admin credentials',
    altTextTemplateImage: 'Template for demo environment',
    placeholderEnvironmentName: 'Name your environment',
    placeholderDemoAdminName: 'Demo admin email',
    btnTextSubmit: 'Submit',
    btnTextTryAgain: 'Try again',
    demoProvisioningUnauthorizedErrormessage: 'Access denied! You do not have permission to access this application.',
    addUserClickthroughsFailed: 'We are having trouble bookmarking your Clickthroughs. Please try again.',
}
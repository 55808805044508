import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    TemplatesSvc
} from 'Services';
import { Template } from 'Models';
import { pageRoutes } from 'Pages';
import { fetchText } from 'Providers/Localization';
import { logUnhandledExceptionAndSetMetrics, logErrorAndSetApiFailureMetrics, logAndSetApiSuccessMetrics } from 'Shared/telemetry/telemetryHelper';
import { operationName, TemplateNames } from 'Shared/constants';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { telemetryMessages } from 'Services/base/telemetryMessages';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { ErrorCode, Metrics } from '../telemetry';
import { CommonUtils } from 'Shared/Utils';
import { UserTemplateMgr } from 'Managers';
import { DemoType } from 'Services/Enums';
import { useGlobalContext } from 'Providers/ContextProvider';
import { RoleManager } from 'Shared/helpers/role-manager';
import { links } from 'Shared/links';

const TEMPLATE_STRING_PREFIX: string = 'TemplatePage.Templates';

export const useTemplates = () => {
    const allCategory = fetchText('TemplatePage.DefaultCategory');
    const accelerateRevenueGenerationCategory: string = fetchText('TemplatePage.AccelerateRevenueGenerationCategory');
    const modernizeServiceExperienceCategory: string = fetchText(`${TEMPLATE_STRING_PREFIX}.Dynamics-365-Customer-Experience-and-Service-Demo.Category`);
    const defaultCategories: string[] = [allCategory, accelerateRevenueGenerationCategory];
    const [loading, setLoading] = useState<boolean>(true);
    const [templates, setLoadedTemplates] = useState<Template[]>([]);
    const [error, setError] = useState<any>(null);
    const [tenantsList, setTenantsList] = useState<string[]>([]);
    const navigate = useNavigate();
    const { roles } = useGlobalContext();

    const getEnvironmentTemplate = async () => {
        let telemetryProps: TelemetryProperties = {
            operationName: operationName.TemplateSvcGetAllTemplates,
            exceptionType: ExceptionType.TemplateServiceException,
            defaultErrorCode: ErrorCode.FailedToGetEnvironmentTemplates
        }

        try {
            let templateApiResponse = await TemplatesSvc.getAllTemplates();

            setLoading(false);
            if (templateApiResponse.hasError) {
                setError(templateApiResponse?.data);

                // Log exception and metrics
                logErrorAndSetApiFailureMetrics(templateApiResponse, { ...telemetryProps, message: telemetryMessages.getTemplatesError }, Metrics.GetTemplatesAPIFailure);

                return;
            }

            let allTemplates = templateApiResponse.data?.templates;
            let localizedTemplatesContent = allTemplates.map(m => {
                return {
                    ...m,
                    name: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.Title`) || m.name, // return name as display name if cannot find localized string
                    description: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.Description`) || m.name,
                    category: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.Category`),
                    productIconsUrl: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.ProductIconsUrl`),
                    primaryButtonText: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.PrimaryButtonText`) || 'Choose this template',
                    secondaryButtonText: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.SecondaryButtonText`),
                    templateDetailContentFile: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.TemplateDetailContentFile`),
                    uniqueName: m.name,
                    clickthroughUrl: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.ClickthroughUrl`),
                    environmentNameRegex: m.environmentNameRegex,
                    environmentNameErrorMessage: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.EnvironmentNameErrorMessage`),
                    importantNote: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.ImportantNote`)
                };
            })
            setLoadedTemplates(localizedTemplatesContent);
            setTenantsList(templateApiResponse.data.tenantsList);

            // Log trace and Set metrics
            logAndSetApiSuccessMetrics(templateApiResponse, { ...telemetryProps, message: telemetryMessages.getTemplatesSuccess }, Metrics.GetTemplatesAPICounter);
        } catch (error) {
            setError(error);
            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    ...telemetryProps,
                    message: telemetryMessages.getTemplatesUnhandledException,
                    defaultErrorCode: ErrorCode.GetAllTemplatesUnhandledError
                },
                Metrics.GetTemplatesAPIFailure);
        }
    }

    const filteredTemplates = (category: string) => {
        if (category === allCategory) {
            return templates;
        }
        else if (category === accelerateRevenueGenerationCategory) {
            return templates.filter((f: Template) => f.category === modernizeServiceExperienceCategory);
        }
        return templates.filter((f: Template) => f.category === category);
    }

    const onSecondaryButtonClick = (selectedTemplate: Template) => {
        if (CommonUtils.compareStringIgnoreCase(selectedTemplate.demoType?.toString(), DemoType.clickthroughs.toString())) {
            // Open Clickthrough Url
            const clickthroughUrl = selectedTemplate.clickthroughUrl;
            if (clickthroughUrl) {
                UserTemplateMgr.openClickthrough(clickthroughUrl);
            }
        }

        else if (selectedTemplate && selectedTemplate.uniqueName) {
            const templateName = selectedTemplate.uniqueName;
            const templateDetailContentFile = selectedTemplate.templateDetailContentFile || '';
            const isTemplateActive = selectedTemplate.isActive;

            // Pass templateName as a query parameter
            const queryParamsTemplate = `templateName=${encodeURIComponent(templateName)}`;

            // Pass the remaining attributes in the state object
            const state = {
                templateDetailContentFile,
                isTemplateActive,
            };

            navigate(`${pageRoutes.templateDetail}?${queryParamsTemplate}`, { state });

        } 
    }

    /**
     * Disable Primary button if template is set as IsActive=false in Db OR
     * Current user is not a DemoHub seller
     * @param isTemplateActive {boolean} template state
     * @returns true if all condition met
     */
    const disablePrimaryButton = (isTemplateActive?: boolean) => {
        return !isTemplateActive || !RoleManager.isDemoHubSeller(roles);
    }

    /**
     * Disable Secondary button if template is set as IsActive=false in Db OR
     * Current user is not a DemoHub seller
     * @param template {Template} template object
     * @returns true if all condition met
     */
    const disableSecondaryButton = (template: Template) => {
        if (!template) {
            return true; 
        }
        const isTemplateActive = template.isActive;
        // This check is needed for making template detail button available for non sellers
        if (template && (CommonUtils.compareStringIgnoreCase(template.secondaryButtonText, "Template details"))) {
            return false;
        }
        return !isTemplateActive || !RoleManager.isDemoHubSeller(roles);
    }

    useEffect(() => {
        getEnvironmentTemplate();
    }, []);

    const categories = templates && templates.length > 0 ? defaultCategories.concat(Array.from(new Set(templates.map((item: Template) => item.category)))) : defaultCategories;

    return {
        templates,
        categories,
        filterByCategory: (category: string) => filteredTemplates(category),
        loading,
        error,
        tenantsList,
        onSecondaryButtonClick,
        isPrimaryButtonDisabled: (isActive?: boolean) => disablePrimaryButton(isActive),
        isSecondaryButtonDisabled: (template: Template) => disableSecondaryButton(template),
    };
};
export const SELLER_STATE_ITEM_KEY: string = 'BizAppsDemoHub-SellerId';
export const CORRELATION_ID: string = 'BizAppsDemoHub-CorrelationId';
export const DEMOLIST_REFRESH_DURATION: number = 30000; // 0.5 minute

/*
Keep Operation Names in Alphabetical order
*/
export const operationName = {
    ApiContentProviderLoadContent: 'ApiContentProviderLoadContent',
    CESRenderSurvey: 'CES.renderSurveys',
    ContentLoader: 'ContentLoader',
    CopilotAssetsContentLoader: 'CopilotAssetsContentLoader',
    DemoRequestSvcDemoProvisioning: 'DemoRequestSvc.startDemoProvisioning',
    DemoRequestSvcGetDemoRequestById: 'DemoRequestSvc.getDemoRequestById',
    DemoRequestSvcGetMyDemos: 'DemoRequestSvc.myDemos',
    DemoRequestSvcNewDemoRequest: 'DemoRequestSvc.newDemo',
    FeatureFlagSvcGetFlags: 'FeatureFlagService.getFlags',
    GlobalContextProvider: 'GlobalContextProvider',
    NsatSvcCheckEligility: 'NsatService.checkEligibility',
    NsatSvcRenderSurvey: 'NsatService.renderSurvey',
    NsatSvcSendNsatEmail: 'NsatService.sendNsatEmail',
    RefreshDemoOrgs: 'RefreshDemoOrgsFromPpac',
    RoleSvcGetRoles: 'RoleService.getRoles',
    TemplateSvcGetAllTemplates: 'TemplateService.getAllTemplates',
    UserSvcRegisterSeller: 'UserService.registerSeller',
    UserTemplateSvcOpenClickthrough: 'UserTemplateService.openClickthrough',
    UserTemplateSvcAddUserTemplateMappingAsync: 'UserTemplateService.addUserTemplateMappingAsync',
    UserTemplateSvcGetUserTemplatesByDemoType: 'UserTemplateService.getUserTemplatesByDemoType',
    UserTemplateSvcGetTemplateByName: 'UserTemplateService.getUserTemplateByNameAsync',
    UserTemplateSvcRemoveUserTemplateMappingAsync: 'UserTemplateService.removeUserTemplateMappingAsync',
    Unknown: 'Unknown',
}

/**
 * Refer for details - https://msazure.visualstudio.com/One/_wiki/wikis/DTP%20Insights%20and%20Analytics%20wiki/497765/Onboarding
 * Nsat constant with the following variables:
 * - EventName              - Event for adding to NSAT based on user action
 * - EventTriggerAttribute  - Data attribute for event binding on user action for submitting events
 * - SurveyName             - Unique identifier for survey name
 * - TeamName               - Unique Name of the team matching with survey response
 * - TeamDisplayName        - Product display name on the CES UI
 * - EventTrigger           - Event that will make the user eligible for a survey * 
 */
export const Nsat = {
    EventName: 'sellerportalaction',
    EventTriggerAttribute: { "data-isnsatevent": "true" },
    SurveyName: 'demohubportaleventbasedsurvey', //
    SurveyNameForEmail: 'demohubportaleventbasedsurveyemail', // add this new survey with event count = 3
    TeamName: 'bizappsdemos',
    TeamDisplayName: 'Demo Hub Portal',
    EventTrigger: 'demohubeventbasedsurveytrigger_30'
}

// List of all success response codes from API
export const SuccessCodes: string[] = ['200', '201', '202', '204'];

export const environmentNames = {
    dev: 'DEV',
    test: 'TEST',
    preprod: 'PREPROD',
    prod: 'PROD'
}

export const ErrorMessage = {
    accessTokenError: 'Access token is null or empty'
}

export const isLocalDevelopment = process.env.REACT_APP_APP_ENV === environmentNames.dev;
export const isTest = process.env.NODE_ENV === 'test';

export const NewDemoRequestFields = {
    demoEnvironmentName: 'demoEnvironmentName',
    tenantUserName: 'tenantUserName',
    userConsent: 'userConsent'
}

export const SharedDemosCategory = 'Shared Demos';

export const TemplateNames = {
    IndustryRetail: 'Industry-Retail',
    COE_TOOLKIT: 'CoE-Toolkit',
    IndustryFSI : 'Industry-FSI',
    FnO: 'Finance-And-Operations'
}

export const FeatureFlagName = {
    Copilot: 'copilot'
}

export const OneDSActionTypes = {
    ClickLeft: 'CL',
    ClickMiddle: 'CM',
    ClickRight: 'CL',
    KeyboardEnter: 'KE',
    KeyboardSpace: 'KS',
    Other: 'O',
    Resize: 'R',
    Scroll: 'S',
    Zoom: 'Z'
}

export const ValidationRegex = {
    ValidEmail: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ // valid email address
}

export const SurveyPath = '/survey/';

export const UrlQueryParams = {
    templateCategory: 'category'
}
import { Role } from "Services/roleSvc/models/Roles";

const RoleHelper = () => {
    const isDemoHubUser = (roles: string[]): boolean => {
        return roles?.includes(Role.DEMOHUB_VISITOR);
    }

    const isDemoHubSeller = (roles: string[]): boolean => {
        return roles?.includes(Role.DEMOHUB_SELLER);
    }

    const demoHubSellerPolicy = (roles: string[]): boolean => {
        return roles?.includes(Role.DEMOHUB_SELLER) || roles?.includes(Role.DEMOHUB_ADMIN);
    }

    return {
        isDemoHubUser,
        isDemoHubSeller,
        demoHubSellerPolicy
    }
}

export const RoleManager = RoleHelper();
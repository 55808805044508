export interface CopilotHeaderParams {
    onNewChatClick: () => void;
}

export const useCopilotHeader = (params: CopilotHeaderParams) => {

    const onToggleCoPilot = () => {

        // clear current conversation on close
        params.onNewChatClick();

        const chatWindowHost = document.getElementById('demohubCopilotHost');
        const demohubHost = document.getElementById('demoHubHost');
        const mainContentHost = document.getElementById('demohubPortalHost');
        if (chatWindowHost && demohubHost && mainContentHost) {
            const isChatWindowOpen = chatWindowHost?.style.visibility === 'hidden';
            chatWindowHost.style.visibility = !isChatWindowOpen ? 'hidden' : 'visible';
            chatWindowHost.style.display = !isChatWindowOpen ? 'none' : 'flex';
            mainContentHost.style.width = '100%';
        }
    }

    const onFullscreenClick = () => {
        const chatWindowHost = document.getElementById('demohubCopilotHost');
        const mainContentHost = document.getElementById('demohubPortalHost');
        if (chatWindowHost && mainContentHost) {
            chatWindowHost.style.width = '99.9%';
            mainContentHost.style.width = '0.1%';
        }
    }

    const onMinScreenClick = () => {
        const chatWindowHost = document.getElementById('demohubCopilotHost');
        const mainContentHost = document.getElementById('demohubPortalHost');
        if (chatWindowHost && mainContentHost) {
            chatWindowHost.style.width = '30%';
            mainContentHost.style.width = '70%';
        }
    }

    return {
        onToggleCoPilot,
        onFullscreenClick,
        onMinScreenClick
    }
}
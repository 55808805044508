import { metricsLogger } from '../Telemetry';
import i18n, { DEFAULT_NS } from './i18n';

export const localeAwareSortAsc = (first: string, second: string): number => first.localeCompare(second, i18n.language, { usage: 'sort' });
export const localeAwareSortDesc = (first: string, second: string): number => second.localeCompare(first, i18n.language, { usage: 'sort' });

export const localeAwareSortFnGenerator = (ascending: boolean): ((first: string, second: string) => number) => {
    return ascending ? localeAwareSortAsc : localeAwareSortDesc;
};

const t = (keys: string | string[], ns?: string) => i18n.t(keys, { ns: ns });
const tObject = (keys: string | string[], ns?: string) => i18n.t(keys, { returnObjects: true, ns: ns });

// EXAMPLES for fetching values from Json files.
// fetchText('Key-Name'); - It will fetch the value as simple string from common.json file which is the default ns.
// fetchText('Key-Name', 'namespace-name'}); - It will fetch the value as simple string from the given file e.g. annoucements.json file.
// fetchObject('Key-Name'); - It will fetch the value as object from common.json file which is the default ns.
// fetchObject('Key-Name', 'namespace-name'}); - It will fetch the value as object from the given file e.g. annoucements.json file.

export const fetchObject = <T>(key: string, namespace?: string): T | null => {
    let defaultValue = null; // keeping empty object as default value for key    
    let objectValue = tObject(key, namespace);
    let contentFileName = namespace || DEFAULT_NS;

    if (!objectValue) {
        metricsLogger.emitGeneralMetric('TranslationKeyNotFound', { UIOperation: `Searching for ${key} key in ${contentFileName} json file.` });
        return defaultValue as T;
    }

    return objectValue as T;
}

export const fetchText = (key:string, namespace?:string): string => {
    let defaultValue = ''; // keeping empty string as default value for key    
    let textValue = t(key, namespace);
    let contentFileName = namespace || DEFAULT_NS;

    if (!textValue) {
        metricsLogger.emitGeneralMetric('TranslationKeyNotFound', { UIOperation: `Searching for ${key} key in ${contentFileName} json file.` });
        return defaultValue;
    }

    return textValue;
}

export const localeAwareLowercaseEquals = (first: string, second: string) => first.toLocaleLowerCase() === second.toLocaleLowerCase();
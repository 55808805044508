import { Link } from '@fluentui/react';
import { fetchText } from 'Providers/Localization';
import { links } from 'Shared/links';
import { BaseProps } from 'Shared/Components';
import { IconLoader } from 'Shared/Controls';
import { CommonUtils } from 'Shared/Utils';
import styles from '../../home.module.scss';

interface AdditionalResourcesProps extends BaseProps {
}

interface ResourceProps {
    contentKey: string;
    iconName: string;
    linkUrl: string;
    inlineHyperlink1?: string;
    id?: string;
}

export const AdditionalResources = (props: AdditionalResourcesProps) => {
    const getText = (key: string): string => {
        return fetchText(key, props.contentFileName);
    }

    const Resource = (props: ResourceProps) => {
        const {
            contentKey,
            iconName,
            linkUrl,
            inlineHyperlink1,
            id
        } = props;
        const contentkeyPrefix = `HomePage.AdditionalResources.${contentKey}`;

        const resourceHeading = getText(`${contentkeyPrefix}.Heading`);
        const resourceDescription = getText(`${contentkeyPrefix}.Description`);

        return (
            <div className={styles.resource}>
                {resourceHeading &&
                    <div className={styles.iconWithText}>
                        <IconLoader
                            iconName={iconName}
                            className={styles.grayedIcon}
                            ariaLabel={resourceHeading}
                        />
                        <Link
                            href={linkUrl}
                            id= {id}
                            target="_blank"
                            aria-label={getText(`${contentkeyPrefix}.AriaLabel`)}
                            title={resourceHeading}>
                            {resourceHeading}
                        </Link>
                    </div>
                }
                {resourceDescription &&
                    <p>
                        {CommonUtils.parseStringToHtml(resourceDescription.replace('{hyperlink1}', inlineHyperlink1 ?? '#'))}
                    </p>
                }
            </div>
        )
    }

    return (
        <div className={styles.additionalResourcesContainer}>
            <div className={styles.header}>
                <h3 className={styles.heading}>
                    {getText('HomePage.AdditionalResources.Heading')}
                </h3>
            </div>
            <div className={styles.additionalResources}>
                <Resource
                    id='lnkRequestDemoEnvironment'
                    contentKey="RequestDemoEnvironment"
                    iconName="PageAdd"
                    linkUrl={links.additionalResources.requestDemoEnvironment} />
                <Resource
                    id='lnkExtendDemoEnvironment'
                    contentKey="ExtendDemoEnvironment"
                    iconName="RenewalCurrent"
                    linkUrl={links.additionalResources.extendDemoEnvironment} />               
                <Resource
                    id='lnkAzureSubscription'
                    contentKey="AzureSubscription"
                    iconName="AzureLogo"
                    linkUrl={links.additionalResources.addAzSubscription}
                    inlineHyperlink1={links.cdxTenantLink} />
            </div>
        </div>
    )
}
import {
    Pivot,
    PivotItem,
    SpinnerSize,
    MessageBar
} from '@fluentui/react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Template } from 'Models';
import { Loader } from 'Shared/Controls';
import { Card } from 'Shared/Components';
import { useTemplates } from '../../hooks/useTemplates';
import { fetchText } from 'Providers/Localization';
import { Nsat, UrlQueryParams } from 'Shared/constants';
import styles from './templates.module.scss';
import { CommonUtils } from 'Shared/utils/common-utils';

interface ITemplateProps {
    onTemplateSelection: (selectedTemplate: Template, tenantsList: string[]) => void;
    preSelectedtemplate?: string | null;
}

const TEMPLATE_PAGE_CONTENT_PREFIX = 'TemplatePage';

export const Templates = (props: ITemplateProps) => {

    const { templates,
        categories,
        filterByCategory,
        loading,
        error,
        tenantsList,
        onSecondaryButtonClick,
        isPrimaryButtonDisabled,
        isSecondaryButtonDisabled,
    } = useTemplates();
    const [searchParams, setSearchParams] = useSearchParams();
    const categoryTab = searchParams.get(UrlQueryParams.templateCategory)?.toLowerCase() || categories[0]?.toLowerCase();

    if (loading) {
        return <Loader Size={SpinnerSize.large} Message={fetchText(`${TEMPLATE_PAGE_CONTENT_PREFIX}.LoadingMessage`)} />;
    }

    if (CommonUtils.redirectToErrorPage(error)) {
        return <Navigate to="/error" state={error} />
    }

    if (!templates || templates?.length === 0) {
        return <MessageBar
            delayedRender={false}
            role="none"
        >
            {fetchText(`${TEMPLATE_PAGE_CONTENT_PREFIX}.NoTemplates`)}
        </MessageBar>;
    }

    if (props.preSelectedtemplate) {
        const templateName = templates.filter((template: Template) => template.uniqueName === props.preSelectedtemplate);
        if (templateName.length > 0) {
            const template = templateName[0];
            props.onTemplateSelection(template, tenantsList);
        }
    }

    function linkClickHandler(event: any): void {
        setSearchParams({category : event.props.itemKey});
    }

    // Function to reorder categories 
    const reorderCategories = (categories: string[], categoryToMove: string, newIndex: number): string[] => {
        // Create a copy of the categories array
        const updatedCategories = [...categories];
        const index = updatedCategories.indexOf(categoryToMove);
        if (index > -1) {
            updatedCategories.splice(index, 1); // Remove the item from the array
        }
        updatedCategories.splice(newIndex, 0, categoryToMove); // Insert the category at the new index
        return updatedCategories;
    };

    // Ensure "Modernize ERP" is at the 3rd position
    const reorderedCategories = reorderCategories(categories, 'Modernize ERP', 3);

 return (
        <div className={styles.templates}>
            <div className={styles.heading}>
                <h1>{fetchText(`${TEMPLATE_PAGE_CONTENT_PREFIX}.Title`)}</h1>
                <p>{fetchText(`${TEMPLATE_PAGE_CONTENT_PREFIX}.Description`)}</p>
            </div>
            <Pivot selectedKey={categoryTab} className={styles.categoryTabs} aria-label={fetchText(`${TEMPLATE_PAGE_CONTENT_PREFIX}.TabsAriaLabel`)} linkSize="normal" onLinkClick={(event: any) => linkClickHandler(event)}>
                <hr />
                {reorderedCategories && reorderedCategories.map((category: string) => {
                    return <PivotItem headerText={category} key={category} itemKey={category.toLowerCase()}>
                        <div className={styles.templateCardsContainer}>
                            <div className={styles.templateCards}>
                                {templates && filterByCategory(category).map((template: Template, index: number) => {
                                    return <Card
                                        id={`template-${template.id}`}
                                        title={template.name}
                                        description={template.description}
                                        isDescriptionRichtext={true}
                                        key={template.id}
                                        imageUrl={template.iconUrl}
                                        demoType={template.demoType}
                                        showColorBand={true}
                                        disablePrimaryButton={isPrimaryButtonDisabled(template.isActive)}
                                        primaryButtonId={`btn-primary-${template.uniqueName}`}
                                        primaryButtonText={template.primaryButtonText}
                                        onPrimaryButtonClick={() => props.onTemplateSelection(template, tenantsList)}                                        
                                        dataAttributes={Nsat.EventTriggerAttribute}
                                        {...(template.secondaryButtonText && {
                                            secondaryButtonId: `btn-secondary-${template.uniqueName}-${index + 1}`,
                                            secondaryButtonText: template.secondaryButtonText,
                                            onSecondaryButtonClick: () => onSecondaryButtonClick(template),
                                            disableSecondaryButton: isSecondaryButtonDisabled(template)
                                        })}
                                    />
                                })}
                            </div>
                        </div>
                    </PivotItem>
                })}
            </Pivot>

        </div>
    )
}

import { ButtonIcon, IconLoader } from 'Shared/Controls';
import { usePagination, DOTS } from './hooks/usePagination';
import styles from './pagination.module.scss';

interface PaginationProps {
    className?: string;
    currentPage: any;
    totalCount: any;
    pageSize: any;
    onPageChange: (pageNo: any) => void;
    siblingCount?: number;
}

export const Pagination = (props: PaginationProps) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const {
        paginationRange,
        displayingDataFrom,
        displayingDataTo
    }: any = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    const dotKey = () => {
        return `dot_${Math.random()}`
    }

    return (
        <div className={className}>
            <div className={styles.paginationContainer}>
                <ul>
                    <li
                        key="prev"
                        onClick={onPrevious}
                        className={currentPage === 1 ? styles.disabled : ''}>
                        <ButtonIcon iconName="ChevronLeftMed" id='btnPrevPage'
                            disabled={currentPage === 1}
                            ariaLabel="Go to the previous page" />
                    </li>
                    {paginationRange.map((pageNumber: any) => {
                        // If the pageItem is a DOT, render the DOTS unicode character
                        if (pageNumber === DOTS) {
                            return <li
                                key={dotKey()}
                                className={styles.dots}> <span>&#8230;</span> </li>;
                        }

                        // Render our Page Pills
                        return (
                            <li
                                key={pageNumber}>
                                <button
                                    aria-label={`Go to page number ${pageNumber}`}
                                    onClick={() => onPageChange(pageNumber)}
                                    className={currentPage === pageNumber ? styles.active : ''}>
                                    {pageNumber}
                                </button>
                            </li>
                        );
                    })}
                    <li
                        key="next"
                        onClick={onNext}
                        className={currentPage === lastPage ? styles.disabled : ''}>
                        <ButtonIcon iconName="ChevronRightMed" id='btnNextPage'
                            disabled={currentPage === lastPage}
                            ariaLabel="Go to the next page" />
                    </li>
                </ul>
                <span className={styles.paginationInfo}>
                    {displayingDataFrom}-{displayingDataTo} of {props.totalCount}
                </span>
            </div>
        </div>
    );
};
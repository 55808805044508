import { DemoRequest } from './models/DemoRequest';

export const mapApiResponse = (demoRequestItem: any): any => {
    let newItem: DemoRequest = {
        EnvironmentName: demoRequestItem.name,
        EnvironmentUrl: demoRequestItem.environmentUrl,
        SecondaryEnvironmentUrl: demoRequestItem.secondaryEnvironmentUrl,
        UserName: demoRequestItem.demoUserAccount,
        Status: demoRequestItem.statusId,
        CreatedOn: demoRequestItem.createdOn,
        CreatedBy: demoRequestItem.createdBy,
        ValidationStatus: demoRequestItem?.demoRequestStatus?.name,
        TemplateName: demoRequestItem?.demoTemplate?.templateName,
        TemplateIconUrl: demoRequestItem?.demoTemplate?.iconUrl,
        DemoRequestId: demoRequestItem?.demoRequestId,
        OperationStatus: demoRequestItem.demoOperationStatus?.status,
        DemoUserAccount: demoRequestItem?.demoUserAccount,
    };
    return newItem;
}

export const mapApiResponseMultiple = (apiResponse: any[]) => {
    let data: DemoRequest[] = [];
    if (apiResponse && apiResponse.length) {
        apiResponse.forEach(item => {
            let newItem: DemoRequest = mapApiResponse(item);
            data.push(newItem);
        })
    }
    return data;
}
import { Link } from '@fluentui/react';
import { IconLoader} from 'Shared/Controls';
import styles from '../../templatedetails-page.module.scss';


interface Asset {
    LinkTitle: string;
    LinkUrl: string;
    LinkAriaLabel: string;
    LinkIconName: string;
}

interface AssetsAndResourcesProps {
    assets: Asset[] | undefined;
    sectionKey?: string | "default";
}

export const AssetsAndResources = ({ assets, sectionKey }: AssetsAndResourcesProps) => {
    return (
        <div className={styles.assetsAndResources}>
            <h4>Assets and Resources</h4>
            <div className={styles.assetsSection}>
                {assets && assets.length > 0 ? (
                    assets.map((asset, index) => (
                        <div key={`asset-${index}`} className={styles.assetsContainer}>
                            <IconLoader
                                iconName={asset.LinkIconName || 'DefaultIconName'}
                                className={styles.icon}
                                ariaLabel={asset.LinkAriaLabel}
                            />
                            <Link
                                id={`lnk-${sectionKey}-${index}`}
                                className={styles.assetLink}
                                aria-label={asset.LinkAriaLabel}
                                title={asset.LinkTitle}
                                href={asset.LinkUrl}
                                target="_blank"
                            >
                                {asset.LinkTitle}
                            </Link>
                        </div>
                    ))
                ) : (
                    <p>No assets and resources available.</p>
                )}
            </div>
        </div>
    );
};
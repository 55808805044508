import {
    IIconProps,
    PrimaryButton,
    Image,
    ImageFit,
    DefaultButton
} from '@fluentui/react';
import { Nsat } from 'Shared/constants';
import { messages } from '../../demorequests.settings';
import { ClickthroughRequest } from 'Services/userTemplateSvc/models/ClickthroughRequest';
import styles from '../demorequests.module.scss';
import { CommonUtils } from 'Shared/Utils';

interface ClickthroughCardProps {
    clickthroughRequestItem: ClickthroughRequest;
    openClickthrough: (clickthroughRequestUrl: string) => void;
    unPinClickthroughRequest: (clickthroughRequestItem: ClickthroughRequest) => void;
    isDescriptionRichtext?: boolean;
}

export const ClickthroughCard = (props: ClickthroughCardProps) => {
    const { isDescriptionRichtext, clickthroughRequestItem } = props;
    const { TemplateName, TemplateIconUrl, TemplateDescription, Url, TemplateUniqueName } = clickthroughRequestItem;

    return <div className={styles.requestsContainer} key={TemplateName}>
        <div className={`${styles.imageContainer} ${styles.cardClickthroughDemoType}`}>
            <Image
                imageFit={ImageFit.cover}
                src={TemplateIconUrl}
                alt={TemplateName}
            />
        </div>
        <div className={`${styles.copyContainer} ${styles.cardClickthroughDemoType}`}>
            <div className={styles.innerText}>
                <h3 className={styles.textMedium} title={TemplateName}>
                    {TemplateName}</h3>
            </div>
            <div className={styles.clickthroughMetaInfo}>
                {!isDescriptionRichtext && <p
                    title={TemplateDescription}>
                    {TemplateDescription}
                </p>
                }
                {
                    isDescriptionRichtext && TemplateDescription &&
                    <div className={styles.description}>
                        {CommonUtils.parseStringToHtml(TemplateDescription)}
                    </div>
                }
            </div>
            <div className={styles.buttonsContainer}>
                <PrimaryButton
                    id={`btnOpenClickthrough-${TemplateUniqueName}`}
                    text={messages.clickthroughOpenBtnText}
                    aria-label={messages.clickthroughOpenBtnAriaLabel}
                    allowDisabledFocus
                    {...Nsat.EventTriggerAttribute}
                    onClick={() => {
                        props.openClickthrough(Url)
                    }}
                />
                <DefaultButton 
                    id={`btnUnpinClickthrough-${TemplateUniqueName}`}
                    text={messages.clickthroughUnPinBtnText}
                    aria-label={messages.clickthroughUnPinBtnAriaLabel}
                    allowDisabledFocus
                    {...Nsat.EventTriggerAttribute}
                    onClick={() => {
                        props.unPinClickthroughRequest(props.clickthroughRequestItem)
                    }}
                />
            </div>
        </div>
    </div>
}
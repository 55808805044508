import {
    IBreadcrumbItem,
    Breadcrumb
} from '@fluentui/react';
import { BaseProps } from '../base/ComponentProps';
import styles from './breadcrumb-menu.module.scss';

interface BreadcrumbProps extends BaseProps {
    ariaLabel: string;
    items: IBreadcrumbItem[];
}

const MaxItems: number = 3; // max levels for breadcrumb menu
export const BreadcrumbMenu = ({ items, ariaLabel, className }: BreadcrumbProps) => {

    return (
        <Breadcrumb
            id='breadcrumbMenu'
            items={items}
            maxDisplayedItems={MaxItems}
            ariaLabel={ariaLabel}
            className={`${styles.breadcrumbMenu} ${className}`}
            overflowAriaLabel="More links"
        />
    )
};
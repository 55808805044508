import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";

const appClientId: string = process.env.REACT_APP_AUTH_CLIENT_ID || '';
const authority: string = 'https://login.microsoftonline.com/common';
const nsatResource: string = 'https://microsoft.onmicrosoft.com/cessurvey';
const LOAD_FRAME_HASH_TIMEOUT = 30000; //specifies the time to wait for the iframe authentication to resolve in milliseconds
/**
 * Reference link for Msal configuration options https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const AuthConfig = {
    auth: {
        clientId: appClientId,
        authority: authority,
        redirectUri: `${window.location.origin}`,
        postLogoutRedirectUri: `${window.location.origin}`,
        navigateToLoginRequestUrl: true  // If true, will navigate back to the original request location before processing the authorization code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
        storeAuthStateInCookie: false
    },
    system: {
        loadFrameTimeout: LOAD_FRAME_HASH_TIMEOUT, // Applies to both silent and popup calls - In milliseconds
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                if (process.env.NODE_ENV === 'development') {
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                }
            },
            piiLoggingEnabled: false,
        },
        piiLoggingEnabled: false,
        pollIntervalMilliseconds: 10 // polls of popup URL hash during authenticaiton. default is 30
    }
};

// TODO: lower the scope to 'user.read' once all of our first party apps are authorized to use 'user.read', 
// currently our apps are authorized to use "user.readwrite.all" permisison, hence using it.
export const LoginRequest = {
    scopes: ["User.ReadWrite.All", "offline_access"]
};

export const ApiAccessTokenRequest = {
    scopes: [`${appClientId}/.default offline_access`]
};

export const NsatAccessTokenRequest = {
    scopes: [`${nsatResource}/.default`]
};
import { useEffect, useState } from 'react';
import {
    useLocation,
} from 'react-router-dom';
import { TemplateDetail, Template } from 'Models';
import { fetchText, fetchObject } from 'Providers/Localization';
import { useGlobalContext } from 'Providers/ContextProvider';
import { RoleManager } from 'Shared/helpers/role-manager';
import { UserTemplateMgr } from 'Managers';
import { TEMPLATE_DETAIL_MAPPING } from '../../../Mappers/templateDetailMapping';

export const useTemplateDetail = () => {

    const location = useLocation();
    const { roles } = useGlobalContext();
    const queryParams = new URLSearchParams(location.search);
    const selectedTemplateName = queryParams.get('templateName') || '';
    const { templateDetailContentFile, isTemplateActive } = location.state || {};
    let isTemplateActiveFlag = isTemplateActive;
    let contentFileName = templateDetailContentFile;

    const [error, setError] = useState<any>(null);
    const [templateLoadedFromDB, setTemplateLoadedFromDB] = useState<Template | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [templateDetailPageContent, setTemplateDetailPageContent] = useState<any>(null);


    const getTemplatefromDB = async () => {
        try {
            const result = await UserTemplateMgr.getUserTemplateByNameAsync(selectedTemplateName);
            isTemplateActiveFlag = result?.isActive;
            setTemplateLoadedFromDB(result);
        } catch (error) {
            setError(error); // Added metrices in the service call , so no need to add here
        }
    };

    const loadData = async () => {

        // 1. Initialize a loader
        setIsLoading(true);

        // 2. Make the DB call as needed
        if (isTemplateActive == null || isTemplateActive === "") {
            // Call the function
            await getTemplatefromDB();
        }

        //3. get content from content file and store in a state variable and pass that state variable to the view
        if (templateDetailContentFile == null || templateDetailContentFile === "") {
            contentFileName = TEMPLATE_DETAIL_MAPPING[selectedTemplateName] || '';
        }

        const templateDetailContent = {
            heading: contentFileName ? fetchText('Heading', contentFileName) : '',
            primaryButtonText: contentFileName ? fetchText('PrimaryButtonText', contentFileName) : '',
            secondaryButtonText: contentFileName ? fetchText('SecondaryButtonText', contentFileName) : '',
            templateDetails: contentFileName ? fetchObject<TemplateDetail[]>('TemplateDetails', contentFileName) : []
        }

        //4. store this templateDetailPageContent in a state variable and pass it to the view
        setTemplateDetailPageContent(templateDetailContent);

        //5.  reset the loader here e..g IsLoaidng = false
        setIsLoading(false);
    };

    // Call loadData function during initialization
    useEffect(() => {
        loadData();
    }, []); // The empty dependency array ensures that the effect runs once after the initial render


    /**
     * Disable Primary button if template is set as IsActive=false in Db OR
     * Current user is not a DemoHub seller
     * @param isTemplateActive {boolean} template state
     * @returns true if all condition met
     */
    const disableTemplateDetailChooseTemplateButton = (isTemplateActiveFlag?: boolean) => {
        if (isTemplateActiveFlag == null)
            return !templateLoadedFromDB?.isActive || !RoleManager.isDemoHubSeller(roles);
        else
            return !isTemplateActiveFlag || !RoleManager.isDemoHubSeller(roles);
    }

    /**
     * Check if template is set as IsActive=false in Db 
     * @param isTemplateActive {boolean} template state
     * @returns true if all condition met
     */
    const isTemplateInactiveCheck = (isTemplateActiveFlag?: boolean) => {
        if (isTemplateActiveFlag == null)
            return !templateLoadedFromDB?.isActive ;
        else
            return !isTemplateActiveFlag ;
    }

    return {
        error,
        templateDetailPageContent,
        selectedTemplateName,
        isTemplateActiveFlag,
        isChooseTemplateButtonDisabled: (isActive?: boolean) => disableTemplateDetailChooseTemplateButton(isActive),
        isLoading,
        isTemplateInactiveCheck: (isActive?: boolean) => isTemplateInactiveCheck(isActive)
    }
};
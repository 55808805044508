import { useEffect } from 'react';
import {
    Label,
    TextField,
    Image,
    PrimaryButton,
    DefaultButton,
    Stack,
    SpinnerSize,
    StackItem,
} from '@fluentui/react';
import { Navigate } from 'react-router-dom';
import { Templates } from './templates/Templates';
import { CommonUtils, DomElementEventListeners } from 'Shared/Utils';
import {
    Loader,
    MessageBox
} from 'Shared/Controls';
import { useNewDemoForm } from '../hooks/useNewDemoForm';
import { Nsat, NewDemoRequestFields } from 'Shared/constants';
import {
    stackTokens,
    MIN_LENGTH,
    MAX_LENGTH,
    messages
} from '../newdemo.settings';
import styles from './newdemoform.module.scss';

export const NewDemoRequest = () => {

    const {
        formReadinessState,
        validatingDemoRequest,
        formValues,
        formErrors,
        onInputChange,
        onSubmitDemo,
        onCancelClick,
        onTemplateSelection,
        onFormValidation,
        demoProvisioningError,
        error,
        formSubmissionNotAllowed,
        isAddingSharedDemoRequest,
        addingClickthrough,
        templateName
    } =
        useNewDemoForm();

    useEffect(() => {
        DomElementEventListeners.addNsatEventListeners('#new-demoform-container');
    }, [formReadinessState?.selectedTemplate])

    if (CommonUtils.redirectToErrorPage(error)) {
        return <Navigate to="/error" state={error} />
    }

    return (
        <div className={styles.newDemoRequestForm} id="new-demoform-container">
            {!formReadinessState?.selectedTemplate &&
                <Templates onTemplateSelection={onTemplateSelection} preSelectedtemplate={templateName}/>
            }
            {isAddingSharedDemoRequest &&
                <StackItem>
                    <Loader Message={messages.loaderSharedDemoRequestSubmission}
                        IsOverlay={true} Size={SpinnerSize.large} />
                </StackItem>
            }
            {addingClickthrough &&
                <StackItem>
                    <Loader Message={messages.loaderClickthroughRequestSubmission}
                        IsOverlay={true} Size={SpinnerSize.large} />
                </StackItem>
            }
            {formReadinessState?.selectedTemplate &&
                <Stack tokens={stackTokens}>
                    <div className={styles.heading}>
                        <h1>{messages.pageHeading}</h1>
                        <p>{messages.pageDescription.replace('{template-name}', formReadinessState?.selectedTemplate?.name)}</p>
                    </div>
                    <Stack.Item align="center" className={styles.formControlsContainer}>
                        <Stack tokens={stackTokens}>
                            <Stack.Item align="center" className={styles.templateInfoContainer}>
                                <Label>{formReadinessState?.selectedTemplate?.name}​</Label>
                                <Image
                                    src={formReadinessState?.selectedTemplate?.iconUrl}
                                    alt={messages.altTextTemplateImage}
                                />
                                <span>{CommonUtils.parseStringToHtml(formReadinessState?.selectedTemplate?.description)}​</span>
                                <Label className={styles.productsIncludedLabel}>{messages?.labelProductIcons}​</Label>
                                <Image
                                    src={formReadinessState?.selectedTemplate?.productIconsUrl}
                                    alt={messages.altTextTemplateImage}
                                />
                                {formReadinessState?.selectedTemplate?.importantNote && formReadinessState?.selectedTemplate?.importantNote.length > 0 &&
                                    <MessageBox
                                        message={(formReadinessState?.selectedTemplate.importantNote)}
                                        type="info"
                                        isMessageRichtext={true}
                                    />
                                }
                            </Stack.Item>
                            <Stack.Item>
                                <Label required htmlFor="demoEnvironmentName">{messages.labelEnvironmentName}</Label>
                                <TextField
                                    placeholder={messages.placeholderEnvironmentName}
                                    id="demoEnvironmentName"
                                    aria-required="true"
                                    name={NewDemoRequestFields.demoEnvironmentName}
                                    role="textbox"
                                    value={formValues.demoEnvironmentName}
                                    onChange={onInputChange}
                                    onBlur={(e: Event) => { onFormValidation(e); }}
                                    errorMessage={formErrors.demoEnvironmentName}
                                    maxLength={MAX_LENGTH}
                                    minLength={MIN_LENGTH}
                                    {...(formErrors.demoEnvironmentName && { formErrors: true, helperText: formErrors.demoEnvironmentName })}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Label required htmlFor="tenantUserName">{messages.labelDemoAdmin}</Label>
                                <TextField
                                    id="tenantUserName"
                                    role="textbox"
                                    aria-required="true"
                                    placeholder={messages.placeholderDemoAdminName}
                                    name={NewDemoRequestFields.tenantUserName}
                                    value={formValues.tenantUserName}
                                    onChange={onInputChange}
                                    onBlur={(e: Event) => { onFormValidation(e); }}
                                    errorMessage={formErrors.tenantUserName}
                                    {...(formErrors.tenantUserName && { formErrors: true, helperText: formErrors.tenantUserName })}
                                />
                                {
                                    demoProvisioningError && demoProvisioningError.errorMessage &&
                                    <MessageBox
                                        message={demoProvisioningError.errorMessage}
                                        isMessageRichtext={true}
                                    />
                                }
                            </Stack.Item>
                            {/* Note: This is not required for now from business requiremnets point of view. 
                            We will uncomment this when we have the consent form requirements ready
                        <UserConsent name="userConsent" consented={formValues.userConsent} onConsentChange={onInputChange} />
                        {!formValues.userConsent && <ValidationError error="Please provide your consent" />}
                        */}
                        </Stack>
                    </Stack.Item>
                    {validatingDemoRequest &&
                        <StackItem>
                            <Loader Message={messages.loaderRequestSubmission}
                                IsOverlay={true} Size={SpinnerSize.large} />
                        </StackItem>
                    }
                    <Stack.Item align="end">
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                id='btnCancelNewDemoRequest'
                                text="Back"
                                onClick={onCancelClick}
                                disabled={validatingDemoRequest}
                                {...Nsat.EventTriggerAttribute}
                            />
                            <PrimaryButton
                                id='btnSubmitNewDemoRequest'
                                text={demoProvisioningError && demoProvisioningError.errorMessage ? messages.btnTextTryAgain : messages.btnTextSubmit}
                                onClick={onSubmitDemo}
                                disabled={formSubmissionNotAllowed}
                                {...Nsat.EventTriggerAttribute}
                            />
                        </Stack>
                    </Stack.Item>
                </Stack>
            }
        </div>
    );
}
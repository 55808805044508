import { MessageBar, MessageBarType } from '@fluentui/react';

interface BannerProps {
    message: any;
    messageBarType: MessageBarType;
}

export const MessageBanner = ({ message, messageBarType }: BannerProps) => {
    // Set default values if message or messageBarType is null or empty
    const defaultMessage = 'Please contact support BizAppsDemoSupport@microsoft.com';
    const defaultMessageType = MessageBarType.info;
    return (
        <div className="message-banner-container">
            <MessageBar
                messageBarType={messageBarType || defaultMessageType}
                isMultiline={true}
                dismissButtonAriaLabel="Close"
                className="message-bar">
                {message || defaultMessage}
            </MessageBar>
        </div>
    );
};
import { MsalProvider } from '@azure/msal-react';
import { UrlString } from '@azure/msal-common';

import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux'
import store from 'Store/store';
import { AuthProvider } from 'Providers/Authentication';

import App from 'Shared/Components/app/App';
import reportWebVitals from './reportWebVitals';

import 'office-ui-fabric-core/dist/css/fabric.min.css'
import 'Shared/styles/base.scss';

import * as constants from 'Shared/constants';
import { generateGuid } from 'Shared/utils/generate-correlationId';
import { GlobalContextProvider } from 'Providers/ContextProvider/GlobalContextProvider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

(function () {
    sessionStorage.setItem(constants.CORRELATION_ID, generateGuid());
})();

const msalInstance = AuthProvider.getMsalInstance();

/**
 * We need to determine if we are in the auth popup
 * If so, we need to render a simple message to avoid interupption in token polling by msal iframe
 * Otherwise, we render the app
 * [MSAL uses]{@link (https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/src/interaction_client/PopupClient.ts#L343)} "UrlString.hashContainsKnownProperties" method to validate the hash
 * @returns true if the current window is an auth popup
 */

const isAuthPopup = () => {
    const hash = window.location.hash;
    return UrlString.hashContainsKnownProperties(hash);
}

// check if we are in the auth popup
// if so, render a simple message
// otherwise, render the main app
if (isAuthPopup()) {
    root.render(<div>
        <h1>Authenticating...</h1>
    </div>);
} else {
    root.render(
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                    <GlobalContextProvider>
                        <App />
                    </GlobalContextProvider>
            </MsalProvider>
        </Provider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

import { AuthConstants, AuthConfig, AuthProvider } from 'Providers/Authentication';
import { MeControlConfig } from './MeControlConfig';

const meControlLightTheme = { type: 'light' };
const meControlDarkTheme = { type: 'dark' };

const signInBase = async () => {
    await AuthProvider.signIn();
};

const meControlSignIn = () => {
    signInBase();
}

const meControlSignInTo = async(args: any) => {
    return AuthProvider.signInto(args);
}

const meControlSignOut = async () => {
    window.MeControl.API.setActiveAccount(null);
    return AuthProvider.signOut();
}

const meControlSwitchTo = async (args: any) => {
    return AuthProvider.switchTo(args);
}

const meControlSwitch = async () => {
    return AuthProvider.switch();
}

const buildAadUrls = (endpoint: string, includeDefaults = true, shouldForgetUser?: boolean) => {
    const defaultParams = includeDefaults ? `appid=${AuthConfig.auth.clientId}&wreply=${AuthConfig.auth.redirectUri}` : '';
    const forgetUserParam = shouldForgetUser === undefined ? '' : `&shouldForgetUser=${String(shouldForgetUser)}`;
    return `https://login.microsoftonline.com/${endpoint}?${defaultParams}${forgetUserParam}`;
};

const mapMsalAccountInfoToAccount = (account: any): any => {
    if (AuthProvider.isMsaAccount(account)) {
        return {
            type: AuthConstants.MSA_AUTH,
            authenticatedState: AuthConstants.AUTH_STATE,
            memberName: account?.name,
            cid: account?.idTokenClaims?.oid?.replace(`00000000-0000-0000-`, ``)
                .replace("-", ""),
        };
    } else {
        return {
            type: AuthConstants.AAD_AUTH,
            authenticatedState: AuthConstants.AUTH_STATE,
            memberName: account?.username,
            displayName: account.name,
            loginHint: account.username,
            tenantId: account.tenantId,
        };
    }
};

const getUserForMeControl = () => {
    const account = AuthProvider.getActiveAccount();
    if (account) {
        return mapMsalAccountInfoToAccount(account);
    }
}

const getMeControlGeneralThemeType = (theme: string) => {
    return theme === 'Light' ? meControlLightTheme : meControlDarkTheme;
};

function loadMeControl() {
    const meControlContainerId = MeControlConfig.containerId;
    const meControlContainer = document.getElementById(meControlContainerId);
    if (!meControlContainer) {
        //TODO: Log this error message
        throw new Error("Can't find me-control-container element");
    }

    // Clear the container of any placeholders
    meControlContainer.className = '';
    meControlContainer.innerHTML = '';

    var currentUser = getUserForMeControl();

    // Build configuration object
    const meControlOptions: any = {
        apiGeneration: MeControlConfig.apiVersion,
        containerId: MeControlConfig.containerId,
        authProviderConfig: {
            type: MeControlConfig.authProvider,
            signIn: meControlSignIn,
            signInTo: meControlSignInTo,
            signOutFromApp: meControlSignOut,
            switch: meControlSwitch,
            switchTo: meControlSwitchTo,

            //Remembered accounts
            aad: {
                signOutUrl: buildAadUrls('uxlogout', true, false),
                signOutAndForgetUrl: buildAadUrls('uxlogout', true, true),
                forgetUrl: buildAadUrls('forgetuser', false),
                rememberedAccountsUrl: buildAadUrls('savedusers')
            }
        },
        primaryUXConfig: {
            theme: getMeControlGeneralThemeType('Light'),
            headerTheme: getMeControlGeneralThemeType('Dark'),
        },
    };


    if (currentUser) {
        meControlOptions.currentAccount = currentUser;
    }

    // Call load
    const loadSuccess = window.MeControl.Loader.load(meControlOptions);
    if (!loadSuccess) {
        throw new Error('Me Control did not load successfully');
    }

    // Clean up callback
    window.onMeControlReadyToLoad = null;
}

function InitMeControl() {
    if (window.MeControl) {
        loadMeControl();
    }
    else {
        window.onMeControlReadyToLoad = loadMeControl;
    }
}

export const MeControl = () => {
    const { inProgress, accounts } = useMsal();
    useEffect(() => {
        console.log('init me control');
        InitMeControl();

    }, [inProgress, accounts]);

    return (
        <div id={MeControlConfig.containerId}>
        </div>
    );
}
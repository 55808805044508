import React from 'react';
import { ICustomProperties, SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from './ApplicationInsightsLogging';
import { InsightsLoggerContract } from './InsightsLoggerContract';
import * as constants from 'Shared/constants';
import * as guid from 'Shared/utils/generate-correlationId';

export class InsightsLogger implements InsightsLoggerContract {
	getCustomProperties() {
		const customProperties: ICustomProperties = {
			clientCorrelationId: sessionStorage.getItem(constants.CORRELATION_ID)
		};
		return customProperties;
	}

	trackException(error: string, properties?: any): void {
		appInsights.trackException({ error: new Error(error), severityLevel: SeverityLevel.Error }, { ...properties, ...this.getCustomProperties() });
	}

	trackTrace(trace: string, properties?: any): void {
		appInsights.trackTrace({ message: trace, severityLevel: SeverityLevel.Information }, { ...properties, ...this.getCustomProperties() });
	}

	trackWarning(warning: string, properties?: any): void {
		appInsights.trackTrace({ message: warning, severityLevel: SeverityLevel.Warning }, { ...properties, ...this.getCustomProperties() });
	}

	trackEvent(event: string, properties?: any): void {
		appInsights.trackEvent({ name: event }, { ...properties, ...this.getCustomProperties() });
	}

	trackOutgoingRequest = (
		name: string,
		absoluteUrl: string,
		duration: number,
		success: boolean,
		responseCode: number,
		correlationContext: string,
		retryCount: number,
		startDate: Date
	): void => {
		appInsights.trackDependencyData({
			id: guid.generateGuid(),
			name,
			duration,
			success,
			responseCode,
			correlationContext,
			target: absoluteUrl,
			properties: {
				[constants.CORRELATION_ID]: correlationContext,
				retryCount
			},
			startTime: startDate
		});
	};

	flush(): void {
		appInsights.flush();
	}
}




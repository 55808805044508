import { ContentSvc } from 'Services';
import { logUnhandledExceptionAndSetMetrics, logErrorAndSetApiFailureMetrics, logAndSetApiSuccessMetrics } from 'Shared/telemetry/telemetryHelper';
import { operationName } from 'Shared/constants';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { telemetryMessages } from 'Services/base/telemetryMessages';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { Dimensions, ErrorCode, Metrics } from './telemetry';
import { ExtendedMap, IDimension } from "Telemetry";

const CONTENT_FETCH_SUCCESS_CODE = 200;
const CONTENT_FETCH_ERROR_CODE = 500;

/**
 * Api Content provider for i18n library
 * @param endpoint content fetching endpoint name
 * @returns content
 */
export const ApiContentProvider = () => {
    // Reference: https://stackoverflow.com/questions/70589040/how-to-incorporate-api-data-in-i18next-instead-of-static-file/70812533#70812533
    const loadContent = async (endpoint: string, callback: any) => {

        let telemetryProps: TelemetryProperties = {
            operationName: operationName.ApiContentProviderLoadContent,
            defaultErrorCode: ErrorCode.FailedToFetchPortalContent,
            exceptionType: ExceptionType.ContentServiceException,
        }
        let dimensions = new ExtendedMap<IDimension, string>([
            [Dimensions.Endpoint, endpoint],
        ]);

        const errResponse = callback(null, {
            status: CONTENT_FETCH_ERROR_CODE,
        });

        try {
            let apiResult = await ContentSvc.getContent(endpoint);

            if (apiResult?.hasError) {
                // Log exception and metrics
                logErrorAndSetApiFailureMetrics(apiResult, { ...telemetryProps, message: telemetryMessages.fetchPortalContentError }, Metrics.ContentAPIFailure, dimensions);

                return errResponse;
            }

            // Log trace and Set metrics
            logAndSetApiSuccessMetrics(apiResult, { ...telemetryProps, message: telemetryMessages.fetchPortalContentSuccess }, Metrics.ContentAPICounter, dimensions);

            return callback(null, {
                data: apiResult?.data,
                status: CONTENT_FETCH_SUCCESS_CODE,
            });

        } catch (error: any) {
            logUnhandledExceptionAndSetMetrics(

                error,
                {
                    ...telemetryProps,
                    message: telemetryMessages.fetchPortalContentUnhandledException,
                    defaultErrorCode: ErrorCode.FetchPortalContentUnhandledError
                },
                Metrics.ContentAPIFailure, dimensions);

            return errResponse;
        }
    };

    return {
        getContent: loadContent
    }
}
import { NavLink } from 'react-router-dom';
import styles from './nav.module.scss';
import { fetchText } from 'Providers/Localization';
import { pageRoutes } from 'Pages';

interface INavItem {
    id: string;
    url: string;
    title: string;
}

export const Navbar = () => {
    const NavItem = (props: INavItem) => (
        <NavLink
            to={props.url}
            className={({ isActive }) => (isActive ? `${styles.active}` : '')}
            role="link"
            id={props.id}
            data-testid={props.id}>
            {fetchText(props.title)}
        </NavLink >
    )

    return (
        <div className={styles.navBar}>
            <nav role="navigation" aria-label={fetchText('TopNav.AriaLabel')}>
                <NavItem id="nav-home" title="TopNav.Home" url={pageRoutes.home} />
                <NavItem id="nav-my-demos" title="TopNav.MyDemos" url={pageRoutes.demos.root} />
                <NavItem id="nav-new-demo-form" title="TopNav.Templates" url={pageRoutes.newDemo} />
                <NavItem id="nav-announcements" title="TopNav.Announcements" url={pageRoutes.announcements} />
            </nav>
        </div >
    );
};
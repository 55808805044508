export const generateGuid = (): string => {
    let dt = new Date().getTime();
    let guid = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g,
        function (c) {
            var rnd = Math.random() * 16; //random number in range 0 to 16
            rnd = (dt + rnd) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === 'x' ? rnd : (rnd & 0x3 | 0x8)).toString(16);
        });
    return guid;
}
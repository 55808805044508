import { AuthError, BrowserAuthError, PublicClientApplication, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common';
import { metricsLogger, telemetryLogger } from 'Providers/Telemetry';
import { AuthenticationProvider } from './AuthProvider';
import {
    AuthConfig,
    LoginRequest,
    ApiAccessTokenRequest,
    AuthConstants,
    AuthErrorCode,
    operationName
} from 'Providers/Authentication';
import { ExtendedMap, IDimension } from 'Telemetry';
import { OperationStatus } from 'Shared/enums';
import { getErrorCode, logAndSetMetrics, logUnhandledExceptionAndSetMetrics } from 'Shared/telemetry/telemetryHelper';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { Dimensions, ErrorCode, Metrics } from './Telemetry';

export class MsalAuthProvider implements AuthenticationProvider {
    private msalInstance: PublicClientApplication;
    constructor() {

        this.msalInstance = new PublicClientApplication(AuthConfig);
        this.msalInstance.handleRedirectPromise()
            .then((msalAuthResponse: any) => {
                if (msalAuthResponse && msalAuthResponse.account) {
                    this.setActiveAccount(msalAuthResponse.account)
                }
            })
            .catch(error => {
                this.handleErrors(error);
            });
    }

    isMsaAccount(userAccount: AccountInfo): boolean {
        return userAccount.tenantId === AuthConstants.MSA_TENANT_ID;
    }

    signIn(): Promise<void> {
        return this.signInBase(LoginRequest);
    }

    signInto = async (args: any, onSuccessCallback?: any, onErrorCallback?: any): Promise<void> => {
        const authParams: any = {
            loginHint: args.nextAccount.memberName
        };

        if (onSuccessCallback && onErrorCallback) {
            await this.signInBaseWithCallback(authParams, onSuccessCallback, onErrorCallback);
        }
        else {
            await this.signInBase(authParams);
        }
    }

    switch = async (): Promise<void> => {
        const authParams: any = {
            ...LoginRequest,
            prompt: AuthConstants.PROMPT_TYPE
        };
        this.signInBase(authParams);
    }

    switchTo = async (args: any, onSuccessCallback?: any, onErrorCallback?: any): Promise<void> => {
        await this.signInto(args, onSuccessCallback, onErrorCallback);
    }

    signOut = async (): Promise<void> => {
        this.msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/"
        });
    }

    setActiveAccount(userAccount: AccountInfo): void {
        this.msalInstance.setActiveAccount(userAccount);
    }

    getActiveAccount(): AccountInfo | null {
        return this.msalInstance.getActiveAccount() ?? null;
    }

    isAuthenticated(): boolean {
        let authAccount = this.msalInstance.getActiveAccount();
        return authAccount != null;
    }

    getMsalInstance(): PublicClientApplication {
        return this.msalInstance;
    }

    // to be deprecated
    getApiAccessTokenAsync = async (userAccount?: AccountInfo, tokenScope?: any): Promise<string> => {
        const request: SilentRequest = {
            ...ApiAccessTokenRequest,
            account: userAccount ?? this.getActiveAccount() ?? undefined,
            scopes: [...(tokenScope ?? ApiAccessTokenRequest.scopes)]
        };

        const token = await this.msalInstance.acquireTokenSilent(request)
            .then((tokenResponse: any) => {
                const accessToken = tokenResponse?.accessToken;
                if (!accessToken || accessToken.localeCompare('undefined', undefined, { sensitivity: 'base' }) === 0) {
                    telemetryLogger.trackEvent(`Msal token acquisition failed. Malformed token: ${accessToken}.`);
                    this.errorHandlingForRedirect(request, { errorCode: AuthErrorCode.NO_TOKENS_FOUND });
                }
                return accessToken;
            })
            .catch((error: any) => {
                metricsLogger.emitExceptionLoggedMetric({ UIOperation: "MsalAuthProvider.getApiAccessTokenAsync" });
                telemetryLogger.trackEvent(`Msal silent token acquisition failed with error: ${error?.errorCode} Message: ${error?.errorMessage}. Acquiring token using redirect.`);
                this.errorHandlingForRedirect(request, { errorCode: AuthErrorCode.NO_TOKENS_FOUND });
            });

        return token;
    }

    acquireTokenSilentAsync = async (scopes: string[], userAccount?: AccountInfo): Promise<string> => {
        let dimensions = new ExtendedMap<IDimension, string>();

        let telemetryProperties: TelemetryProperties = {
            operationName: operationName.MsalAcquireSilentToken,
            defaultErrorCode: ErrorCode.MsalSilentTokenAcquisitionFailed,
            exceptionType: ExceptionType.MsalException
        }

        const request: SilentRequest = {
            ...ApiAccessTokenRequest,
            account: userAccount ?? this.getActiveAccount() ?? undefined,
            scopes: scopes
        };

        const token = await this.msalInstance.acquireTokenSilent(request)
            .then((tokenResponse: any) => {
                const accessToken = tokenResponse?.accessToken;
                if (!accessToken || accessToken.localeCompare('undefined', undefined, { sensitivity: 'base' }) === 0) {
                    // Add error dimensions
                    dimensions.addDimension(Dimensions.ErrorCode, getErrorCode(tokenResponse, ErrorCode.MsalSilentTokenAcquisitionFailed));
                    dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.FAILURE);
                    dimensions.addDimension(Dimensions.MsalErrorCode, AuthErrorCode.NO_TOKENS_FOUND);

                    logAndSetMetrics(
                        {
                            ...telemetryProperties,
                            message: `Msal token acquisition failed. Malformed token: ${accessToken}.`
                        },
                        Metrics.MsalAuthenticationFailure,
                        dimensions);

                    this.errorHandlingForRedirect(request, { errorCode: AuthErrorCode.NO_TOKENS_FOUND });
                }
                return accessToken;
            })
            .catch((error: any) => {

                // Add error dimensions
                dimensions.addDimension(Dimensions.MsalErrorCode, error?.errorCode || AuthErrorCode.NO_TOKENS_FOUND);

                logUnhandledExceptionAndSetMetrics(
                    error,
                    {
                        ...telemetryProperties,
                        message: `Msal silent token acquisition failed. Acquiring token using redirect.`
                    },
                    Metrics.MsalAuthenticationFailure,
                    dimensions)

                this.errorHandlingForRedirect(request, { errorCode: AuthErrorCode.NO_TOKENS_FOUND });
            });

        return token;
    }

    private signInBase = async (authParams: any): Promise<void> => {
        await this.msalInstance.loginRedirect(authParams);
    }

    private signInBaseWithCallback = async (authParams: any, onSuccessCallback?: any, onErrorCallback?: any): Promise<void> => {

        let popUpSigninConfig = AuthConfig;

        // when we login in using popup, sometimes the popup doesn't get closed automatically.
        // this is the recommended fix from MSAL team git docs as it states:
        // When using popup APIs we recommend setting the redirectUri to a blank page or a page that does not implement MSAL. 
        // This will help prevent potential issues as well as improve performance.
        // Referenced link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#optional-configure-authority

        popUpSigninConfig.auth.redirectUri = `${window.location.origin}/blank.html`;

        let msalInstanceForPopupSignin = new PublicClientApplication(popUpSigninConfig);

        await msalInstanceForPopupSignin.loginPopup(authParams).then(onSuccessCallback).catch(onErrorCallback);
    }

    private errorHandlingForRedirect = async (request: any, error?: any): Promise<void> => {
        if (MsalAuthProvider.requireInteraction(error?.errorCode)) {
            const redirectRequest: RedirectRequest = {
                scopes: [...request.scopes],
                redirectStartPage: window.location.href
            }
            await this.msalInstance.acquireTokenRedirect(redirectRequest);
        }
    }

    private handleErrors = (error: any): void => {
        if (MsalAuthProvider.requireInteraction(error?.errorCode)) {
            telemetryLogger.trackWarning(`Msal token acquisition failed with error: ${error?.errorCode}.`);
            this.signIn();
        } else {
            let dimensions = new ExtendedMap<IDimension, string>([
                [Dimensions.OperationName, operationName.MsalSignInRedirect],
                [Dimensions.OperationStatus, OperationStatus.FAILURE.toString()],
                [Dimensions.MsalErrorCode, error?.errorCode || ErrorCode.MsalSignInRedirectFailed]
            ]);

            let telemetryProperties: TelemetryProperties = {
                operationName: operationName.MsalSignInRedirect,
                message: `Client authentication error: ${error?.errorCode}. Message: ${error?.errorMessage}`,
                defaultErrorCode: ErrorCode.MsalSignInRedirectFailed,
                exceptionType: ExceptionType.MsalException
            }

            logUnhandledExceptionAndSetMetrics(
                error,
                telemetryProperties,
                Metrics.MsalAuthenticationFailure,
                dimensions
            );

            this.signIn();
        }
    }

    private static requireInteraction = (errorMessage: AuthError['errorMessage']): boolean => {
        if (!errorMessage?.length) {
            return false;
        }

        return (
            errorMessage.includes(AuthErrorCode.CONSENT_REQUIRED) ||
            errorMessage.includes(AuthErrorCode.INTERACTION_REQUIRED) ||
            errorMessage.includes(AuthErrorCode.LOGIN_REQUIRED) ||
            errorMessage.includes(AuthErrorCode.NO_TOKENS_FOUND) ||
            errorMessage.includes(AuthErrorCode.INVALID_GRANT) ||
            errorMessage.includes(AuthErrorCode.NO_ACCOUNT_ERROR)
        );
    };
}
/*
Keep Error Codes in Alphabetical order
*/
export const ErrorCode = {
    FailedToAddUserTemplateMapping: 'FailedToAddUserTemplateMapping',
    FailedToFetchUserTemplatesByDemoType: 'FailedToFetchUserTemplatesByDemoType',
    FailedToFetchTemplatesByName: 'FailedToFetchTemplatesByName',
    FailedToOpenClickthrough: 'FailedToOpenClickthrough',
    FetchUserTemplatesByDemoTypeUnhandledError: 'FetchUserTemplatesByDemoTypeUnhandledError',
    FetchTemplatesByNameUnhandledError: 'FetchTemplatesByNameUnhandledError',
    FailedToRemoveUserTemplateMapping: 'FailedToRemoveUserTemplateMapping',
    NoError: 'NoError',
    RemoveUserTemplateMappingUnhandledError: 'RemoveUserTemplateMappingUnhandledError',
    UserTemplateMappingUnhandledError: 'UserTemplateMappingUnhandledError',
}
import { ButtonIcon } from 'Shared/Controls';
import { useCopyToClipboard } from './hooks/useCopyToClipboard';
import { BaseProps } from '../base/ComponentProps';

interface CopyToClipboardProps extends BaseProps {
    textToCopy: string;
    textType: string;
}

// TODO: move to resources
const strings: any = {
    copyIcon: 'Copy',
    copyIconAriaLabel: 'Copy',
    copiedIcon: 'CheckMark',
    copiedIconAriaLabel: 'Copied',
}

export const CopyToClipboard = (props: CopyToClipboardProps) => {

    const { isCopied, onCopyClick } = useCopyToClipboard(props.textToCopy);

    return (
        <div>
            {isCopied &&
                <ButtonIcon
                    id='btnCopiedIcon'
                    iconName={strings.copiedIcon}
                    ariaLabel={`${strings.copiedIconAriaLabel}`}
                    disabled={true}
                    dataAttributes={props.dataAttributes}
                />}
            {!isCopied &&
                <ButtonIcon
                    id='btnNotCopyIcon'
                    iconName={strings.copyIcon}
                    ariaLabel={`${strings.copyIconAriaLabel} ${props.textType}`}
                    onClick={onCopyClick}
                    dataAttributes={props.dataAttributes}
                />}
        </div>
    )
}
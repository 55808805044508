import { Dimensions } from "./Dimensions";

/*
Keep Metrics in Alphabetical order
*/
export const Metrics = {
    CESRenderSurveyCounter: {
        metricName: 'CESRenderSurveyCounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    CESRenderSurveyFailure: {
        metricName: 'CESRenderSurveyFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.OperationStatus,
            Dimensions.ErrorCode,
        ],
        enabled: true,
    },

    NsatCheckEligibilityAPIFailure: {
        metricName: 'NsatCheckEligibilityAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    NsatCheckEligibilityAPICounter: {
        metricName: 'NsatCheckEligibilityAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    NsatRenderSurveyCounter: {
        metricName: 'NsatRenderSurveyCounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    NsatRenderSurveyFailure: {
        metricName: 'NsatRenderSurveyFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    NsatSendEmailCounter: {
        metricName: 'NsatSendEmailCounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },

    NsatSendEmailFailure: {
        metricName: 'NsatSendEmailFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus
        ],
        enabled: true,
    },
};

export enum DemoHubStatus {
    NEW = 1,
    INPROGRESS = 2,
    SUCCEEDED = 3,
    FAILED = 4,
}

export enum OperationStatus {
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
}

export enum SurveyType {
    NSAT = "nsat"
    // Add more sources as needed
}
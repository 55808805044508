import { NsatSvc } from 'Services';
import { Nsat } from 'Shared/constants';
import { AuthProvider } from 'Providers/Authentication';
import { telemetryLogger } from 'Providers/Telemetry';
import { ExceptionType } from 'Services/base/exceptionTypes';

/**
 * @description Utility class for adding global event listener
 */
export class DomElementEventListeners {

    /**
     * Attach event listener for capturing Nsat event
     * @returns
     */
    static addNsatEventListeners(domElementSelector?: any): any {
        const domElementContainer = domElementSelector ? document.querySelector(domElementSelector) as HTMLElement : document;
        if (domElementContainer) {
            const elements = domElementContainer.querySelectorAll('[data-isnsatevent="true"]');
            const eventCallback = (e: MouseEvent) => {
                var currentUser = AuthProvider.getActiveAccount();
                if (!currentUser) {
                    telemetryLogger.trackException('NsatSurvey - current user is null', { exceptionType: ExceptionType.NsatServiceException });
                    return;
                }
                telemetryLogger.trackEvent(Nsat.EventName);
                NsatSvc.addEvent(currentUser?.localAccountId, Nsat.TeamName, Nsat.EventName)
            }

            for (var i = 0; i < elements.length; i++) {
                let element: HTMLElement = elements[i] as HTMLElement;
                element.addEventListener('click', eventCallback);
            }
        }
    }
}
import {
    LatencyCancel,
    LatencyLoader,
    LatencyWrapper,
} from "@fluentai/react-copilot";
import { ChatResponse, CopilotRequest } from "DemoHubCopilot/models/CopilotRequest";
import { UserResponse } from "./UserResponse";
import { CopilotResponse } from "./CopilotResponse";

import styles from './answer.module.scss';

export interface AnswerProps {
    answers: ChatResponse[];
    loading: boolean;
    onPromptClick: (copilotRequest: CopilotRequest) => void;
    onStopGenerating: () => void;
    disableForm: boolean;
}

export const AnswerPanel = (props: AnswerProps) => {
    const { loading, answers, disableForm } = props;

    const AnswerComponent = (message: ChatResponse) => {
        if (message.role === 'user') {
            return (
                <UserResponse
                    role={message.role}
                    content={message.content}
                    responseActions={message.responseActions}
                    onPromptClick={props.onPromptClick} />
            )
        }

        if (message.role === 'assistant') {
            return (
                <CopilotResponse
                    role={message.role}
                    content={message.content}
                    responseActions={message.responseActions}
                    onPromptClick={props.onPromptClick}
                    disableForm={props.disableForm} />
            )
        }

        return (
            <div>
                {/*Add Response style here if there is any error*/}
                <div>Sorry: Try Again Please.</div>
            </div>
        )
    }

    return (
        <div className={styles.answerPanel}>
            {answers.map((answer, index) => {
                return (
                    <AnswerComponent key={`answer-${index}`} {...answer} />
                )
            })}
            {loading && <LatencyWrapper>
                <LatencyLoader {...props} header="Please note that this process may take a while." />
                <LatencyCancel onClick={() => { props.onStopGenerating() }}>Stop generating</LatencyCancel>
            </LatencyWrapper>}
        </div>
    );
};
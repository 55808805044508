import { Dimensions } from "./Dimensions";

export const Metrics = {
    ContentLoaderAPIFailure: {
        metricName: 'ContentLoaderAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode
        ],
        enabled: true,
    },
    FeatureFlagAPIFailure: {
        metricName: 'FeatureFlagAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode
        ],
        enabled: true,
    },
    FeatureFlagAPICounter: {
        metricName: 'FeatureFlagAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.Role
        ],
        enabled: true,
    },
    RoleAPIFailure: {
        metricName: 'RoleAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode
        ],
        enabled: true,
    },
    RoleAPICounter: {
        metricName: 'RoleAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.Role
        ],
        enabled: true,
    },
    UserAPIFailure: {
        metricName: 'UserAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.Role
        ],
        enabled: true,
    },
    UserAPICounter: {
        metricName: 'UserAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.Role
        ],
        enabled: true,
    },
    GlobalContextRegistrationFailure: {
        metricName: 'GlobalContextRegistrationFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.Role
        ],
        enabled: true,
    }    
};
import { useEffect } from 'react';
import { IBreadcrumbItem } from '@fluentui/react';
import { pageRoutes } from 'Pages';

import {
    useLocation,
    useNavigate
} from 'react-router-dom';
import { Announcement } from 'Models';
import { fetchText, fetchObject } from 'Providers/Localization';

const contentFileName = 'annoucements';

export const useAnnouncements = () => {
    // get the hash value from the Url. hash is the value of text appended with symbol # in the url 
    // e.g.#announcement - 1 in the https://demohubsite.com/announcements#announcement-1 url.
    const { hash } = useLocation();
    const navigate = useNavigate();
    const contentOriginUrl = process.env.REACT_APP_SHAREPOINT_SITE_ORIGIN; // TODO: move it to a helper

    // get the content from the json file
    const announcementsPageContent = {
        heading: fetchText('SectionHeading', contentFileName),
        announcements: fetchObject<Announcement[]>('Announcements', contentFileName)
    }

    const breadcrumbNavClick = (pageUrl: string) => {
        navigate({
            pathname: pageUrl,
        });
    }

    const breadcrumbItems: IBreadcrumbItem[] = [
        {
            text: fetchText('TopNav.Home'), key: 'Home', as: 'a',
            onClick: () => { breadcrumbNavClick(pageRoutes.home) },
            id: 'lnkbreadcrumbHome'
        },
        {
            text: fetchText('TopNav.Announcements'), key: 'Announcements', as: 'a',
            onClick: () => { breadcrumbNavClick(pageRoutes.announcements) }, isCurrentItem: true,
            id: 'lnkBreadcrumbAnnouncements'
        }
    ];

    useEffect(() => {
        // if there is no hash i.e. page is opened directly, hence no need to set any focus
        if (!hash || hash.length === 0) {
            return;
        }

        const announcementSection = document.querySelector(hash);
        if (announcementSection) {
            announcementSection.scrollIntoView();
        }
    }, [hash]);

    return {
        announcementsPageContent,
        breadcrumbItems,
        contentOriginUrl
    }
};
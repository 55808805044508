import { fetchText } from 'Providers/Localization';
import { AnnouncementCard, BreadcrumbMenu } from 'Shared/Components';
import { MessageBox } from 'Shared/Controls';
import { CommonUtils, DateUtils } from 'Shared/Utils';
import { useAnnouncements } from './hooks/useAnnouncements';

import styles from './announcements-page.module.scss';
import { Announcement } from 'Models';

export const AnnouncementsPage = () => {
    const {
        announcementsPageContent,
        breadcrumbItems,
        contentOriginUrl
    } = useAnnouncements();

    const Announcements = () => {
        if (!announcementsPageContent?.announcements || announcementsPageContent?.announcements?.length === 0) {
            return (
                <MessageBox
                    message={fetchText('NotFoundMessages.NoAnnouncementsFoundMsg')}
                    type="info"
                />
            )
        }

        return (
            <>
                {announcementsPageContent?.announcements?.map((announcement: Announcement, index: number) => {
                    return (
                        <AnnouncementCard
                            key={`announcement-${index}`}
                            id={CommonUtils.formatStringInSlugFormat(announcement.Title)}
                            title={announcement.Title}
                            subTitle={`${DateUtils.formatUtcDate(announcement.ModifiedOn)} by ${announcement.CreatedBy}`}
                            imageUrl={announcement.ImageUrl}
                            description={announcement.LongDescription}
                            inlineLinksOriginUrl={contentOriginUrl}
                            isDescriptionRichtext={true}
                        />
                    )
                })}
            </>
        )
    }

    return (
        <div className={`sp-content ${styles.announcementsPage}`}>
            <h2>
                {announcementsPageContent?.heading}
            </h2>
            <div className={styles.announcementsContainer}>
                <BreadcrumbMenu
                    className={styles.breadcrumbsMenu}
                    items={breadcrumbItems}
                    ariaLabel={fetchText('AriaLabels.AnnouncementBredcrumbsMenu')} />
                <Announcements />
            </div>
        </div>
    )
}
import axios, { CancelTokenSource } from 'axios';
import { createEnableMocksFn } from 'Shared/utils/mock-utilities';
import { apiSuccessResponse, apiFailureResponse, getErrorResponse } from '../base/getApiResponse';
import { ApiResult } from '../base/models';
import { ApiName } from '../base/enums';
import { create } from '../base/client';
import { CopilotSvcContract } from './copilotSvcContract';
import { ConversationRequest, DemoCopilotResponse } from './models/ConversationRequest';

const client = create(
    ApiName.Copilot,
    createEnableMocksFn(async () => import('./copilotSvc.mock'))
);

export class CopilotService implements CopilotSvcContract {

    public getAnswer = async (request: ConversationRequest, cancelTokenSource: CancelTokenSource): Promise<ApiResult<DemoCopilotResponse>> => {
        try {
            var endpoint = 'conversation';
            let response = await client.post(endpoint, request, {
                cancelToken: cancelTokenSource.token,
                timeout: 300000
            });
            return apiSuccessResponse(response);
        } catch (error: any) {             
            return apiFailureResponse(error,
                getErrorResponse(error));
        }
    }
}
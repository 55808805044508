import { ApiName } from './enums';

/**
 * This method is use to enable/disable the mock data for the apis
 * If we set the value to true, then it will not make the actual api call,
 * and  will use the mock data as response for that api.
 * DO NOT CHECK IN THE CODE WITH ANY API VALUE SET TO true.
 */
export const areMocksEnabled: { [key in ApiName]: boolean } = {
    [ApiName.Content]: false,
    [ApiName.Copilot]: false,
    [ApiName.Demos]: false,
    [ApiName.FeatureFlags]: false,
    [ApiName.Nsat]: false,
    [ApiName.Roles]: false,
    [ApiName.Templates]: false,
    [ApiName.Users]: false,
    [ApiName.UserTemplates]: false,
    [ApiName.Template]: false,
    [ApiName.Nsats]: false
}
import { Link } from '@fluentui/react';
import { BaseProps } from 'Shared/Components';
import { MessageBox } from 'Shared/Controls';
import { fetchText } from 'Providers/Localization';
import { Resource } from '../../models/assets-resources';
import styles from '../../home.module.scss';

interface AssetsAndResourcesProps extends BaseProps {
    heading: string;
    assetsResources: Resource[] | null;
}

export const AssetsAndResources = (props: AssetsAndResourcesProps) => {
    const { assetsResources } = props;

    const categories = assetsResources && assetsResources.length > 0 ?
        Array.from(new Set(assetsResources.map((item: Resource) => item.Category))) : ['All'];

    const LinksList = (props: any) => {
        const categoryWiseLinks = assetsResources?.filter((f: Resource) => f.Category === props.category)

        return (
            <ul>
                {categoryWiseLinks?.length && categoryWiseLinks.map((link: Resource, index: number) => {
                    return (
                        <li key={`link-${index}`}>
                            <Link
                                id={`lnkAssetsResources-${props.category?.substring(0,3)}-${index}`}
                                aria-label={link.LinkAriaLabel}
                                title={link.LinkTitle}
                                href={link.LinkUrl}
                                target="_blank">
                                {link.LinkTitle}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        )
    }

    return (
        <div className={styles.assetsAndResources}>
            <h2>
                {props.heading}
            </h2>
            {!assetsResources &&
                <MessageBox
                    message={fetchText('NotFoundMessages.NoAssetsResourcesFoundMsg')}
                    type="info"
                />
            }
            {assetsResources && assetsResources.length &&
                <div className={styles.assetsSection}>
                    {categories && categories.length && categories.map((category: any, index: number) => {
                        return (
                            <div className={styles.assetsContainer} key={`category-${index}`}>
                                <h3> {category}</h3>
                                <LinksList category={category} />
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}
// add content files name here without extension (.json)
// add new file name in alphabetical order
export const fileNamespaces: string[] = [
    'annoucements',
    'assets-resources',
    'coe-toolkit-template-detail',
    'common',
    'copilotasset',
    'fno-template-detail',
    'financial-services-template-detail',
    'lowcode-template-detail',
    'manufacturing-demo-template-detail',
    'retail-template-detail',
    'solutionplay-template-detail'
]
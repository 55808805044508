import {
    Dialog,
    DialogFooter,
    Stack,
    DefaultButton,
    PrimaryButton,
    Label,
    TextField,
    SpinnerSize,
    DialogType,
    TooltipHost
} from '@fluentui/react';
import { fetchText } from 'Providers/Localization';
import { ButtonIcon, Loader, MessageBox } from 'Shared/Controls';
import { useRefreshDemo, RefreshState } from '../../hooks/useRefreshDemoEnvironments';
import styles from './refreshdemoenvs.module.scss';

const modelProps = {
    isBlocking: true,
    layerProps: { eventBubblingEnabled: true },
    className: 'refresh-demos-video-dialog'
};

interface RefreshMydemosProps {
    OnSuccessfullRefresh: () => void;
}

export const RefreshDemoEnvironments = (props: RefreshMydemosProps) => {
    const { formValues,
        formErrors,
        onSaveClick,
        onInputChange,
        refreshingDemos,
        demosRefreshResultState,
        showHideDialog,
        showHideDialogClick,
        onTenantAdminValidation,
        formSubmissionNotAllowed,
        getTextFor,
        errorMessage,
        successMessage
    } = useRefreshDemo(props.OnSuccessfullRefresh);

    const dialogContentProps = {
        type: DialogType.normal,
        title: getTextFor('Heading')
    };

    return (
        <div className={styles.syncDemosDialog}>
            <div className={styles.openDialogAction}>
                <DefaultButton secondaryText={getTextFor('RefreshButtonText')} onClick={showHideDialogClick} text={getTextFor('RefreshButtonText')} id='btnToggleRefreshDemos'/>
                <TooltipHost content={getTextFor('RefreshButtonTooltip')}>
                    <ButtonIcon iconName="info" id='btnInfoRefreshDemo' />
                </TooltipHost>
            </div>
            <Dialog hidden={showHideDialog} onDismiss={showHideDialogClick}
                dialogContentProps={dialogContentProps}
                modalProps={modelProps}>
                <div>
                    {refreshingDemos &&
                        <Loader Message={getTextFor('RefreshInProgressMessage')}
                            Size={SpinnerSize.small} />
                    }
                    {demosRefreshResultState === RefreshState.Completed && !errorMessage && !errorMessage.length &&
                        <MessageBox
                            type="success"
                            isMessageRichtext={true}
                            message={successMessage}
                        />
                    }
                    <Stack>
                        {!refreshingDemos && !errorMessage && demosRefreshResultState === RefreshState.NotStarted &&
                            <>
                                <Stack.Item>
                                    <Stack.Item>
                                        <Label required htmlFor="tenantAdminName">{getTextFor('TenantAdminLabel')}</Label>
                                        <MessageBox type="info"
                                            isMessageRichtext={true}
                                            message={getTextFor('Description')}
                                        />
                                    </Stack.Item>
                                    <TextField
                                        id="tenantAdminName"
                                        role="textbox"
                                        type="email"
                                        aria-required="true"
                                        placeholder={getTextFor('TenantAdminTextPlaceholder')}
                                        name="tenantAdminName"
                                        value={formValues.tenantAdminName}
                                        onChange={onInputChange}
                                        onBlur={(e: Event) => { onTenantAdminValidation(e); }}
                                        errorMessage={formErrors.tenantAdminName}
                                        {...(formErrors.tenantAdminName && { formErrors: true, helperText: formErrors.tenantAdminName })}
                                    />
                                </Stack.Item>
                            </>
                        }
                        {
                            errorMessage &&
                            <Stack.Item>
                                <MessageBox type="error"
                                    isMessageRichtext={true}
                                    message={errorMessage}
                                />
                            </Stack.Item>
                        }
                        <Stack.Item>
                            <DialogFooter>
                                {(!errorMessage && !refreshingDemos && demosRefreshResultState === RefreshState.NotStarted) &&
                                    <PrimaryButton
                                        id='btnRefreshDemos'
                                        onClick={onSaveClick}
                                        text={fetchText('CommonButtonsTitle.Refresh')}
                                        disabled={formSubmissionNotAllowed} />
                                }
                                <DefaultButton
                                    id='btnCloseRefreshDemos'
                                    onClick={showHideDialogClick}
                                    text={formSubmissionNotAllowed ? fetchText('CommonButtonsTitle.Close') : fetchText('CommonButtonsTitle.Cancel')} />
                            </DialogFooter>
                        </Stack.Item>
                    </Stack>
                </div>
            </Dialog>
        </div>
    );
};
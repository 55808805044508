import {
    Dropdown,
    Button,
    Field,
    Input,
    Option,
    Textarea,
    MessageBar,
    MessageBarBody
} from "@fluentui/react-components";
import {
    CopilotRequest,
    DataGenerationProvidedInput
} from 'DemoHubCopilot/models/CopilotRequest';
import { dataGenIndustries, } from 'DemoHubCopilot/copilot.settings';
import {
    useDataGenerationForm,
    DataGenerationInputParams
} from './hooks/useDataGenerationForm';

import styles from './data-generation-form.module.scss';
import React, { useState } from 'react';

export interface DataGenerationInputFormProps {
    onPromptClick: (copilotRequest: CopilotRequest) => void
    dataGenerationProvidedInput?: DataGenerationProvidedInput;
    showNlpInputForm?: boolean;
    disableForm?: boolean;
}

export const DataGenerationInputForm = (props: DataGenerationInputFormProps) => {
    const hookParams: DataGenerationInputParams = {
        onPromptClick: props.onPromptClick,
        dataGenerationProvidedInput: props.dataGenerationProvidedInput
    }

    const {
        showInputFields,
        formValues,
        setFormValues,
        filteredEntities,
        filteredApps,
        nlpPromptInput,
        setNlpPromptInput,
        onRequiredDetailClicks,
        onIndustryChange,
        onAppsChange,
        onAppsBlur,
        onEntitiesChange,
        onEntitiesBlur,
        onInputCollectedActionClick,
        onInputPreviewCtaClick,
        onFinalActionClick,
        disableFormSubmitButton } = useDataGenerationForm(hookParams);
        const [isInputValid, setIsInputValid] = useState(true);

    const InputFormFields = () => {
        return (
            <div className={styles.formContainer}>
               
                <div className={styles.formFieldContainer}>
                    <Field label="MSX Id">
                        <Input
                            id="data-gen-msxid"
                            name="data-gen-msxid"
                            defaultValue={formValues.msxId}
                            onBlur={(e) => {
                                setFormValues({
                                    ...formValues,
                                    msxId: e.target.value
                                });
                            }}
                            tabIndex={0}
                        />
                    </Field>
                </div>
                <div className={styles.formFieldContainer}>
                    <Field label="Industry" required>
                        <Dropdown
                            id="dwn-data-gen-industries"
                            aria-labelledby="data-gen-industries"
                            placeholder="Industry"
                            onOptionSelect={(e, data) => onIndustryChange(e, data.optionValue)}
                            defaultValue={formValues.industry}
                            tabIndex={1}
                        >
                            {dataGenIndustries.map(industry => (
                                <Option key={industry.id} value={industry.id}>{industry.name}</Option>
                            ))}
                        </Dropdown>
                    </Field>

                </div>
                <div className={styles.formFieldContainer}>
                    <Field id="data-gen-apps" label="Apps" required>
                        <Dropdown
                            id="dwn-data-gen-apps"
                            multiselect={true}
                            aria-labelledby="data-gen-apps"
                            placeholder="Select Apps"
                            onOptionSelect={onAppsChange}
                            onBlur={onAppsBlur}
                            value={formValues.apps.join(", ")}
                            defaultSelectedOptions={formValues.apps}
                            tabIndex={2}
                        >
                            {filteredApps?.map(app => (
                                <Option key={app.id} value={app.id}>{app.name}</Option>
                            ))}
                        </Dropdown>
                    </Field>
                </div>
                <div className={styles.formFieldContainer}>
                    <Field id="data-gen-entities" label="Entity" required>
                        <Dropdown
                            id="dwn-data-gen-entities"
                            multiselect={true}
                            aria-labelledby="data-gen-entities"
                            placeholder="Select Entities"
                            onOptionSelect={onEntitiesChange}
                            onBlur={onEntitiesBlur}
                            value={formValues.entities.join(", ")}
                            defaultSelectedOptions={formValues.entities}
                            tabIndex={3}
                        >
                            {filteredEntities?.map(entity => (
                                <Option key={entity.id} value={entity.id}>{entity.name}</Option>
                            ))}
                        </Dropdown>
                    </Field>
                </div>
                <div className={styles.formFieldContainer}>
                    <Field label="Number of rows to be generated" required>
                            <Input
                                id="in-ddg-noOfRecords"
                                type="number"
                                min="1"
                                max="100"
                                name="ddg-noOfRecords"
                                defaultValue={formValues.noOfRecords?.toString()}
                                tabIndex={4}
                                onBlur={(e) => {
                                    const value = parseInt(e.target.value);
                                    if (value > 100) {
                                        setIsInputValid(false);
                                    } else {
                                        setIsInputValid(true);
                                        setFormValues({
                                            ...formValues,
                                            noOfRecords: value
                                        });
                                    }
                                }} />
                            {!isInputValid && 
                                <MessageBar intent="error">
                                    <MessageBarBody>
                                        Please enter a number less than or equal to 100.
                                    </MessageBarBody>
                                </MessageBar>
                            }
                    </Field>
                </div>
                <div className={styles.formActionContainer}>
                    <Button
                        id="data-gen-Next"
                        tabIndex={5}
                        disabled={disableFormSubmitButton}
                        onClick={() => { onInputCollectedActionClick(); }}
                        appearance="primary">Next</Button>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.dataGenInputsContainer}>
            {!showInputFields && !props.dataGenerationProvidedInput &&
                <Button
                    id="data-gen-required-details"
                    tabIndex={0}
                    onClick={() => { onRequiredDetailClicks(); }}
                    appearance="secondary">Required details</Button>
            }
            {showInputFields &&
                <div>
                    <InputFormFields />
                </div>
            }
            {props.dataGenerationProvidedInput && !props.showNlpInputForm &&
                <div>
                    <div className={styles.selectedInfoCard}>
                        <div>
                            MSX ID: {props.dataGenerationProvidedInput?.msxId}
                        </div>
                        <div>
                            Industry: {props.dataGenerationProvidedInput?.industry}
                        </div>
                        <div>
                            App: {props.dataGenerationProvidedInput?.apps?.join(', ')}
                        </div>
                        <div>
                            Entity: {props.dataGenerationProvidedInput?.entities.join(', ')}
                        </div>
                        <div>
                            No. of rows to be generated: {props.dataGenerationProvidedInput?.noOfRecords}
                        </div>
                    </div>
                    <Button id="data-gen-continue" onClick={() => { onInputPreviewCtaClick(); }} appearance="primary">Continue</Button>
                </div>}

            {props.showNlpInputForm &&
                <div className={styles.nlpInputContainer}>
                    <p>Example: <i>The customer owns a coffee company, ensure all addresses are within the United States. Ensure that all account names are relevant to the coffee business. Moreover, ensure that the Knowledge articles cover a range of topics including the coffee brewing process, coffee machine delivery, repair, warranty, and returns.</i></p>
                    <Textarea
                        disabled={props.disableForm}
                        placeholder="Type here to describe data to be generated."
                        maxLength={500}
                        value={nlpPromptInput}
                        onChange={(e) => {
                            setNlpPromptInput(e.target.value);
                        }} />
                    <div className={styles.formActionContainer}>
                        <Button
                            id="data-gen-nlp-submit"
                            disabled={props.disableForm}
                            onClick={() => { 
                                onFinalActionClick(); }}
                            appearance="primary">Submit</Button>
                    </div>
                </div>}
        </div>
    );
};
import {
    MessageBar,
} from '@fluentui/react';
import {
    Pagination,
} from 'Shared/Controls';
import {
    messages,
    pageSize
} from '../../demorequests.settings';
import styles from '../demorequests.module.scss';
import { ClickthroughRequest } from 'Services/userTemplateSvc/models/ClickthroughRequest';
import { ClickthroughCard } from './ClickthroughCard';

interface ClickthroughRequestListProps {
    totalCount: number;
    currentPage: number;
    setCurrentPage: any;
    clickthroughData?: ClickthroughRequest[];
    openClickthrough: (clickthroughRequestUrl: string) => void;
    unPinClickthroughRequest: (clickthroughRequestItem: ClickthroughRequest) => void;
}

export const ClickthroughRequestList = (props: ClickthroughRequestListProps) => {
    const { clickthroughData } = props;
    return (
        <div key="demo-request-list" id="demos-list-container">
            {clickthroughData && clickthroughData.length > 0 ? (
                clickthroughData.map((clickthroughItem: ClickthroughRequest) => {
                    return (
                        <ClickthroughCard
                            clickthroughRequestItem={clickthroughItem}
                            openClickthrough={props.openClickthrough}
                            unPinClickthroughRequest={props.unPinClickthroughRequest}
                            isDescriptionRichtext={true}
                            key={clickthroughItem.ClickthroughRequestId}
                        />
                    );
                })
            ) : (
                <div className={styles.infoBox} key="no-data-found">
                    <MessageBar delayedRender={false} role="none">
                        {messages.noClickthroughRecordsFound}
                    </MessageBar>
                </div>
            )}

            <Pagination
                className="pagination-bar"
                currentPage={props.currentPage}
                totalCount={props.totalCount}
                pageSize={pageSize}
                onPageChange={page => props.setCurrentPage(page)}
            />
        </div>
    )
}

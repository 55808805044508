import * as React from "react";

import {
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover,
} from "@fluentui/react-components";
import {
    bundleIcon,
    ChatAddRegular,
    ChatAddFilled,
    FullScreenMaximizeFilled,
    FullScreenMaximizeRegular,
    FullScreenMinimizeFilled,
    FullScreenMinimizeRegular,
    Dismiss20Filled,
    Dismiss20Regular,
    MoreHorizontal20Filled,
    MoreHorizontal20Regular
} from "@fluentui/react-icons";
import styles from './copilot-header.module.scss';
import { useCopilotHeader } from './hooks/useCopilotHeader';

const FullscreenIcon = bundleIcon(FullScreenMaximizeFilled, FullScreenMaximizeRegular);
const TriggerIcon = bundleIcon(MoreHorizontal20Filled, MoreHorizontal20Regular);
const MinScreenIcon = bundleIcon(FullScreenMinimizeFilled, FullScreenMinimizeRegular);
const NewChatIcon = bundleIcon(ChatAddFilled, ChatAddRegular);
const DismissIcon = bundleIcon(Dismiss20Filled, Dismiss20Regular);

export interface CopilotMenuProps {
    onNewChatClick: () => void;
}

export const CopilotMenu = (props: CopilotMenuProps) => {
    const {
        onFullscreenClick,
        onMinScreenClick,
        onToggleCoPilot
    } = useCopilotHeader({
        onNewChatClick: props.onNewChatClick
    })

    return (
        <div>
            <div className={styles.copilotHeader}>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <i title="More options"><TriggerIcon /></i>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem title="Start new chat" icon={<NewChatIcon />} onClick={() => { props.onNewChatClick() }}>New chat</MenuItem>
                            <MenuItem title="Get to Full screen" icon={<FullscreenIcon />} onClick={() => { onFullscreenClick() }}>Full screen</MenuItem>
                            <MenuItem title="Minimize the screen" icon={<MinScreenIcon />} onClick={() => { onMinScreenClick() }}>Minimize</MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
                <i title="Clear & close the session"><DismissIcon onClick={() => { onToggleCoPilot() }} /></i>
            </div>
        </div>
    )
};
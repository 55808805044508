import { useNavigate } from 'react-router-dom';
import { fetchText, fetchObject } from 'Providers/Localization';
import { useGlobalContext } from 'Providers/ContextProvider';
import {
    Announcement,
    SolutionPlayDemo
} from 'Models';
import { pageRoutes } from 'Pages';
import { Resource } from '../models/assets-resources';
import { SurveyType } from 'Shared/enums';
import { CommonUtils } from 'Shared/Utils';
import { SurveyPath } from 'Shared/constants';
import { DeployPreConfiguredDemo } from '../models/deploy-preconfigured-demos';

const homePageContentNs = 'common';
const announcementContentNs = 'annoucements';
const assetsResourcesContentNs = 'assets-resources';

export const useHome = () => {
    const navigate = useNavigate();
    const { isContentLoading, isUserRolesLoading } = useGlobalContext();

    const readMoreClick = (announcementId: string) => {
        navigate({
            pathname: pageRoutes.announcements,
            hash: `#${announcementId}`
        });
    }

    const announcementsContent = {
        heading: fetchText('SectionHeading', announcementContentNs),
        announcements: fetchObject<Announcement[]>('Announcements', announcementContentNs)
    }

    const assetsResourcesContent = {
        heading: fetchText('SectionHeading', assetsResourcesContentNs),
        assetsResources: fetchObject<Resource[]>('AssetsResources', assetsResourcesContentNs)
    }


    const solutionPlayDemosContent = {
        heading: fetchText('HomePage.AvailableExploreDemoTemplates.Heading', homePageContentNs),
        exploreAllDemos: fetchText('HomePage.AvailableExploreDemoTemplates.ExploreAllDemos', homePageContentNs),
        solutionPlayDemos: fetchObject<SolutionPlayDemo[]>('HomePage.AvailableExploreDemoTemplates.ExploreDemoTemplates', homePageContentNs)
    }

    const deployPreConfiguredDemoContent = fetchObject<DeployPreConfiguredDemo[]>('HomePage.HowDoesItWork.Steps', homePageContentNs);    

    const isPortalLoading = () => {
        // render home page when both the content and roles loading finish
        return isContentLoading || isUserRolesLoading;
    }

    const isNsatSurvey = () => {
        return CommonUtils.getUrlTypeFromPath(SurveyPath) === SurveyType.NSAT;
    }   

    const onExploreCopilotAssetClick = () => {
        navigate(pageRoutes.copilotAssets);
    };

    return {
        isLoading: isPortalLoading(),
        announcementsContent,
        assetsResourcesContent,
        solutionPlayDemosContent,
        deployPreConfiguredDemoContent,
        onReadmoreClick: (announcementId: string) => { readMoreClick(announcementId) },
        isNsat: isNsatSurvey(),
        onExploreCopilotAssetClick
    };
};
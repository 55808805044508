import {
    Stack,
    IStackStyles,
    Link,
} from '@fluentui/react';
import { useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { MeControl } from 'Providers/Authentication';
import { ButtonIcon } from 'Shared/Controls';
import { FAQs } from 'Pages';
import { Nsat, FeatureFlagName } from 'Shared/constants';
import styles from './header.module.scss';
import { RoleManager } from 'Shared/helpers/role-manager';
import { useGlobalContext } from 'Providers/ContextProvider/GlobalContextProvider';

const strings = {
    siteTitle: 'Business Applications Demo Hub'
}

const stackStyles: IStackStyles = {
    root: {
        background: '#0c75c8'
    },
};

const onWaffleClick = () => {
    window.open('https://portal.office.com', '_blank');
}

export const Header = () => {
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const { roles, featureFlags } = useGlobalContext();
    const isDemoHubUser = RoleManager.isDemoHubUser(roles);
    const isDemoHubSeller = RoleManager.isDemoHubSeller(roles);
    const isCopilotFeatureEnabled = featureFlags?.includes(FeatureFlagName.Copilot);
    const [isChatWindowOpen, setChatWindow] = useState(false);

    /**
    * This function will toggle the new copilot experince visibility
    */
    const toggleCoPilot = () => {
        const isCopilotVisible = !isChatWindowOpen;
        setChatWindow(isCopilotVisible);
        const chatWindowHost = document.getElementById('demohubCopilotHost');
        const demohubHost = document.getElementById('demoHubHost');
        const mainContentHost = document.getElementById('demohubPortalHost');

        if (chatWindowHost && demohubHost && mainContentHost) {
            chatWindowHost.style.visibility = !isCopilotVisible ? 'hidden' : 'visible';
            chatWindowHost.style.display = !isCopilotVisible ? 'none' : 'flex';
            mainContentHost.style.width = '100%';
        }
    }

    return (
        <Stack enableScopedSelectors horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            styles={stackStyles}
            className={styles.header}>

            <div className={styles.menuContainer}>
                <ButtonIcon
                    id='btnWaffleOffice365'
                    iconName="WaffleOffice365"
                    ariaLabel="App Launcher"
                    onClick={onWaffleClick}
                    className={styles.iconContainer}
                    dataAttributes={Nsat.EventTriggerAttribute}
                />
                <Link href="/" title="Biz Apps Demo Hub Home" id='lnkDemohubHome'>{strings.siteTitle}</Link>
            </div>

            <div className={styles.menuContainer}>
                {isDemoHubUser &&
                    <div>
                        <ButtonIcon
                            id='btnTopNavHelp'
                            iconName="Help"
                            ariaLabel="Help"
                            onClick={openPanel}
                            className={styles.iconContainer}
                            dataAttributes={Nsat.EventTriggerAttribute}
                        />
                        <FAQs isOpen={isOpen} dismissPanel={dismissPanel} />
                    </div>
                }
                {/* TBD: Remove this temp condition (10 > 5) once UI is developed*/}
                {((isDemoHubSeller && isCopilotFeatureEnabled)) &&
                    <button id="demohub-copilot-topnav"
                        className={styles.copilotIcon} onClick={toggleCoPilot}>
                        <img
                            src="/images/chatcopilot/copilot-icon.png"
                            alt='Demo Hub copilot' />
                    </button>
                }
                <MeControl />
            </div>
        </Stack>
    )
}
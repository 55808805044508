export type InputOption = {
    name: string;
    id: string;
}

export type InputAppOption = {
    industry: string;
    apps: InputOption[];
}

export type InputEntityOption = {
    industry: string;
    app: string;
    entities: InputOption[];
}

export const dataGenIndustries: InputOption[] = [
    {
        name: 'Retail',
        id: 'Retail'
    },
    {
        name: 'Manufacturing',
        id: 'Manufacturing'
    },
    {
        name: 'Accounting',
        id: 'Accounting'
    },
    {
        name: 'Financial',
        id: 'Financial'
    },
    {
        name: 'Insurance',
        id: 'Insurance'
    }
]

export const dataGenApps: InputAppOption[] = [
    {
        industry: 'Retail',
        apps: [
            {
                name: 'Sales',
                id: 'Sales'
            },
            {
                name: 'Customer Service',
                id: 'CustomerService'
            },
            {
                name: 'Field Service',
                id: 'FieldService'
            }
        ]
    },
    {
        industry: 'Manufacturing',
        apps: [
            {
                name: 'Sales',
                id: 'Sales'
            },
            {
                name: 'Customer Service',
                id: 'CustomerService'
            },
            {
                name: 'Field Service',
                id: 'FieldService'
            }
        ]
    },
    {
        industry: 'Accounting',
        apps: [
            {
                name: 'Sales',
                id: 'Sales'
            },
            {
                name: 'Customer Service',
                id: 'CustomerService'
            },
            {
                name: 'Field Service',
                id: 'FieldService'
            }
        ]
    },
    {
        industry: 'Financial',
        apps: [
            {
                name: 'Sales',
                id: 'Sales'
            },
            {
                name: 'Customer Service',
                id: 'CustomerService'
            },
            {
                name: 'Field Service',
                id: 'FieldService'
            }
        ]
    },
    {
        industry: 'Insurance',
        apps: [
            {
                name: 'Sales',
                id: 'Sales'
            },
            {
                name: 'Customer Service',
                id: 'CustomerService'
            },
            {
                name: 'Field Service',
                id: 'FieldService'
            }
        ]
    }
]

export const dataGenEntities: InputEntityOption[] = [
    {
        industry: 'Retail',
        app: 'CustomerService',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Cases',
                id: 'Cases'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            },
            {
                name: 'Knowledge Articles',
                id: 'KnowledgeArticles'
            }
        ]
    },
    {
        industry: 'Retail',
        app: 'Sales',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Opportunities',
                id: 'Opportunities'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            }
        ]
    },
    {
        industry: 'Retail',
        app: 'FieldService',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Cases',
                id: 'Cases'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            }
        ]
    },
    {
        industry: 'Manufacturing',
        app: 'CustomerService',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Cases',
                id: 'Cases'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            },
            {
                name: 'Knowledge Articles',
                id: 'KnowledgeArticles'
            }
        ]
    },
    {
        industry: 'Manufacturing',
        app: 'Sales',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Opportunities',
                id: 'Opportunities'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            }
        ]
    },
    {
        industry: 'Manufacturing',
        app: 'FieldService',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Cases',
                id: 'Cases'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            }
        ]
    },
    {
        industry: 'Financial',
        app: 'CustomerService',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Cases',
                id: 'Cases'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            },
            {
                name: 'Knowledge Articles',
                id: 'KnowledgeArticles'
            }
        ]
    },
    {
        industry: 'Financial',
        app: 'Sales',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Opportunities',
                id: 'Opportunities'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            }
        ]
    },
    {
        industry: 'Financial',
        app: 'FieldService',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Cases',
                id: 'Cases'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            }
        ]
    },
    {
        industry: 'Accounting',
        app: 'CustomerService',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Cases',
                id: 'Cases'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            },
            {
                name: 'Knowledge Articles',
                id: 'KnowledgeArticles'
            }
        ]
    },
    {
        industry: 'Accounting',
        app: 'Sales',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Opportunities',
                id: 'Opportunities'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            }
        ]
    },
    {
        industry: 'Accounting',
        app: 'FieldService',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Cases',
                id: 'Cases'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            }
        ]
    },
    {
        industry: 'Insurance',
        app: 'CustomerService',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Cases',
                id: 'Cases'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            },
            {
                name: 'Knowledge Articles',
                id: 'KnowledgeArticles'
            }
        ]
    },
    {
        industry: 'Insurance',
        app: 'Sales',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Opportunities',
                id: 'Opportunities'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            }
        ]
    },
    {
        industry: 'Insurance',
        app: 'FieldService',
        entities: [
            {
                name: 'Accounts',
                id: 'Accounts'
            },
            {
                name: 'Cases',
                id: 'Cases'
            },
            {
                name: 'Contacts',
                id: 'Contacts'
            }
        ]
    },
]
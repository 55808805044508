import { HeroBanner } from "Shared/Components"
import styles from './demorequests.module.scss';
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { Pivot, PivotItem } from "@fluentui/react";
import { DemoType } from "Services/Enums";
import { fetchText } from "Providers/Localization";
import { useEffect, useState } from "react";

const DEMOS_SUBNAV_PREFIX: string = 'DemosSubNav';

export const DemoRequests = () => {

    interface DemoListNavigationHeaderModel {
        HeaderName : string;
        HeaderLink : string;
        IsActive : boolean;
    }

    const navigate = useNavigate();
    const { demotype } = useParams<{ demotype: string }>();
    const [selectedTab, setSelectedTab] = useState<string>(DemoType[DemoType.mydemos]); 
    const DemoListNavigationHeader : DemoListNavigationHeaderModel[] = [
        {
            HeaderName : fetchText(`${DEMOS_SUBNAV_PREFIX}.MyDemos`),
            HeaderLink : DemoType[DemoType.mydemos],
            IsActive : true
        },
        {
            HeaderName : fetchText(`${DEMOS_SUBNAV_PREFIX}.SharedDemos`),
            HeaderLink : DemoType[DemoType.shareddemos],
            IsActive : process.env.REACT_APP_ACTIVATE_SHAREDDEMOS?.toLowerCase() === 'true' ? true : false
        },
        {
            HeaderName: fetchText(`${DEMOS_SUBNAV_PREFIX}.Clickthroughs`),
            HeaderLink: DemoType[DemoType.clickthroughs],
            IsActive: isNavElementActive(process.env.REACT_APP_ACTIVATE_CLICKTHROUGHS)
        }
    ]

    function linkClickHandler(event: any): void {
        navigate(event.props.itemKey);
    }

    function isNavElementActive(element: string | undefined): boolean {
        return element?.toLowerCase() === 'true' ? true : false
    }

    useEffect(() => {
        setSelectedTab(demotype ? demotype : DemoType[DemoType.mydemos]);
    }, [demotype])

    return (
        <div className={styles.demoRequests}>
            <HeroBanner />
            <Pivot selectedKey={selectedTab} aria-label={fetchText(`${DEMOS_SUBNAV_PREFIX}.AriaLabel`)} linkSize="normal" onLinkClick={(event: any) => linkClickHandler(event)}>
                <hr />
                {DemoListNavigationHeader && DemoListNavigationHeader.map((header: DemoListNavigationHeaderModel) => {
                    return ( header.IsActive &&
                        <PivotItem headerText={header.HeaderName} key={header.HeaderLink} itemKey={header.HeaderLink}>
                        <Outlet />
                    </PivotItem>
                    )       
                })}
            </Pivot>
        </div>
    )
}
import { useState } from 'react';
import { IDropdownOption, IIconProps } from '@fluentui/react';
import { CopilotAsset } from 'Models';
import { fetchText, fetchObject } from 'Providers/Localization';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { logUnhandledExceptionAndSetMetrics} from 'Shared/telemetry/telemetryHelper';
import { operationName } from "Shared/constants";
import { ExtendedMap, IDimension } from "Telemetry";
import { Dimensions, ErrorCode, Metrics } from "../telemetry";
import { AssetTypeOption } from '../components/assettype-option/AssetTypeOption';


const contentFileName = 'copilotasset';
const pageSize = 9; // TO-DO move int to a settings or config file
const defaultImageUrl = '/images/copilot-assets/copilot-default.png'

export const useCopilotAssets = () => {
    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const [titleSearchInput, setTitleSearchInput] = useState<string>('');
    const [selectedAssetTypes, setSelectedAssetTypes] = useState<string[]>([]);
    const [productTypeSearchInput, setProductTypeSearchInput] = useState<string>('');
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const [dynamicProductTypeOptions, setDynamicProductTypeOptions] = useState<IDropdownOption[]>([]);
    const [assetTypeOptions, setAssetTypeOptions] = useState<AssetTypeOption[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState('');

    // get the content from the json file
    const copilotAssetPageContent = {
        heading: fetchText('Heading', contentFileName),
        headingDescription: fetchText('HeadingDescription', contentFileName),
        copilotAssets: fetchObject<CopilotAsset[]>('CopilotAssets', contentFileName)
    }
    const totalAssets = copilotAssetPageContent?.copilotAssets?.length || 0;

    let telemetryProps: TelemetryProperties = {
        operationName: operationName.CopilotAssetsContentLoader,
        exceptionType: ExceptionType.CopilotAssetsContentLoadException,
        defaultErrorCode: ErrorCode.FailedToLoadCopilotAssetsContent
    }
    if (totalAssets === 0) {
        let dimensions = new ExtendedMap<IDimension, string>([
            [Dimensions.ContentFileName, contentFileName],
        ]);
        const error = new Error('Failed to load Assets');
        logUnhandledExceptionAndSetMetrics(
            error,
            {
                ...telemetryProps,
                message: telemetryProps.exceptionType,
                defaultErrorCode: ErrorCode.FailedToLoadCopilotAssetsContent
            },
            Metrics.CopilotAssetsFetchAPICounter,
            dimensions
        );

    }
    const iconName: IIconProps = { iconName: 'Share' };

    const getPrimaryButtonText = (assetType?: string) => {
        switch (assetType) {
            case 'Video':
                return fetchText('CopilotAssetCTATitle.Video');
            case 'Script':
                return fetchText('CopilotAssetCTATitle.Script');
            case 'Clickthrough':
                return fetchText('CopilotAssetCTATitle.Clickthrough');
            default:
                return fetchText('CopilotAssetCTATitle.Default');
        }
    };

    const onPrimaryButtonClick = (asset: CopilotAsset) => {
        switch (asset.AssetType) {
            case 'Video':
                setSelectedVideoUrl(asset.LinkToAsset || '');
                setIsVideoDialogOpen(true);
                break;
            default:
                // Open the link to the asset in a new tab
                window.open(asset.LinkToAsset, '_blank');
                break;
        }
    };

    const handleVideoDialogClose = () => {
        setIsVideoDialogOpen(false); // Close the video dialog
        setSelectedVideoUrl(''); // Clear the selected video URL
    };

    // Handler for updating the title search input value
    const onTitleSearchInputChange = (event: any): void => {
        setTitleSearchInput(event.target.value);
    };

    // Handler for clearing the title search input value
    const clearTitleSearchInputChange = () => {
        setTitleSearchInput('');
    };

    // Handler for clearing the asset type 
    const clearSelectedAssetTypes = () => {
        setSelectedAssetTypes([]);
    };

    // Handler for clearing the product type search
    const clearProductTypeSearchInput = () => {
        setProductTypeSearchInput('');
    };

    // Handler for clearing the product type search
    const clearSelectedFilters = () => {
        setSelectedFilters([]);
    };

    const onProductTypeSearchInputClear = () => {
        clearSelectedFilters();
        clearProductTypeSearchInput();
    };

    // Handler for clearing all filter selections
    const clearAllFilters = () => {
        clearSelectedFilters();
        clearSelectedAssetTypes();
        clearProductTypeSearchInput();
        clearTitleSearchInputChange();
    };

    // Handler for selecting/deselecting asset type options
    const onAssetTypeChange = (event: any): void => {
        const { value } = event.target;
        const assetTypeKey = value as string;

        const isSelected = selectedAssetTypes.includes(assetTypeKey);

        let newSelectedAssetTypes: string[];

        if (isSelected) {
            newSelectedAssetTypes = selectedAssetTypes.filter(assetType => assetType !== assetTypeKey);
        } else {
            newSelectedAssetTypes = [...selectedAssetTypes, assetTypeKey];
        }
        setSelectedAssetTypes(newSelectedAssetTypes);
    };

    // Handler for updating the search input value
    const onProductTypeSearchInputChange = (event: any): void => {
        setProductTypeSearchInput(event.target.value);
    };


    // Handler for selecting/deselecting filter options
    const onFilterChange = (event: any): void => {
        const { value } = event.target;
        const filterKey = String(value); // Ensure filterKey is always treated as string

        const isSelected = selectedFilters.includes(filterKey);

        let newSelectedFilters: string[];

        if (isSelected) {
            newSelectedFilters = selectedFilters.filter(filter => filter !== filterKey);
        } else {
            newSelectedFilters = [...selectedFilters, filterKey];
        }
        setSelectedFilters(newSelectedFilters);
    };

    const filteredProductTypeOptions: AssetTypeOption[] = dynamicProductTypeOptions
        .filter(option =>
            option.text.toLowerCase().includes(productTypeSearchInput.toLowerCase())
        )
        .map(option => ({
            key: option.key.toString(),
            text: option.text.toString()
        }));

    const handleSortChange = (selectedSort: string) => {
        setSortBy(selectedSort);
    };

    return {
        copilotAssetPageContent,
        pageSize,
        totalAssets,
        iconName,
        getPrimaryButtonText,
        onPrimaryButtonClick,
        defaultImageUrl,
        selectedVideoUrl,
        isVideoDialogOpen,
        handleVideoDialogClose,
        onTitleSearchInputChange,
        titleSearchInput,
        clearTitleSearchInputChange,
        selectedAssetTypes,
        onAssetTypeChange,
        onProductTypeSearchInputChange,
        productTypeSearchInput,
        onProductTypeSearchInputClear,
        selectedFilters,
        setSelectedFilters,
        setDynamicProductTypeOptions,
        onFilterChange,
        clearAllFilters,
        assetTypeOptions,
        setAssetTypeOptions,
        filteredProductTypeOptions,
        currentPage,
        setCurrentPage,
        sortBy,
        handleSortChange
    }
};
import { fetchText } from 'Providers/Localization';
import { BaseProps } from 'Shared/Components';
import { AnnouncementCard } from 'Shared/Components';
import { MessageBox } from 'Shared/Controls';
import { CommonUtils, DateUtils } from 'Shared/Utils';
import { Announcement } from 'Models';

interface AnnouncementsListProps extends BaseProps {
    heading: string;
    announcements: Announcement[] | null;
    onReadmoreClick: (announcementId: string) => void;
}

// On home page, we are showing only 4 announcements
// 3 announcements + 1 Upcoming milestone
const AnnouncementsDisplayCount = 4;
export const AnnouncementsList = (props: AnnouncementsListProps) => {
    const { heading, announcements, onReadmoreClick } = props;

    const Announcements = () => {
        if (!announcements || !announcements?.length) {
            return (
                <MessageBox
                    message={fetchText('NotFoundMessages.NoAnnouncementsFoundMsg')}
                    type="info"
                />
            )
        }

        return (
            <>
                {announcements?.slice(0, AnnouncementsDisplayCount).map((announcement: Announcement, index: number) => {
                    return (
                        <AnnouncementCard
                            key={`announcement-${index}`}
                            id={`announcement-${index}`}
                            title={announcement.Title}
                            description={announcement.ShortDescription}
                            imageUrl={announcement.ImageUrl}
                            primaryLinkLabel={DateUtils.formatUtcDate(announcement.ModifiedOn)}
                            primaryLinkId={`announcement-link-${index}`}
                            primaryLinkText={fetchText('Links.Readmore')}
                            onPrimaryLinkClick={() => onReadmoreClick(CommonUtils.formatStringInSlugFormat(announcement.Title))}
                        />
                    )
                })}
            </>
        )
    }

    return (
        <div className={props.className}>
            <h2>
                {heading}
            </h2>
            <Announcements />
        </div>
    )
}
import {
    getCLS,
    getFID,
    getFCP,
    getLCP,
    getTTFB,
    ReportHandler
} from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler, webVitals =
    { getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        Object.values(webVitals).forEach((metric) => {
            metric(onPerfEntry);
        });
    }
};

export default reportWebVitals;
import i18n, { InitOptions } from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import { telemetryLogger } from 'Providers/Telemetry';
import { environmentNames } from 'Shared/constants';
import { fileNamespaces } from './setupContentFiles';
import { ApiContentProvider } from './content-loader';

// require numeral locales so that they get picked up by webpack
// eslint-disable-next-line @typescript-eslint/no-require-imports, import/no-unassigned-import
export const missingKeyFn = (key: string, defaultValue?: string) => defaultValue ?? null;
export const missingFn = (_: string, match: string[]) => `<<INTERPOLATION_ERROR key '${match[1]}'>>`;
export const DEFAULT_NS = 'common';
export const exists = (key: string) => i18n.exists(key);
const locales = ['en-US'];

export const LOC_QUERY_STRING_PARAM = 'mkt';
export const LOC_LOCAL_STORAGE_KEY = 'bizappsdemos/Market';

export const initializeLocalization = async () => {
    // Learn more about i18n configuration options:https://www.i18next.com/overview/configuration-options
    const options: InitOptions = {
        load: 'currentOnly',
        ns: fileNamespaces,
        defaultNS: DEFAULT_NS,
        supportedLngs: locales,
        fallbackLng: locales[0],
        detection: {
            /**
             * detection strategy:
             * - only attempt to load from browser (navigator) on first load
             * - otherwise always prefer value in localStorage, which will be set on first load by i18n or by user through language selection
             * - allow querystring override for testing
             */
            order: ['querystring', 'localStorage', 'navigator'],
            lookupQuerystring: LOC_QUERY_STRING_PARAM,
            lookupLocalStorage: LOC_LOCAL_STORAGE_KEY
        },

        interpolation: {
            escapeValue: false // not necessary for react due to react auto-escaping (unless you use dangerouslySetInnerHTML)
        },

        // will log missing keys to console in local development
        debug: process.env.REACT_APP_APP_ENV === environmentNames.dev,

        parseMissingKeyHandler: missingKeyFn,
        missingInterpolationHandler: missingFn,

        // For configururing multiple back-ends, we need to setup chained back-end with i18next
        // Caching reference links: https://www.i18next.com/how-to/caching
        // Chained backend Reference links: https://github.com/i18next/i18next-chained-backend
        // LocalStorageBackend Reference links: https://github.com/i18next/i18next-localstorage-backend
        backend: {
            backends: [
                HttpApi,  // primary backend - content api endpoint
                HttpApi  //  fallback json files stored in web project locally
            ],
            backendOptions: [
                {
                    loadPath: `{{lng}}/{{ns}}.json`,
                    request: async (options: any, url: any, payload: any, callback: any) => {
                        const { getContent } = ApiContentProvider();
                        await getContent(url, callback);
                    }
                },
                {
                    // if content api fails, then the system will fallback to this json and load the content from here
                    loadPath: '/fallback-resources/en-US/common.json'
                }
            ]
        },

        // do not asynchronously init; allow consumers to wait on initialize promise
        initImmediate: false,

        // return object or array
        returnObjects: true
    };

    return i18n
        .use(I18nextBrowserLanguageDetector)
        .use(ChainedBackend)
        .use(initReactI18next)
        .init(options)
        .then(() => {
            // add lang attr to html for accessibility
            document.getElementsByTagName('html')[0].setAttribute('lang', i18n.language);
        }).catch((error) => {
            telemetryLogger.trackException('An error while initializing localization provider', error);
        });
};

export const getLanguageCode = () => i18n.language.split('-')[0];
export const getIsRtl = () => i18n.dir(getLanguageCode()) === 'rtl';
export const addString = (key: string, value: string) => {
    i18n.addResource(i18n.language, DEFAULT_NS, key, value);
};
export const addStrings = (values: Record<string, string>) => {
    i18n.addResources(i18n.language, DEFAULT_NS, values);
};

export default i18n;
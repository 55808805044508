import { OutputCard } from '@fluentai/react-copilot';
import {
    AiGeneratedDisclaimer,
    CopilotMessageFooter,
} from "@fluentai/react-copilot-chat";
import { FeedbackButtons } from "@fluentai/feedback-buttons";
import { ChatActionResponseType, CopilotRequest, ChatResponse, ChatResponseAction } from 'DemoHubCopilot/models/CopilotRequest';
import { DataGenerationInputForm } from '../data-generation-form/DataGenerationInputForm';
import { DataTable } from '../data-preview/DataTable';
import { useCopilotResponse } from './hooks/useCopilotResponse';

import styles from './answer.module.scss';

export interface CopilotResponseProps extends ChatResponse {
    onPromptClick: (copilotRequest: CopilotRequest) => void;
    disableForm: boolean;
}

export const CopilotResponse = (props: CopilotResponseProps) => {
    const {
        selectedFeedback,
        handleNegativeFeedback,
        handlePositiveFeedback } = useCopilotResponse();

    const getResponseActionType = (action: ChatResponseAction) => {
        if (action.type === ChatActionResponseType.DatagenForm) {
            return <DataGenerationInputForm
                key="data-gen-new-form"
                onPromptClick={props.onPromptClick} />
        }
        if (action.type === ChatActionResponseType.ExportToExcel) {
            return <DataTable
                key="data-preview-and-export"
                dataToExport={action.actionData} />
        }
        if (action.type === ChatActionResponseType.DatagenFormCustomPromptView) {
            return <DataGenerationInputForm
                key="data-gen-nlp-form"
                onPromptClick={props.onPromptClick}
                showNlpInputForm={true}
                disableForm={props.disableForm}
                dataGenerationProvidedInput={action.actionData} />
        }
    }

    return (
        <div className={`${styles.answerCard} ${styles.agent}`}>
            <div>
                <img
                    height="24"
                    src="/images/chatcopilot/copilot-icon.png"
                    alt='Demo Hub copilot' />
            </div>
            <OutputCard>
                <div className={styles.answerContainer}>
                    {props.content}

                    {props.responseActions && props.responseActions.length > 0 && props.responseActions.map((action: ChatResponseAction, index) => {
                        return getResponseActionType(action);
                    })}
                </div>
                <CopilotMessageFooter
                    className={styles.messageFooter}
                    feedback={<FeedbackButtons
                        selected={selectedFeedback}
                        positiveFeedbackButton={{ onClick: handlePositiveFeedback, id: "btn-upvote" }}
                        negativeFeedbackButton={{ onClick: handleNegativeFeedback, id: "btn-downvote" }}
                        positiveFeedbackTooltip={{
                            content: "Helpful",
                        }}
                        negativeFeedbackTooltip={{
                            content: "Not Helpful",
                        }} />}
                    aiDisclaimer={
                        <AiGeneratedDisclaimer>
                            AI-generated content may be incorrect
                        </AiGeneratedDisclaimer>
                    }>
                </CopilotMessageFooter>
            </OutputCard >
        </div >
    )
}
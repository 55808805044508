import { metricsLogger, telemetryLogger } from "Providers/Telemetry";
import { SuccessCodes } from "./constants";

export interface ArgumentKeyValuePair {
    argumentName: string;
    argumentValue: string;
}

/**
 * Checks for the null input arguments. Logs the null argument and breaks the loop.
 * @param argumentsList
 * @returns isNullArgument
 */
export const CheckArgumentNullException = (argumentsList: ArgumentKeyValuePair[]) => {
    let isNullArgument = false;

    let nullOrUndefinedArguments: string[] = [];
    argumentsList.every(element => {
        if (Object.is(element.argumentValue, null) || Object.is(element.argumentValue, undefined) || element.argumentValue.length === 0) {
            nullOrUndefinedArguments.push(element.argumentName);
            telemetryLogger.trackException(`ErrorHandler.CheckArgumentNullException. Argument Null Error: ${nullOrUndefinedArguments} cannot be null or empty`);
            isNullArgument = true;
            return false; // acts as break statement
        }
        return true; // acts as continue statement
    });
    return isNullArgument;
}

/**
 * Checks for the APi response received and emits metrices.
 * @param api
 * @param responseCode
 */
export const CheckAPIResponseCode = (api: string, responseCode?: number) => {
    if (SuccessCodes.includes(responseCode?.toString() || "")) {
        // success response code
        metricsLogger.emitApiSuccessMetric({ Api: api, ApiResponseCode: responseCode });
    }
    else {
        // failure response code
        metricsLogger.emitApiFailureMetric({ Api: api, ApiResponseCode: responseCode });
    }
}
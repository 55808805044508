import { useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { AuthProvider } from 'Providers/Authentication';
import { fetchText } from 'Providers/Localization';
import {
    logErrorAndSetApiFailureMetrics,
    logUnhandledExceptionAndSetMetrics
} from 'Shared/telemetry/telemetryHelper';
import { operationName, ValidationRegex } from 'Shared/constants';
import { links } from 'Shared/links';
import { useForm, IFormModel } from 'Shared/hooks/useForm';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { telemetryMessages } from 'Services/base/telemetryMessages';
import {
    DemoRequestSvc,
    RefreshDemoRequest
} from 'Services';
import {
    ErrorCode,
    Metrics
} from '../telemetry';

interface TenantAdminForm {
    tenantAdminName: string;
}

export enum RefreshState {
    NotStarted,
    Completed,
    Failed
}

interface DemoRefreshResult {
    refreshState: RefreshState;
    noOfDeactivatedDemos?: number
}

const formModel: IFormModel = {
    props: (): TenantAdminForm => ({
        tenantAdminName: ''
    })
}

export const useRefreshDemo = (SuccessfullRefreshCallback: () => void) => {
    const {
        values,
        errors,
        setErrors,
        handleInputChange,
        setValues
    } = useForm(formModel);
    const [refreshingDemos, setRefreshingDemos] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [demosRefreshResult, setdemosRefreshResult] = useState<DemoRefreshResult>({ refreshState: RefreshState.NotStarted }); // notstarted default value i.e. request hasn't started processing yet 
    const [showHideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const getTextFor = (keyName: string) => {
        return fetchText(`AllDemos.RefreshDemos.${keyName}`);
    }

    const getErrorMessage = () => fetchText(`AllDemos.RefreshDemos.RefreshError`)    // replace placeholder value with the icm creation link;
    const getSuccessMessage = () => {
        return demosRefreshResult?.noOfDeactivatedDemos && demosRefreshResult.noOfDeactivatedDemos > 0 ? fetchText(`AllDemos.RefreshDemos.RefreshCompletedMessageForDemosRemoval`)?.replace('{0}', demosRefreshResult.noOfDeactivatedDemos.toString()) :
            fetchText(`AllDemos.RefreshDemos.RefreshCompletdMessageForNoDemoRemoval`);
    }// replace placeholder value with the no of demos removed

    const refreshDemosClick = async () => {
        setRefreshingDemos(true);
        const switchToArgs = {
            nextAccount: {
                memberName: values.tenantAdminName
            }
        }
        await AuthProvider.switchTo(switchToArgs,
            onTenantAdminSignedInSuccess,
            onTenantAdminSignedInFailure);
    }

    const showHideDialogClick = () => {
        // close dialog
        toggleHideDialog();
        // Reset form value
        setValues(formModel.props());
        setErrors('');
        setRefreshingDemos(false);
        setdemosRefreshResult({ refreshState: RefreshState.NotStarted });
        setErrorMessage('');
    }

    const onTenantAdminSignedInSuccess = async (msalResponse: any) => {
        try {

            let refreshDemoRequest: RefreshDemoRequest = {
                DemoAdminAccount: msalResponse.account,
                SellerUserName: AuthProvider.getActiveAccount()?.username
            };

            var refreshDemoOrgsApiResponse = await DemoRequestSvc.refreshDemoOrgsWithPpac(refreshDemoRequest);
            if (!refreshDemoOrgsApiResponse.hasError) {
                setdemosRefreshResult({ refreshState: RefreshState.Completed, noOfDeactivatedDemos: refreshDemoOrgsApiResponse.data });
                // if demos gets deactivated, refresh the my demos screen demos
                if (refreshDemoOrgsApiResponse?.data > 0) {
                    SuccessfullRefreshCallback();
                }
            }
            else {
                setdemosRefreshResult({ refreshState: RefreshState.Failed, noOfDeactivatedDemos: 0 });
                setErrorMessage(getErrorMessage());

                let refreshDemoOrgsTelemetry: TelemetryProperties = {
                    operationName: operationName.RefreshDemoOrgs,
                    exceptionType: ExceptionType.RefreshDemoOrgsException,
                    defaultErrorCode: ErrorCode.FailedToRefreshDemoOrgs
                }

                // Log trace and Set metrics
                logErrorAndSetApiFailureMetrics(refreshDemoOrgsApiResponse,
                    {
                        ...refreshDemoOrgsTelemetry,
                        message: telemetryMessages.refreshDemoOrgsError
                    },
                    Metrics.RefreshDemoOrgsAPIFailure);
            }
            setRefreshingDemos(false);

        } catch (error) {
            setErrorMessage(getErrorMessage());
            setRefreshingDemos(false);
            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    operationName: operationName.RefreshDemoOrgs,
                    message: telemetryMessages.refreshDemoOrgsError,
                    defaultErrorCode: ErrorCode.FailedToRefreshDemoOrgs
                },
                Metrics.RefreshDemoOrgsAPIFailure);
        }
    }

    const onTenantAdminSignedInFailure = async (msalResponse: any) => {
        // If user closes the tenant admin signin pop-up then terminate the refresh operation by closing the refresh dialog
        showHideDialogClick();
    }

    const validateTenantAdmin = (e?: Event) => {
        let validationChecks: TenantAdminForm = {
            ...errors
        }
        let validationMessage = validateForm();
        validationChecks.tenantAdminName = validationMessage;
        setErrors(validationChecks);
    }

    const validateForm = (isTenantAdminSignFailure?: boolean) => {
        if (isTenantAdminSignFailure) {
            return getTextFor('TenantAdminSigninError');
        }

        if (!values.tenantAdminName || !values.tenantAdminName.trim().length) {
            return fetchText('ValidationMessages.Required');
        }
        if (!(values.tenantAdminName.match(ValidationRegex.ValidEmail))) {
            return fetchText('ValidationMessages.InvalidEmail');
        }

        return '';
    }

    const isFormSubmissionAllowed = () => {
        return ((Object.values(values).some(x => !x) || Object.values(errors).some(x => x !== '')) ||
            errorMessage?.length > 0 || refreshingDemos || demosRefreshResult.refreshState !== RefreshState.NotStarted);
    }

    return {
        onSaveClick: () => { refreshDemosClick() },
        formValues: values,
        onInputChange: handleInputChange,
        formErrors: errors,
        refreshingDemos,
        demosRefreshResultState: demosRefreshResult.refreshState,
        showHideDialog,
        showHideDialogClick,
        onTenantAdminValidation: validateTenantAdmin,
        formSubmissionNotAllowed: isFormSubmissionAllowed(),
        getTextFor,
        errorMessage,
        successMessage: getSuccessMessage()
    };
};
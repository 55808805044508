import { Template, TemplateResponse } from 'Models';

export const mapApiResponse = (apiResponse: any): TemplateResponse => {
    let data: TemplateResponse = {
        templates: [],
        tenantsList: []
    }
    if (apiResponse && apiResponse.templates.length) {
        apiResponse.templates.forEach((item: any) => {
            let newItem: Template = {
                id: item.templateId,
                iconUrl: item.iconUrl,
                name: item.templateName,
                category: item.category,
                isActive: item.isActive,
                allowedDemoTenants: item.allowedDemoTenants,
                demoType: item.demoType,
                environmentNameRegex: new RegExp(item.environmentNameRegularExpression),
            };
            data.templates.push(newItem);
        })
        data.tenantsList = apiResponse.tenantsList;
    }

    return data;
}
import { useEffect, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
    SpinnerSize
} from '@fluentui/react';
import {
    Loader
} from 'Shared/Controls';
import { CommonUtils, DomElementEventListeners } from 'Shared/Utils';
import { DemoType } from 'Services/Enums';
import { useClickthroughRequests } from 'Pages/DemoRequests/hooks/useClickthroughRequests';
import { messages, pageSize, requestFilters } from 'Pages/DemoRequests/demorequests.settings';
import { ClickthroughRequestList } from './ClickthroughRequestList';

import styles from '../demorequests.module.scss';

export const ClickthroughRequests = () => {
    const { demotype } = useParams<{ demotype: string }>();
    requestFilters.DemoType = demotype ? DemoType[demotype as keyof typeof DemoType] : DemoType.clickthroughs;

    const {
        currentPage,
        setCurrentPage,
        Loading,
        Clickthroughs,
        openClickthrough,
        unPinClickthroughRequest,
        unPinRequestLoader
    } = useClickthroughRequests(requestFilters);

    const clickthroughData = useMemo(() => {
        if (currentPage > 0) {
            const firstPageIndex = (currentPage > 0 ? currentPage - 1 : 0) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
            return Clickthroughs.slice(firstPageIndex, lastPageIndex);
        }
    }, [currentPage, Clickthroughs]);

    useEffect(() => {
        if (!Loading) {
            DomElementEventListeners.addNsatEventListeners('#demos-list-container');
        }
    }, [Loading, currentPage])

    return (
        <div className={styles.demoRequests}>
            {unPinRequestLoader &&
                <Loader Message={messages.loaderUnPinClickthroughRequestSubmission}
                    IsOverlay={true} Size={SpinnerSize.large} />
            }
            {!Loading ? <ClickthroughRequestList
                totalCount={ClickthroughRequests.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                clickthroughData={clickthroughData}
                openClickthrough={openClickthrough}
                unPinClickthroughRequest={unPinClickthroughRequest}
            />
                : <Loader
                    Message={messages.loadingClickthroughs}
                    Size={SpinnerSize.large}
                />
            }
        </div>
    )
}
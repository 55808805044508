import * as React from 'react';
import { Dialog, DialogFooter, DialogType, DefaultButton } from '@fluentui/react';

interface VideoDialogProps {
    isOpen: boolean;
    onClose: () => void;
    videoUrl: string | '';
}

export const VideoDialog = (props: VideoDialogProps) => {
    const [hideDialog, setHideDialog] = React.useState(true);

    React.useEffect(() => {
        setHideDialog(!props.isOpen);
    }, [props.isOpen]);

    const handleClose = () => {
        setHideDialog(true);
        props.onClose();
    };

    return (
        <Dialog 
            hidden={hideDialog}
            onDismiss={handleClose}
            //maxWidth={900}
            dialogContentProps={{
                type: DialogType.normal,
                title: ''
            }}
            modalProps={{
                isBlocking: false,
                //styles: { main: { maxWidth: 750, width: 650 } },
                className: 'copilot-video-dialog'
            }}
        >
            <div>
                <iframe
                    width="100%"
                    height="315"
                    src={props.videoUrl}
                    title="Video"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                ></iframe>
            </div>
            <DialogFooter>
                <DefaultButton onClick={handleClose} text="Close" />
            </DialogFooter>
            </Dialog>
    );
};
import { telemetryLogger } from "Providers/Telemetry";
import { ApiResult } from "../../Services/base/models";
import { RequestStatus } from "../../Services/base/enums/RequestStatus";
import { ErrorMessage } from "Shared/constants";
import { Dimensions, ExtendedMap, IDimension, metricService } from "Telemetry";
import { TelemetryProperties } from "Shared/interfaces/TelemetryProperties";
import { IMetric } from "Telemetry/IMetric";
import { ErrorCode } from "Services/base/errorCodes";
import { OperationStatus } from "Shared/enums";
import { UNKNOWN_STATUS } from "Services/base/constants";

/**
 * This methods logs the telemetry and sets the metrics for the API success scenario.
 * It takes cares of setting generic dimensions for an API.
 * @param apiResponse API response
 * @param telemetryProperties telemetry properties
 * @param metric Metric name
 * @param dimensions Metric dimensions
 * @returns 
 */
export const logAndSetApiSuccessMetrics = <T>(
    apiResponse: ApiResult<T>,
    telemetryProperties: TelemetryProperties,
    metric: IMetric,
    dimensions?: ExtendedMap<IDimension, string>,
) => {
    try {
        dimensions = dimensions ?? new ExtendedMap<IDimension, string>();
        const { operationName, message } = telemetryProperties

        dimensions.addDimension(Dimensions.ApiName, operationName);
        dimensions.addDimension(Dimensions.ApiStatus, apiResponse?.status?.toString() || UNKNOWN_STATUS);
        dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.SUCCESS);
        dimensions.addDimension(Dimensions.ErrorCode, ErrorCode.NoError);

        // Log message
        telemetryLogger.trackTrace(
            message ?? `${operationName} is completed successfully.`,
            {
                operationName: operationName,
                statusCode: apiResponse.status,
            });

        // Set metrics
        metricService.set(metric, dimensions);
        return;
    } catch (error) {
        telemetryLogger.trackException("Exception occurred while logging telemetry and setting metrics for API success scenario.", error)
    }
}

/**
 * This method logs the exception telemetry and sets the metrics for the API failure scenario
 * It takes cares of setting generic dimensions for an API.
 * @param apiResponse API response
 * @param telemetryProperties Telemetry properties
 * @param metric Metric name
 * @param dimensions Metric dimensions
 * @returns 
 */
export const logErrorAndSetApiFailureMetrics = <T>(
    apiResponse: ApiResult<T>,
    telemetryProperties: TelemetryProperties,
    metric: IMetric,
    dimensions?: ExtendedMap<IDimension, string>,
) => {

    try {
        dimensions = dimensions ?? new ExtendedMap<IDimension, string>();
        const { operationName, defaultErrorCode } = telemetryProperties

        dimensions.addDimension(Dimensions.ApiName, operationName);
        dimensions.addDimension(Dimensions.ApiStatus, apiResponse?.status?.toString() || UNKNOWN_STATUS);

        // If error code is already set by caller method that will be given preference
        if (!dimensions.get(Dimensions.ErrorCode)) {
            dimensions.addDimension(Dimensions.ErrorCode, getErrorCode(apiResponse, defaultErrorCode));
        }

        dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.FAILURE);

        // Validate and Log Error messages
        validateAndLogError(apiResponse, telemetryProperties);

        // Set metrics
        metricService.set(metric, dimensions);
        return;
    } catch (error) {
        telemetryLogger.trackException("Exception occurred while logging telemetry and setting metrics for API failure scenario.", error)
    }
}

/**
 * This method logs the unhandled exception telemetry and sets the metrics for the Unhandled exception scenario
 * @param error Error object
 * @param telemetryProperties Telemetry properties
 * @param metric Metric name
 * @param dimensions Metric dimensions
 * @returns 
 */
export const logUnhandledExceptionAndSetMetrics = (
    error: any,
    telemetryProperties: TelemetryProperties,
    metric: IMetric,
    dimensions?: ExtendedMap<IDimension, string>
) => {
    try {
        const { operationName, message: exceptionMessage, defaultErrorCode } = telemetryProperties;

        dimensions = dimensions ?? new ExtendedMap<IDimension, string>();

        // Add Common Dimensions
        dimensions.addDimension(Dimensions.ApiName, operationName);
        dimensions.addDimension(Dimensions.ApiStatus, error?.status || UNKNOWN_STATUS);
        dimensions.addDimension(Dimensions.ErrorCode, getErrorCode(error, defaultErrorCode));
        dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.FAILURE);

        telemetryLogger.trackException(
            `${exceptionMessage}.Error Details: ${error}`,
            {
                operationName: operationName,
                error: error,
                exceptionType: telemetryProperties.exceptionType,
                statusCode: error?.status,
                detailedError: error.stack
            }
        );

        metricService.set(metric, dimensions);
    } catch (error) {
        telemetryLogger.trackException("Exception occurred while logging telemetry and setting metrics for unhandled exception scenario.", error)
    }
}

/**
* This is generic method to log telemetry and set metrics. 
* This method can be used for both success and failure scenarios and most of the custom dimensions is set at caller level.
* @param apiResponse API response
* @param telemetryProperties telemetry properties
* @param metric Metric name
* @param dimensions Metric dimensions
* @returns 
*/
export const logAndSetMetrics = (
    telemetryProperties: TelemetryProperties,
    metric: IMetric,
    dimensions?: ExtendedMap<IDimension, string>,
) => {

    dimensions = dimensions ?? new ExtendedMap<IDimension, string>();
    const { operationName, message } = telemetryProperties

    dimensions.addDimension(Dimensions.ApiName, operationName);

    // Log message
    telemetryLogger.trackEvent(`${message}`);

    // Set metrics
    metricService.set(metric, dimensions);
    return;
}

export const getErrorCode = (
    response: any,
    defaultErrorCode: string): string => {

    return response?.data?.errorCode || response?.code || defaultErrorCode;
}

const isValidError = (apiResponse: ApiResult<any>) => {
    return apiResponse?.hasError
        && apiResponse?.data?.errorMessage !== ErrorMessage.accessTokenError;
}

const isUnAuthorizedError = (apiResponse: ApiResult<any>) => {
    return apiResponse?.hasError
        && (apiResponse?.status === RequestStatus.UNAUTHORIZED
            || apiResponse?.status === RequestStatus.FORBIDDEN);
}

const validateAndLogError = <T>(
    apiResponse: ApiResult<T>,
    telemetryProperties: TelemetryProperties,
) => {
    const { operationName, message: exceptionMessage, exceptionType } = telemetryProperties;

    if (isUnAuthorizedError(apiResponse)) {
        telemetryLogger.trackWarning(
            `User is unauthorized to perform ${operationName} operation. Error Code: ${apiResponse?.status}. Error Message:  ${apiResponse?.data}`,
            {
                operationName: operationName,
                error: apiResponse.data,
            });
    }
    else {
        telemetryLogger.trackException(exceptionMessage ?? `Exception occurred in ${operationName} API. Error Code: ${apiResponse?.status}. Error Message:  ${apiResponse?.data}`,
            {
                operationName: operationName,
                error: apiResponse.data,
                exceptionType: exceptionType,
                statusCode: apiResponse.status,
            });
    }
}
import {
    Link,
    Panel} from '@fluentui/react';
import { links } from 'Shared/links';
import { renderNsatSurveyWithoutEligibilityCheck } from 'Services/nsatSvc/nsatSvcClient';
import styles from './FAQs.module.scss';

interface IFAQsProps {
    isOpen?: boolean;
    dismissPanel?: () => void;
}

export const FAQs = (props: IFAQsProps) => {

    const handleRateExperienceClick = () => {
        renderNsatSurveyWithoutEligibilityCheck(false);
        // Close the FAQ panel
        props.dismissPanel?.();
    };

    return (
        <Panel
            layerProps={{ eventBubblingEnabled: true }}
            isLightDismiss
            isOpen={props.isOpen}
            onDismiss={props.dismissPanel}
            closeButtonAriaLabel="Close"
            headerText="Help"
        >
            <div className={styles.helpLinkContainer}>
                <Link href={links.faq} target="_blank" id='lnkHelpFAQ'>
                    FAQs
                </Link>
                <Link href={links.licenceTenantSupport} target="_blank" id='lnkHelpLicence&TenantSupport'>
                    License & Tenant Support
                </Link>
                <Link href={links.demoHubSupport} target="_blank" id='lnkHelpdemoHubSupport'>
                    Demo Hub Support
                </Link>
                    <Link href={links.sendFeedback} target="_blank" id='lnlHelpSendFeedback'>
                        Send Feedback
                    </Link>
                <Link onClick={handleRateExperienceClick} id='lnkRateExperience' className={styles.rateExperienceLink}>
                    Rate Your Experience
                </Link>
            </div>
        </Panel>
    );
}
/* NOTE:  We have implemented a very basic redux service, 
as the scope of our appllication is very small now, it is not required.
 We will enhance it in future as the application grows and we get more use cases of
 centralizing and storing the global state of the application. 
 */

import { createStore } from 'redux'
import rootReducer from './reducers'

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
import { EligibilityResponse } from './models/EligibilityResponse';
import { telemetryLogger, metricsLogger } from 'Providers/Telemetry';
import { ExceptionType } from 'Services/base/exceptionTypes';

export const MapApiResponseToData = (apiResponse: any) => {
    telemetryLogger.trackTrace(`NsatService.MapEligibilityApiResponseToData from Eligibility started with response ${apiResponse}`);
    console.log('inside MapApiResponseToData apiResponse ', apiResponse);
    let data: any;
    if (apiResponse) {
        let newItem: EligibilityResponse = {
            surveyName: apiResponse.SurveyName,
            eligibility: apiResponse.Eligibility,
            formsProId: apiResponse.FormsProId,
            formsProEligibilityId: apiResponse.FormsProEligibilityId,
            renderDetails: apiResponse.RenderDetails,
        };
        telemetryLogger.trackTrace(`NsatService.MapEligibilityApiResponseToData from Eligibility completed with newItem ${newItem}`);
        data = newItem;
    }
    else {
        telemetryLogger.trackException('Received Eligibility response undefined', { exceptionType: ExceptionType.NsatServiceException });
        metricsLogger.emitExceptionLoggedMetric({ UIOperation: "NsatService.MapApiResponseToData" });
    }
    telemetryLogger.trackTrace('NsatService.MapApiResponseToData from Eligibility completed');
    return data;
}
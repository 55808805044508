import { createEnableMocksFn } from 'Shared/utils/mock-utilities';
import {
    DemoRequest,
    RequestFilter,
    NewDemo,
    DemoProvisioning,
    RefreshDemoRequest
} from 'Services';
import { apiSuccessResponse, apiFailureResponse, getErrorResponse } from '../base/getApiResponse';
import { ApiResult } from '../base/models';
import { ApiName } from '../base/enums';
import { create } from '../base/client';
import { DemoContract } from './demosSvcContract';
import { mapApiResponse, mapApiResponseMultiple } from './demosSvc.transforms';
import { BaseApiResponse } from 'Services/base/models/ApiResult';

const client = create(
    ApiName.Demos,
    createEnableMocksFn(async () => import('./demosSvc.mock'))
);

export class DemoService implements DemoContract {

    public myDemos = async (filter: RequestFilter): Promise<ApiResult<DemoRequest[]>> => {
        try {
            var endpoint = `demos?demotype=${filter.DemoType}`;
            let response = await client.get(endpoint);
            return apiSuccessResponse(response, mapApiResponseMultiple);
        } catch (error: any) {
            return apiFailureResponse(error,
                getErrorResponse(error));
        }
    }

    public getDemoRequestById = async (demoRequestId: string | null): Promise<ApiResult<DemoRequest>> => {
        try {
            const response = await client.get(`demos/${demoRequestId}`);
            return apiSuccessResponse(response, mapApiResponse);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }

    }

    public newDemo = async (demoRequest: NewDemo): Promise<ApiResult<string>> => {
        try {
            let response = await client.post('demos',
                demoRequest);
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }

    public startDemoProvisioning = async (demoProvisioningRequest: DemoProvisioning): Promise<ApiResult<BaseApiResponse>> => {
        try {
            let response = await client.post('demos/environment',
                demoProvisioningRequest,
                { userAccount: demoProvisioningRequest?.DemoAdminAccount });// specifiy userAccount to generate the access token in given user's context
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }

    public refreshDemoOrgsWithPpac = async (refreshDemosRequest: RefreshDemoRequest): Promise<ApiResult<number>> => {
        try {
            let response = await client.post('demos/refreshwithppac',
                refreshDemosRequest,
                { userAccount: refreshDemosRequest?.DemoAdminAccount });// specifiy userAccount to generate the access token in given user's context
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }
    public deleteDemo = async (deleteDemoRequest?: string): Promise<ApiResult<string>> => {
        try {
            let response = await client.delete(
                `demos/${deleteDemoRequest}`,
            );
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }
    
}
import {
    Dropdown,
    Button,
    Field,
    Option
} from "@fluentui/react-components";
import { useDataPreview } from './hooks/useDataPreview';
import React, { useEffect } from 'react';

import styles from './data-table.module.scss';
export interface DataTableProps {
    dataToExport: any[];
}

export const DataTable = (props: DataTableProps) => {
    const {
        defaultEntity,
        entities,
        setCurrentTable,
        tableData,
        tableHeaders,
        exportToExcel
    } = useDataPreview({
        dataToExport: props.dataToExport
    });

    useEffect(() => {
        setCurrentTable(defaultEntity);
    }, []);

    return (
        <div className={styles.dataPreviewContainer}>
            <div className={styles.filterContainer}>
                <Field label="Entity" required>
                    <Dropdown
                        aria-labelledby="data-gen-industries"
                        placeholder="Select entity"
                        onOptionSelect={(e, data) => setCurrentTable(data.optionValue ?? '')}
                        defaultValue={defaultEntity}
                    >
                        {entities.map(entity => (
                            <Option key={entity} value={entity}>{entity}</Option>
                        ))}
                    </Dropdown>
                </Field>
            </div>
            {tableData && tableData.length > 0 &&
                <div className={styles.tableContainer}>
                    <table>
                        <thead>
                            <tr>
                                {tableHeaders?.map((header, index) => (
                                    <th key={index}>{header.replace(/\\/g, '')}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((row: any, index: number) => (
                                <tr key={index}>
                                    {tableHeaders?.map((header, index) => (
                                        <td key={index}>{row[header]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={styles.actionContainer}>
                        <Button id="data-export" onClick={() => exportToExcel()}>Export to .xlsx</Button>
                    </div>
                </div>
            }
        </div>
    );
}
import { BrowserRouter } from 'react-router-dom';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Header, Footer } from 'Shared/Controls';
import { OneDsAnalyticaProvider } from 'Providers/Telemetry/OneDsAnalyticaProvider';
import { AuthProvider } from 'Providers/Authentication';
import { AppRoutes, SignIn } from 'Pages';
import { withParentComponent } from '../base/ParentComponent';
import { DemoHubCopilot, enableCopilotWindowResizing } from 'DemoHubCopilot';

import styles from './app.module.scss';
import { useEffect } from 'react';
function App() {
    const isAuthenticated = AuthProvider.isAuthenticated();
    useEffect(() => {
        // For creating resixable screens
        enableCopilotWindowResizing();
    }, [])

    if (!isAuthenticated) {
        return <SignIn />
    }

    OneDsAnalyticaProvider.Initialize(isAuthenticated);

    initializeIcons();

    const PageComponents = () => {
        return (
            <FluentProvider theme={webLightTheme}>
                <Header />
                <div className={styles.demoHubHostContainer} id="demoHubHost">                    
                    <div className={styles.mainContentHost} id="demohubPortalHost">                        
                        <div id="mainContent">
                            <AppRoutes />
                        </div>
                        <Footer />
                    </div>
                    <div id="dragMe" className={styles.dragHandle}></div>
                    <div className={styles.copilotHost} id="demohubCopilotHost">
                        <DemoHubCopilot />
                    </div>
                </div>
            </FluentProvider>
        )
    }

    const WrappedComponent = withParentComponent(PageComponents);

    const MainAppComponent = () => {
        return (
            <BrowserRouter>
                <WrappedComponent />
            </BrowserRouter>
        )
    }

    return <MainAppComponent />;
}

export default App;

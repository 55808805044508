import MockAdapter from 'axios-mock-adapter';

import { ApiConfig } from 'Services/base/models';

interface ModuleType {
	enableMocksFn: (config: ApiConfig, mock: MockAdapter) => void;
}

export const createEnableMocksFn = (getModule: () => Promise<ModuleType>) => async (config: ApiConfig, mock: MockAdapter) => {
	const { enableMocksFn } = await getModule();

	return enableMocksFn(config, mock);
};
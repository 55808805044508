import {
    Label,
    Link
} from '@fluentui/react';
import { CommonUtils } from 'Shared/Utils';
import { BaseProps } from '../base/ComponentProps';
import styles from './announcement-card.module.scss';

interface IAnnouncementCardProps extends BaseProps {
    id: string;
    title: string;
    subTitle?: string;
    description?: string;
    isDescriptionRichtext?: boolean;
    inlineLinksOriginUrl?: string;
    imageUrl?: string;
    primaryLinkLabel?: string;
    primaryLinkId?: string;
    primaryLinkText?: string;
    onPrimaryLinkClick?: () => void;
}

export const AnnouncementCard = (props: IAnnouncementCardProps) => {
    return (
        <div
            role="figure"
            id={props.id}
            data-testid={props.id}
            className={styles.announcementCard}>
            <div className={styles.cardHeading}>
                <Label
                    title={props.title}>
                    {props.title}
                </Label>
            </div>
            {props.subTitle && <div className={styles.cardSubHeading}>
                <span
                    title={props.subTitle}>
                    {props.subTitle}
                </span>
            </div>}
            <div className={styles.separator} />
            <div className={!props.isDescriptionRichtext ? styles.cardBody : `${styles.cardBody} ${styles.largeView}`}>
                {props.imageUrl &&
                    <div className={styles.cardImage}>
                        <img
                            src={props.imageUrl}
                            alt={props.title}
                            onError={(e: any) => {
                                e.target.onerror = null;
                                e.target.style.display = 'none';
                            }}>
                        </img>
                    </div>
                }
                {!props.isDescriptionRichtext && <p
                    title={props.description}>
                    {props.description}
                </p>
                }
                {
                    props.isDescriptionRichtext && props.description && <div> {
                        CommonUtils.parseStringToHtml(props.description,
                            props.inlineLinksOriginUrl)
                    } </div>
                }

            </div>
            {(props.primaryLinkText || props.primaryLinkLabel) &&
                <div className={styles.actionButtons}>
                    {props.primaryLinkLabel &&
                        <span className={styles.actionLabel}>
                            {props.primaryLinkLabel}
                        </span>
                    }
                    {props.primaryLinkText &&
                        <Link
                            id={props.primaryLinkId}
                            title={props.primaryLinkText}
                            aria-label={props.primaryLinkText}
                            onClick={props.onPrimaryLinkClick}>
                            {props.primaryLinkText}
                        </Link>
                    }
                </div>
            }
        </div>
    )
};
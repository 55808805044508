import { Dimensions } from "./Dimensions";

/*
Keep Metrics in Alphabetical order
*/
export const Metrics = {    
    CopilotAssetsFetchAPICounter: {
        metricName: 'CopilotAssetsFetchAPICounter',
        dimensionList: [
            Dimensions.ErrorCode,
        ],
        enabled: true,
    }
};

import { DataGenerationInput } from "Services/copilotSvc/models/ConversationRequest";
import { ChatMessage } from "../../Services/ChatCopilot/models";

export type CopilotRequest = {
    question: string;
    datagenerationRequest?: DataGenerationInput;
    chatResponse?: ChatResponse;
    isStarterPromptClicked?: boolean;
    disableForm?: boolean;
}

export enum AgentType {
    User = 'user',
    Copilot = 'assistant'
}

export enum ChatActionResponseType {
    ExportToExcel = 'excelexport',
    DatagenForm = 'datagenform',
    DatagenFormSelectedInputView = 'datagenform_selectedinputview',
    DatagenFormCustomPromptView = 'datagenform_custompromptview'
}

export interface ChatResponse extends ChatMessage {

    responseActions?: ChatResponseAction[];
}

export interface UserResponse extends ChatMessage {
    responseActions?: ChatResponseAction[];
}

export interface ChatResponseAction {
    type: ChatActionResponseType;
    actionControl?: any; // For rendering any UI control
    actionData?: any // for additional data with response
}

export interface DataGenerationProvidedInput {
    msxId: string;
    industry: string;
    apps: string[];
    entities: string[];
    noOfRecords?: number;
}
import { Dimensions, ErrorCode, Metrics } from "Managers/telemetry";
import { Template } from "Models";
import { UserTemplateSvc } from "Services";
import { DemoType } from "Services/Enums";
import { ExceptionType } from "Services/base/exceptionTypes";
import { telemetryMessages } from "Services/base/telemetryMessages";
import { DemoUserTemplate } from "Services/userTemplateSvc/models/DemoUserTemplate";
import { operationName } from "Shared/constants";
import { TelemetryProperties } from "Shared/interfaces/TelemetryProperties";
import { logAndSetApiSuccessMetrics, logAndSetMetrics, logErrorAndSetApiFailureMetrics, logUnhandledExceptionAndSetMetrics } from "Shared/telemetry/telemetryHelper";
import { ExtendedMap, IDimension } from "Telemetry";

export interface IUserTemplateManager {
    addUserTemplateMappingAsync(selectedTemplate: Template): Promise<boolean>;
    getUserTemplatesByDemoTypeAsync<T>(demoType: DemoType): Promise<Template[]>;
    removeUserTemplateMappingAsync(userTemplateRequest: DemoUserTemplate, demoType: DemoType): Promise<void>;
    openClickthrough(clickthroughRequestUrl: string): void;
    getUserTemplateByNameAsync(templateName: string): Promise<Template | null>;
}

export class UserTemplateManager implements IUserTemplateManager {
    /**
     * Adds selected template to the user
     * @param selectedTemplate selected Template
     * @returns True/False
     */
    addUserTemplateMappingAsync = async (selectedTemplate: Template): Promise<boolean> => {
        let telemetryProps: TelemetryProperties = {
            operationName: operationName.UserTemplateSvcAddUserTemplateMappingAsync,
            exceptionType: ExceptionType.UserTemplateServiceException,
            defaultErrorCode: ErrorCode.FailedToAddUserTemplateMapping
        }

        let dimensions = new ExtendedMap<IDimension, string>([
            [Dimensions.DemoType, DemoType[selectedTemplate.demoType || DemoType.mydemos]],
            [Dimensions.TemplateId, selectedTemplate.id]
        ]);

        let demoUserTemplate: DemoUserTemplate = {
            TemplateId: selectedTemplate?.id,
        }

        const userTemplateResponse = await UserTemplateSvc.addUserTemplateMappingAsync(demoUserTemplate);
        const { hasError } = userTemplateResponse;

        try {
            if (!hasError) {
                // Log trace and Set metrics
                logAndSetApiSuccessMetrics(
                    userTemplateResponse,
                    {
                        ...telemetryProps,
                        message: telemetryMessages.addUserTemplateMappingSuccess
                    },
                    Metrics.AddUserTemplateMappingAPICounter,
                    dimensions);
            }
            else {
                // Log exception and metrics
                logErrorAndSetApiFailureMetrics(
                    userTemplateResponse,
                    {
                        ...telemetryProps,
                        message: telemetryMessages.addUserTemplateMappingError
                    },
                    Metrics.AddUserTemplateMappingAPIFailure,
                    dimensions);
            }

            return !hasError;
        }
        catch (error) {
            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    ...telemetryProps,
                    message: telemetryMessages.addUserTemplateMappingUnhandledException,
                    defaultErrorCode: ErrorCode.UserTemplateMappingUnhandledError
                },
                Metrics.AddUserTemplateMappingAPIFailure);
        }

        return false;
    }
    /**
     * Gets user templates by demo type
     * @param demoType Demo Type
     * @returns Array of Templates
     */
    getUserTemplatesByDemoTypeAsync = async (demoType: DemoType): Promise<Template[]> => {
        let telemetryProps: TelemetryProperties = {
            operationName: operationName.UserTemplateSvcGetUserTemplatesByDemoType,
            exceptionType: ExceptionType.UserTemplateServiceException,
            defaultErrorCode: ErrorCode.FailedToFetchUserTemplatesByDemoType
        }

        let dimensions = new ExtendedMap<IDimension, string>([
            [Dimensions.DemoType, DemoType[demoType]]
        ]);

        try {
            const apiResult = await UserTemplateSvc.getUserTemplatesByDemoType(demoType);

            if (!apiResult?.hasError) {
                // Log trace and Set metrics
                logAndSetApiSuccessMetrics(
                    apiResult,
                    {
                        ...telemetryProps,
                        message: telemetryMessages.getUserTemplatesByDemoTypeSuccess
                    },
                    Metrics.GetUserTemplatesByDemoTypeAPICounter,
                    dimensions
                );

                return apiResult.data;
            } else {
                // Log exception and metrics
                logErrorAndSetApiFailureMetrics(
                    apiResult,
                    {
                        ...telemetryProps,
                        message: telemetryMessages.getUserTemplatesByDemoTypeError
                    },
                    Metrics.GetUserTemplatesByDemoTypeAPIFailure,
                    dimensions
                );
            }
        } catch (error) {
            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    ...telemetryProps,
                    message: telemetryMessages.getUserTemplatesByDemoTypeUnhandledException,
                    defaultErrorCode: ErrorCode.FetchUserTemplatesByDemoTypeUnhandledError
                },
                Metrics.GetUserTemplatesByDemoTypeAPIFailure,
                dimensions
            );
        }

        return [];
    }

    /**
     * Remove Template Id mapping to the User
     * @param userTemplateRequest User template Request
     * @param demoType 
     */
    removeUserTemplateMappingAsync = async (userTemplateRequest: DemoUserTemplate, demoType: DemoType): Promise<void> => {
        let telemetryProps: TelemetryProperties = {
            operationName: operationName.UserTemplateSvcRemoveUserTemplateMappingAsync,
            exceptionType: ExceptionType.UserTemplateServiceException,
            defaultErrorCode: ErrorCode.FailedToRemoveUserTemplateMapping
        }

        let dimensions = new ExtendedMap<IDimension, string>([
            [Dimensions.DemoType, DemoType[demoType]],
            [Dimensions.TemplateId, userTemplateRequest.TemplateId.toString()]
        ]);

        const apiResult = await UserTemplateSvc.removeUserTemplateMappingAsync(userTemplateRequest)

        try {
            if (!apiResult?.hasError) {
                // Log trace and Set metrics
                logAndSetApiSuccessMetrics(
                    apiResult,
                    {
                        ...telemetryProps,
                        message: telemetryMessages.removeUserTemplateMappingSuccess
                    },
                    Metrics.RemoveUserTemplateMappingAPICounter,
                    dimensions);
            } else {
                // Log exception and metrics
                logErrorAndSetApiFailureMetrics(
                    apiResult,
                    {
                        ...telemetryProps,
                        message: telemetryMessages.removeUserTemplateMappingError
                    },
                    Metrics.RemoveUserTemplateMappingAPIFailure,
                    dimensions);
            }
        }
        catch (error) {
            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    ...telemetryProps,
                    message: telemetryMessages.removeUserTemplateMappingUnhandledException,
                    defaultErrorCode: ErrorCode.RemoveUserTemplateMappingUnhandledError
                },
                Metrics.RemoveUserTemplateMappingAPIFailure,
                dimensions
            );
        }
    }

    /**
     * Opens Clickthrough Url
     * @param templateUri Template URL
     */
    openClickthrough = (templateUri: string): void => {
        let telemetryProps: TelemetryProperties = {
            operationName: operationName.UserTemplateSvcOpenClickthrough,
            message: telemetryMessages.openClickthrough,
            defaultErrorCode: ErrorCode.FailedToOpenClickthrough
        }

        let dimensions = new ExtendedMap<IDimension, string>([
            [Dimensions.TemplateUri, templateUri],
        ]);

        logAndSetMetrics(
            telemetryProps,
            Metrics.OpenClickthroughAPICounter,
            dimensions
        );

        window.open(templateUri, '_blank');
    }

    /**
     * Gets template by template name
     * @param templateName Template Name
     * @returns Template
     */
    getUserTemplateByNameAsync = async (templateName: string): Promise<Template | null> => {
        let telemetryProps: TelemetryProperties = {
            operationName: operationName.UserTemplateSvcGetTemplateByName,
            exceptionType: ExceptionType.UserTemplateServiceException,
            defaultErrorCode: ErrorCode.FailedToFetchTemplatesByName
        }

        let dimensions = new ExtendedMap<IDimension, string>([
            [Dimensions.TemplateName, templateName]
        ]);

        try {
            const apiResult = await UserTemplateSvc.getUserTemplateByName(templateName);

            if (!apiResult?.hasError) {
                // Log trace and Set metrics
                logAndSetApiSuccessMetrics(
                    apiResult,
                    {
                        ...telemetryProps,
                        message: telemetryMessages.getTemplatesByNameSuccess
                    },
                    Metrics.GetTemplatesByNameAPICounter,
                    dimensions
                );

                return apiResult.data;
            } else {
                // Log exception and metrics
                logErrorAndSetApiFailureMetrics(
                    apiResult,
                    {
                        ...telemetryProps,
                        message: telemetryMessages.getTemplatesByNameError
                    },
                    Metrics.GetTemplatesByNameAPIFailure,
                    dimensions
                );

                return null;
            }
        } catch (error) {
            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    ...telemetryProps,
                    message: telemetryMessages.getTemplatesByNameUnhandledException,
                    defaultErrorCode: ErrorCode.FetchTemplatesByNameUnhandledError
                },
                Metrics.GetTemplatesByNameAPIFailure,
                dimensions
            );
            throw error;
        }
    }
}
import { ICustomProperties, IMetricTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from './ApplicationInsightsLogging';
import * as constants from 'Shared/constants';
import { InsightsMetricContract, InsigtsMetricProperties } from './InsightsMetricContract';

export class InsightsMetric implements InsightsMetricContract {

    // set default properties fro metrics
    getCustomProperties() {
        const customProperties: ICustomProperties = {
            clientCorrelationId: sessionStorage.getItem(constants.CORRELATION_ID)
        };
        return customProperties;
    }

    emitApiFailureMetric(properties?: InsigtsMetricProperties): void {
        const metricData: IMetricTelemetry = {
            name: "Record API Failure",
            average: 1,         //it is requiredfield, so setting it to 1, will check more on it
        };
        appInsights.trackMetric(metricData, { ...properties, ...this.getCustomProperties() });
    }

    emitApiSuccessMetric(properties?: InsigtsMetricProperties): void {
        const metricData: IMetricTelemetry = {
            name: "Record API Success",
            average: 1,
        };
        appInsights.trackMetric(metricData, { ...properties, ...this.getCustomProperties() });
    }

    emitExceptionLoggedMetric(properties?: InsigtsMetricProperties): void {
        const metricData: IMetricTelemetry = {
            name: "Exception Logged",
            average: 1,
        };
        appInsights.trackMetric(metricData, { ...properties, ...this.getCustomProperties() });
    }

    emitGeneralMetric(metricName: string, properties?: InsigtsMetricProperties): void {
        const metricData: IMetricTelemetry = {
            name: metricName,
            average: 1,
        };
        appInsights.trackMetric(metricData, { ...properties, ...this.getCustomProperties() });
    }
}




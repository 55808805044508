export enum RequestHeader {
    APPLICATION_JSON = 'application/json'
}

export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
}

export enum Endpoint {
    NEW_REQUEST = 'NEW',
    MY_REQUEST = 'MYREQUEST',
    GET_USER_ROLES = 'GETUSERROLES',
    START_PROVISIONING_REQUEST = 'STARTPROVISIONINGREQUEST',
    GET_DEMO_REQUEST_BY_ID = 'GETDEMOREQUEST',
    GET_TEMPLATES = 'GETTEMPLATES',
    CONVERSATION = 'COPILOT_CONVERSATION',
}

export enum RequestStatus {
    OK = 200,
    ACCEPTED = 202,
    CREATED = 201,
    NOCONTENT = 204,
    UNAUTHORIZED = 401,
    BADREQUEST = 400,
    FORBIDDEN = 403,
    ERROR = 500,
    DEMOACCOUNTMISMATCH = 309,
    BAPAPIFAILED = 310,
    TENANTUSERSVALIDATIONFAILED = 311
}

export enum DemoType {
    mydemos = 0,
    shareddemos = 1,
    clickthroughs = 2,
}

export const TemplateDemoTypeDisplayNames = {
    [DemoType.mydemos]: 'Demo Environment',
    [DemoType.shareddemos]: 'Shared Demo',
    [DemoType.clickthroughs]: 'Clickthrough',
};
import { Template } from 'Models';

export const mapApiResponse = (apiResponse: any): Template[] => {
    return apiResponse && apiResponse.map((item: any) => {
        return {
            id: item.templateId,
            iconUrl: item.iconUrl,
            name: item.templateName,
            category: item.category,
            isActive: item.isActive,
            demoType: item.demoType
        } as Template;
    });
}

export const mapApiResponseTemplate = (apiResponse: any): Template | null => {
    if (apiResponse) {
        // apiResponse is a single object, not an array
        return {
            id: apiResponse.templateId,
            iconUrl: apiResponse.iconUrl,
            name: apiResponse.templateName,
            category: apiResponse.category,
            isActive: apiResponse.isActive,
            demoType: apiResponse.demoType
        } as Template;
    }

    return null; // Return null if apiResponse is falsy (null or undefined)
};

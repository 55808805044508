import { AxiosRequestConfig } from 'axios';
import { metricsLogger } from 'Providers/Telemetry';
import { ErrorMessage } from 'Shared/constants';
import {
    ApiAccessTokenRequest,
    AuthProvider
} from 'Providers/Authentication';
import { Dimension, ExtendedMap, IDimension } from 'Telemetry';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { logAndSetMetrics } from 'Shared/telemetry/telemetryHelper';
import { UNKNOWN_API, operationName } from '../constants';
import { ExceptionType } from '../exceptionTypes';
import { Dimensions, ErrorCode, Metrics } from './telemetry';
import { OperationStatus } from 'Shared/enums';

const getAccessToken = async (scopes: string[], userContext?: any) => {
    return await AuthProvider.acquireTokenSilentAsync(scopes, userContext);
};

export const authInterceptors = {
    addAadToken: (scopes?: string[]) => async (config: AxiosRequestConfig) => {
        // We use default scope as our first party app id
        // For all other APIs we request an access token against their resource/scopes

        let dimensions = new ExtendedMap<IDimension, string>();

        let telemetryProperties: TelemetryProperties = {
            operationName: operationName.AuthInterceptorsAddAadToken,
            defaultErrorCode: ErrorCode.AccessTokenAcquisitionFailed,
            exceptionType: ExceptionType.AccessTokenException
        }

        if (!scopes) {
            scopes = ApiAccessTokenRequest.scopes;
        }

        const token = await getAccessToken(scopes, config.userAccount);
        const trimmedToken = token?.trim();
        if (trimmedToken && config.headers) {
            config.headers.Authorization = `Bearer ${trimmedToken}`;
        }
        else {
            //ToDO: Access token Retry strategy needs to be revisited. Multiple parallel Api calls are failing when refresh token expired.

            dimensions.addDimension(Dimensions.OperationName, operationName.AuthInterceptorsAddAadToken);
            dimensions.addDimension(Dimensions.ErrorCode, ErrorCode.AccessTokenAcquisitionFailed);
            dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.FAILURE);
            dimensions.addDimension(Dimensions.ApiName, config.apiMethod ?? UNKNOWN_API);

            logAndSetMetrics(
                {
                    ...telemetryProperties,
                    message: ErrorMessage.accessTokenError
                },
                Metrics.AccessTokenAcquisitionFailure,
                dimensions
            );

            config.bapDemosInvalidAccessToken = true;
            config.data = ErrorMessage.accessTokenError;
            return Promise.reject({ config: config });
        }

        return config;
    }
};
import { useEffect, useState } from 'react';
import styles from '../../copilotassets-page.module.scss';


interface SortByProps {
    onSortChange: (selectedSort: string) => void;
}

export const SortBy = (props: SortByProps) => {
    const [sortBy, setSortBy] = useState('date'); // Initialize sortBy state with 'date' for uploaded date (New to Old)

    useEffect(() => {
        // Notify parent component about the default sorting
        props.onSortChange(sortBy);
    }, [sortBy, props]);


    const handleSortChange = (event: any) => {
        const selectedSort = event.target.value;
        setSortBy(selectedSort);
        props.onSortChange(selectedSort);
    };

    return (
        <div className={styles.sortByContainer}>
            <label htmlFor="sort">Sort by:</label>
            <select id="sort" value={sortBy} onChange={handleSortChange} className={styles.selectBox}>
                <option value="title">Title (A to Z)</option>
                <option value="date">Uploaded date (New to Old)</option>
                <option value="dateOldToNew">Uploaded date (Old to New)</option>
            </select>
        </div>
    );
};
import axios, { AxiosResponse } from 'axios';
import { ApiResult, ErrorResponse } from './models';
import {
    CORRELATIONID_RESPONSE_HEADER,
    ACCESS_TOKEN_ERROR,
    UNEXPECTED_ERROR,
    UNKNOWN_STATUS
} from './constants';

// expected error codes for our application
const ErrorCodes: number[] = [
    400, // Bad Request
    401, // Unauthorized
    403, // Forbidden
    404, // Not Found
    500, // Internal Server Error
    502, // Bad Gateway
    503, // Service Unavailable
    504 // Gateway Timeout
];

export const apiSuccessResponse = async <T>(response: AxiosResponse,
    transformCallback?: any): Promise<ApiResult<T>> => {
    const errorStatusCode = isError(response.status);
    return {
        hasError: errorStatusCode,
        status: response.status,
        correlationId: response.headers?.[CORRELATIONID_RESPONSE_HEADER],
        data: errorStatusCode ? response.data : mapSuccessResponse(transformCallback, response.data)
    };
}

export const apiFailureResponse = async<T>(error?: any | null, errorResponse?: any): Promise<ApiResult<T>> => {
    const config = error?.config;

    if (!errorResponse) {
        errorResponse = {
            errorCode: config?.bapDemosInvalidAccessToken ? ACCESS_TOKEN_ERROR : UNEXPECTED_ERROR,
            errorMessage: config?.data || 'Unexpected error occurred'
        }
    }

    return {
        hasError: true,
        isCancelled: axios.isCancel(error),
        correlationId: error?.headers?.[CORRELATIONID_RESPONSE_HEADER],
        data: errorResponse,
        status: error?.response?.status || 0,
    };
}

export const getErrorResponse = (error: any): ErrorResponse => {
    return {
        errorCode: apiErrorCode(error),
        errorMessage: error?.data || error?.message || UNEXPECTED_ERROR
    };
}

const apiErrorCode = (error: any) => {
    return error?.data?.errorCode || error?.code || UNEXPECTED_ERROR;
}

const isError = (status: number): boolean => {
    return ErrorCodes.find((code: number) => code === status) !== undefined;
}

const mapSuccessResponse = <T>(transformCallback: any, responseData: T) => {
    return transformCallback ? transformCallback(responseData) : responseData;
}

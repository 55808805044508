import { PromptStarter } from "@fluentai/prompt-starter";
import { AppFolder16Regular, Sparkle16Regular } from "@fluentui/react-icons";
import { AgentType, ChatActionResponseType, CopilotRequest } from "../../models/CopilotRequest";
import styles from './starter-prompts.module.scss';

// prompts list
const promptsList: any[] = [
    {
        category: 'Ask your demo related questions',
        promptText: "Find answers and troubleshooting tips related to Demo Hub",
        type: "info",
        icon: AppFolder16Regular
    },
    {
        category: 'Generate Demo Data',
        promptText: "Generate customized demo data based on customer scenarios",
        type: "datagen",
        icon: Sparkle16Regular
    }
];

export interface StarterPromptsProps {
    onPromptClick: (copilotRequest: CopilotRequest) => void
}

export const StarterPrompts = (props: StarterPromptsProps) => {
     
    const onPromptClick = (promptType: string) => {
        if (promptType === 'datagen') {
            props.onPromptClick({
                question: "Generate data",
                isStarterPromptClicked: true,
                chatResponse: {
                    role: AgentType.Copilot,
                    content: "Please share the following information related to your demo. Fields marked as '*' are required.",
                    responseActions: [
                        {
                            type: ChatActionResponseType.DatagenForm
                        }
                    ]
                }
            });
        } else {
            props.onPromptClick({
                question: "What is Demo Hub?",
                isStarterPromptClicked: true
            });
        }
    }

    return (
        <div className={styles.starterPrompts}>
            <div className={styles.helptText}>
                <span>Welcome to the Demo hub Copilot! Get started by selecting one of the options.</span>
            </div>
            <div className={styles.promptCards}>
                {promptsList.map((prompt: any, index: number) => {
                    return <PromptStarter
                        key={`card-${index}`}
                        icon={<prompt.icon />}
                        category={prompt.category}
                        prompt={prompt.promptText}
                        onClick={() => { onPromptClick(prompt.type) }}
                    />
                })}
            </div>
        </div >
    )
}
import {
    Spinner,
    SpinnerSize
} from '@fluentui/react';

import styles from './loader.module.scss';

interface LoaderProps {
    Message: string;
    Size: SpinnerSize;
    IsOverlay?: boolean;
    ClassName?: string;
}

export const Loader = (props: LoaderProps) => {

    const LoaderBox = () => {
        return (
            <div className={`${styles.loaderWrapper} ${props.ClassName}`}>
                <Spinner
                    size={props.Size}
                    label={props.Message}
                    ariaLive="assertive"
                    className={styles.loaderItems}
                />
            </div>
        )
    };

    if (props.IsOverlay) {
        return (
            <div className={styles.loaderOverlay}>
                <div className={styles.overlayInner}>
                    <div className={styles.overlayContent}>
                        <div className={styles.loaderBox}>
                            <LoaderBox />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return <LoaderBox />;
}
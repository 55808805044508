import { Link, PrimaryButton, SpinnerSize } from '@fluentui/react'
import { useNavigate } from 'react-router-dom';
import { fetchText } from 'Providers/Localization';
import { HeroBanner } from 'Shared/Components';
import { Loader, MessageBox } from 'Shared/Controls';
import { links } from 'Shared/links';
import { CommonUtils } from 'Shared/Utils';
import { AssetsAndResources } from './components/assets-and-resources/AssetsResources';
import { AnnouncementsList } from './components/announcements-list/AnnouncementsList';
import { AdditionalResources } from './components/additional-resources/AdditionalResources';
import { SolutionPlayDemos } from './components/solution-play-demos/SolutionPlayDemo';
import { DeployPreconfiguredDemo } from './components/deploy-preconfigured-demo/DeployPreconfiguredDemo';

import { renderNsatSurveyWithoutEligibilityCheck } from 'Services/nsatSvc/nsatSvcClient';
import { useHome } from './hooks/useHome';

import styles from './home.module.scss';

const homePageContentNs = 'common';
const homePageContentPrefix = 'HomePage';

export const HomePage = () => {
    const {
        isLoading,
        announcementsContent,
        assetsResourcesContent,
        solutionPlayDemosContent,
        deployPreConfiguredDemoContent,
        onReadmoreClick,
        isNsat,
        onExploreCopilotAssetClick } = useHome();

    const getText = (key: string): string => {
        key = homePageContentPrefix + '.' + key;
        return fetchText(key, homePageContentNs);
    }

    // show a spinner while content is being fetched
    if (isLoading) {
        return (<Loader
            Message="Please wait.."
            Size={SpinnerSize.large} />
        )
    }

    if (isNsat) {
        renderNsatSurveyWithoutEligibilityCheck(true);
    }

    return (
        <div className={styles.homePage}>
            <HeroBanner />
            <div className={styles.homePageContent}>
                <div>
                    <div className={styles.overviewSection}>
                        <div className={styles.textContainer}>
                            <h2>{getText('Overview.Heading')}</h2>
                            <span>
                                {CommonUtils.parseStringToHtml(getText('Overview.Description'))}
                            </span>
                        </div>
                    </div>
                    {solutionPlayDemosContent.solutionPlayDemos && solutionPlayDemosContent.solutionPlayDemos.length > 0 && (
                        <SolutionPlayDemos
                            className={styles.solutionPlayDemosSection}
                            heading={solutionPlayDemosContent.heading}
                            exploreAllDemos={solutionPlayDemosContent.exploreAllDemos}
                            solutionPlayDemos={solutionPlayDemosContent.solutionPlayDemos} />
                    )}
                    {deployPreConfiguredDemoContent && deployPreConfiguredDemoContent.length > 0 && (
                        <div className={styles.howDoesItWorkSection}>
                            <div className={styles.textContainer}>
                                <h3>{getText('HowDoesItWork.Heading')}</h3>
                                <div>
                                    <DeployPreconfiguredDemo data={deployPreConfiguredDemoContent} />
                                </div>
                            </div>
                            <MessageBox
                                message={getText('HowDoesItWork.ImportantNote')}
                                type="info"
                                isMessageRichtext={true}
                            />
                        </div>
                    )}
                    <AdditionalResources contentFileName={homePageContentNs} />
                    <div className={styles.assetsAndfeedback}>
                        <AssetsAndResources
                            heading={assetsResourcesContent.heading}
                            assetsResources={assetsResourcesContent.assetsResources} />
                    </div>
                </div>
                <div>
                    <div className={styles.copilotAssetsSection}>
                        <div>
                            <h3>{getText('CopilotAssets.Heading')}</h3>
                            <PrimaryButton
                                id='btnCopilotAssets'
                                title={getText('CopilotAssets.LinkText')}
                                aria-label={getText('CopilotAssets.LinkAriaLabel')}
                                onClick={onExploreCopilotAssetClick}>
                                {getText('CopilotAssets.LinkText')}
                            </PrimaryButton>
                        </div>
                        <img role="presentation" src="/images/copilot-assets/copilot_icon.png" alt={getText('CopilotAssets.Heading')} />
                    </div>
                    <AnnouncementsList
                        className={styles.annoucementsSection}
                        heading={announcementsContent.heading}
                        announcements={announcementsContent.announcements}
                        onReadmoreClick={onReadmoreClick}
                    />                    
                    <div className={styles.feedbackSection}>
                        <div>
                            <h2>{getText('Feedback.Heading')}</h2>
                            <p>
                                {getText('Feedback.Description')}
                                <Link
                                    id='lnkFeedbackSurvey'
                                    title={getText('Feedback.SurveyLinkText')}
                                    aria-label={getText('Feedback.SurveyLinkAriaLabel')}
                                    onClick={() => window.open(links.sendFeedback, '_blank')}>
                                    {getText('Feedback.SurveyLinkText')}
                                </Link>
                            </p>
                        </div>
                        <div className={styles.faqSection}>
                            <div>
                                <h3>{getText('FAQ.Heading')}</h3>
                                <PrimaryButton
                                    id='btnFAQ'
                                    title={getText('FAQ.FaqLinkText')}
                                    aria-label={getText('FAQ.FaqLinkAriaLabel')}
                                    onClick={() => window.open(links.faq, '_blank')}>
                                    {getText('FAQ.FaqLinkText')}
                                </PrimaryButton>
                            </div>
                            <img role="presentation" src="/images/home/learn-support_icon.png" alt={getText('FAQ.Heading')} />
                        </div>
                        <div className={styles.newsLetterSection}>
                            <h3>{getText('DemoHubNews.Heading')}</h3>
                            <PrimaryButton
                                id='btnNewsLetter'
                                title={getText('DemoHubNews.NewsLetterLinkText')}
                                aria-label={getText('DemoHubNews.NewsLetterLinkAriaLabel')}
                                onClick={() => window.open(links.newsLetterLink, '_blank')}>
                                {getText('DemoHubNews.NewsLetterLinkText')}
                            </PrimaryButton>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}
import { AuthProvider } from 'Providers/Authentication';
import { Nsat, operationName } from 'Shared/constants';
import { EligibilityResponse, NsatSvc } from 'Services';
import { RenderSurveyParam } from '././models/RenderSurveyParam';
import { EligibilityParam } from '././models/EligibilityParam';
import { NsatInput } from '././models/NsatInput';
import { logAndSetApiSuccessMetrics, logErrorAndSetApiFailureMetrics, logUnhandledExceptionAndSetMetrics } from 'Shared/telemetry/telemetryHelper';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { telemetryMessages } from 'Services/base/telemetryMessages';
import { ErrorCode, Metrics } from './telemetry';
import { MapApiResponseToData } from './nsatSvc.transforms';

export const renderNsatSurvey = async () => {
    var currentUser = AuthProvider.getActiveAccount();
    if (!currentUser?.localAccountId || !currentUser?.tenantId) {
        return;
    }

    // This will suppress NSAT survey for test users.
    // For Playwright tests we are using test users to login and test the application for which we don't want to show NSAT survey.
    if (isTestUser(currentUser?.username)) {
        return;
    }

    triggerNsatEmailWithEligibilityCheck();

    const userId: string = currentUser?.localAccountId;
    const tenantId: string = currentUser?.tenantId;

    let eligibilityParams: EligibilityParam = {
        userId: userId,
        tenantId: tenantId,
        surveyName: Nsat.SurveyName,
        teamName: Nsat.TeamName,
        eventrigger: Nsat.EventTrigger,
    }

    let telemetryProps: TelemetryProperties = {
        operationName: operationName.NsatSvcCheckEligility,
        exceptionType: ExceptionType.NsatServiceException,
        defaultErrorCode: ErrorCode.FailedToCheckNsatSurveyEligibility
    }

    // check eligibility for survey
    NsatSvc.checkEligibility(eligibilityParams).then((eligibilityResponse) => {
        if (eligibilityResponse.hasError) {

            // Log exception and metrics
            logErrorAndSetApiFailureMetrics(eligibilityResponse, { ...telemetryProps, message: telemetryMessages.nsatCheckEligibilityError }, Metrics.NsatCheckEligibilityAPIFailure);

            return;
        }

        if (eligibilityResponse && eligibilityResponse?.data?.eligibility) {
            let surveyContext = {
                teamName: Nsat.TeamName,
                surveyName: Nsat.SurveyName,
                tenantId: tenantId,
                userId: userId,
                culture: 'en-us',
            };
            let productContext = [{ key: 'url', value: window.location.href }, { key: "productdisplayname", value: Nsat.TeamDisplayName }];

            let renderParams: RenderSurveyParam = {
                context: surveyContext,
                productContext: productContext,
                renderDetails: eligibilityResponse?.data?.renderDetails
            }

            // if eligible, render survey
            NsatSvc.renderSurvey(renderParams);
        }

        // Log trace and Set metrics
        logAndSetApiSuccessMetrics(eligibilityResponse, { ...telemetryProps, message: telemetryMessages.nsatCheckEligibilitySuccess }, Metrics.NsatCheckEligibilityAPICounter);
    }).catch((error) => {
        logUnhandledExceptionAndSetMetrics(
            error,
            {
                ...telemetryProps,
                message: telemetryMessages.nsatCheckEligibilityUnhandledException,
                errorCode: ErrorCode.NsatCheckEligibilityUnhandledError
            },
            Metrics.NsatCheckEligibilityAPIFailure);
    });
};

const isTestUser = (username: string): boolean => {
    const testUsers = process.env.REACT_APP_PLAYWRIGHT_TESTACCOUNT_MAILIDS?.split(',');
    return testUsers?.some(x => x.toLowerCase() === username.toLowerCase()) ?? false;
};

export const renderNsatSurveyWithoutEligibilityCheck = async (isEmailSource: boolean) => {
    var currentUser = AuthProvider.getActiveAccount();
    if (!currentUser?.localAccountId || !currentUser?.tenantId) {
        return;
    }

    // This will suppress NSAT survey for test users.
    // For Playwright tests we are using test users to login and test the application for which we don't want to show NSAT survey.
    if (isTestUser(currentUser?.username) && isEmailSource) {
        return;
    }

    const userId: string = currentUser?.localAccountId;
    const tenantId: string = currentUser?.tenantId;

    // DO NOT check eligibility 

    var eligibilityDummyResponse = {
        "SurveyName": "demohubportaleventbasedsurvey",
        "Eligibility": true,
        "FormsProId": null,
        "FormsProEligibilityId": null,
        "RenderDetails": {
            "StopPromptSettings": {
                "Type": "Time",
                "Count": 1
            },
            "RenderOption": "CesUI",
            "Height": 470,
            "Width": 565,
            "CesUISettings": {
                "ViewName": "pop-nsat-categories-v1",
                "ContainerType": "Popup",
                "ToastPosition": null
            },
            "CustomerVoiceSettings": null
        }
    };

    let eligibilityResponse: EligibilityResponse = MapApiResponseToData(eligibilityDummyResponse);

    if (eligibilityResponse && eligibilityResponse?.eligibility) {
        let surveyContext = {
            teamName: Nsat.TeamName,
            surveyName: Nsat.SurveyName,
            tenantId: tenantId,
            userId: userId,
            culture: 'en-us',
        };

        let productContext = [
            { key: 'url', value: window.location.href },
            { key: "productdisplayname", value: Nsat.TeamDisplayName }
        ];

        if (isEmailSource) {
            productContext.push({ key: 'source', value: 'email' });
        } else {
            productContext.push({ key: 'source', value: 'portal' });
        }

        let renderParams: RenderSurveyParam = {
            context: surveyContext,
            productContext: productContext,
            renderDetails: eligibilityResponse?.renderDetails
        }
        // render survey
        NsatSvc.renderSurvey(renderParams);
    }
};

const triggerNsatEmailWithEligibilityCheck = async () => {
    var currentUser = AuthProvider.getActiveAccount();
    if (!currentUser?.localAccountId || !currentUser?.tenantId) {
        return;
    }

    // This will suppress NSAT survey for test users.
    // For Playwright tests we are using test users to login and test the application for which we don't want to show NSAT survey.
    if (isTestUser(currentUser?.username)) {
        return;
    }

    const userId: string = currentUser?.localAccountId;
    const tenantId: string = currentUser?.tenantId;

    let eligibilityParams: EligibilityParam = {
        userId: userId,
        tenantId: tenantId,
        surveyName: Nsat.SurveyNameForEmail,
        teamName: Nsat.TeamName,
        eventrigger: Nsat.EventTrigger,
    }

    let telemetryProps: TelemetryProperties = {
        operationName: operationName.NsatSvcCheckEligility,
        exceptionType: ExceptionType.NsatServiceException,
        defaultErrorCode: ErrorCode.FailedToCheckNsatSurveyEligibility
    }

    // check eligibility for survey
    NsatSvc.checkEligibility(eligibilityParams).then((eligibilityResponse) => {
        if (eligibilityResponse.hasError) {

            // Log exception and metrics
            logErrorAndSetApiFailureMetrics(eligibilityResponse, { ...telemetryProps, message: telemetryMessages.nsatCheckEligibilityError }, Metrics.NsatCheckEligibilityAPIFailure);

            return;
        }
        if (eligibilityResponse && eligibilityResponse?.data?.eligibility) {
            
            let nsatInputModel: NsatInput = {
                userEmail: currentUser?.username ? currentUser.username : '',
                userName: currentUser?.name ? currentUser.name : '',
                userId: userId
            }
            NsatSvc.sendNsatEmail(nsatInputModel);  
        }

        // Log trace and Set metrics
        logAndSetApiSuccessMetrics(eligibilityResponse, { ...telemetryProps, message: telemetryMessages.nsatCheckEligibilitySuccess }, Metrics.NsatCheckEligibilityAPICounter);
    }).catch((error) => {
        logUnhandledExceptionAndSetMetrics(
            error,
            {
                ...telemetryProps,
                message: telemetryMessages.nsatCheckEligibilityUnhandledException,
                errorCode: ErrorCode.NsatCheckEligibilityUnhandledError
            },
            Metrics.NsatCheckEligibilityAPIFailure);
    });
};


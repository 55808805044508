/* Please add in alphabatical order */
export const pageRoutes = {
    announcements: '/announcements',
    copilotAssets: '/assethub',
    error: '/error',
    faq: '/faq',
    home: '/',
    demos: {
        root: '/demos',
        mydemos: '',
        allDemos: ':demotype'
    },
    newDemo: '/newdemorequest',
    survey: '/survey/*',
    templateDetail: '/templateDetail',
    unauthorized: '/unauthorized',
}
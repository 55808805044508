import { Role } from "Services";
import store from "Store/store";

export class RoleHelper {
    private static getRoles = (state: any) => state.rolesInformation.roles;

    public static IsDemoHubSellerRole(): boolean {
        const roles = (this.getRoles(store.getState()));
        return roles?.includes(Role.DEMOHUB_SELLER)
    }

    public static IsDemoUserRole(): boolean {
        const roles = (this.getRoles(store.getState()));
        return roles?.includes(Role.DEMOHUB_VISITOR)
    }
}
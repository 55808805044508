import { useState, useRef } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { CopilotSvc } from 'Services';
import { ChatMessage } from 'Services/ChatCopilot/models';
import { ConversationRequest } from 'Services/copilotSvc/models/ConversationRequest';
import {
    ChatActionResponseType,
    ChatResponseAction,
    ChatResponse,
    CopilotRequest
} from 'DemoHubCopilot/models/CopilotRequest';

export const useDemoHubCopilot = () => {
    const [answers, setAnswers] = useState<ChatResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [disableForm, setDisableForm] = useState<boolean>(false);
    const abortFuncs = useRef([] as CancelTokenSource[]);      

    var messages: ChatMessage[] = [];
    
    const resetConversation = () => {
        setAnswers([]);
        setIsLoading(false);
        messages = [];
    }

    const showLoading = async () => {
        setIsLoading(true);
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                setIsLoading(false);
                resolve();
            }, 1500);
        });
    }

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.cancel('Operation canceled by the user.'));
        setIsLoading(false);
    }

    const callConversationApi = async (copilotRequest: CopilotRequest) => {
        const CancelToken = axios.CancelToken;
        const requestSource = CancelToken.source();
        abortFuncs.current.unshift(requestSource);

        const userMessage: ChatMessage = {
            role: "user",
            content: copilotRequest.question
        };

        try {

            // if starter prompt is clicked, have empty message queue
            if (copilotRequest.isStarterPromptClicked) {
                answers.length = 0;
                messages.length = 0;
                setDisableForm(false);
            }

            // set disable form flag
            if (copilotRequest.disableForm) {
                setDisableForm(true);
            }

            // add if there is any default response
            if (copilotRequest?.chatResponse) {
                if (copilotRequest?.chatResponse.role !== "user") {
                    await showLoading();
                }
                messages.push(copilotRequest?.chatResponse);
                setAnswers([...answers, ...messages]);
                return;
            }
            else {
                messages.push(userMessage);
            }

            setAnswers([...answers, ...messages]);
            setIsLoading(true);

            let newMessages = [];

            for (let answer of messages) {
                if (answer.content.includes('Description:')) {
                    let parts = answer.content.split('Description:');
                    for (let part of parts) {
                        let trimmedPart = part.trim();
                        if (trimmedPart.length > 0) {
                          newMessages.push({ ...answer, content: trimmedPart });
                        }
                    }
                } else {
                    newMessages.push(answer);
                }
            }


            const request: ConversationRequest = {
                messages: [
                    ...answers.filter((answer) => 
                      answer.role !== "error" && 
                      answer.role.length > 0 &&  
                      answer.content !== "Please share the following information related to your demo. Fields marked as '*' are required." &&
                      answer.content !== "Please describe your data to continue. Add as much information as possible to customize the data." &&
                      answer.content !== "Required details are as below."
                    ), 
                    ...newMessages
                  ],
                dataGenerationInput: copilotRequest.datagenerationRequest
            };

            var chatResponse = await CopilotSvc.getAnswer(request, requestSource);
            if (!chatResponse.hasError) {
                const result = chatResponse.data;
                const promptResponse: ChatResponse[] = result.messages;

                // If user has synthetic data
                if (result?.isRequestDataGeneration && result?.syntheticDataGenerated && result?.syntheticDataGenerated.length > 0) {
                    console.log(result?.syntheticDataGenerated);
                    // Add additional action for excel export in answers
                    const synDataExportActionMessage: ChatResponseAction = {
                        type: ChatActionResponseType.ExportToExcel,
                        actionData: result?.syntheticDataGenerated
                    }
                    promptResponse[promptResponse.length - 1].responseActions = [synDataExportActionMessage];
                    let userResponses = promptResponse.filter(response => response.role === 'user');
                    let formattedResponse = userResponses.map((response, index) => 
                        index === 0 ? response.content : `Description: ${response.content}`
                    ).join('\n');
                    //let formattedResponse = `${promptResponse[0].content} \n Description: ${promptResponse[1].content}`;
                    let formattedChatResponse: ChatResponse = {
                        content: formattedResponse,
                        role: "user"
                      };
                    setAnswers([...answers, formattedChatResponse, promptResponse[promptResponse.length - 1]]);
                    setIsLoading(false);
                    return;
                }
                else {
                    setAnswers([...answers,  result.messages[1], result.messages[0]]);
                }
            }
            else {
                if (chatResponse.isCancelled) {
                    // TODO: Log user cancelled the operation
                    return;
                }
                setAnswers([...answers,  {
                    role: "error",
                    content: 'Error occurred'
                }]);

                //TODO: Log trace and Set metrics
            }
            setIsLoading(false);

        } catch (e) {
            setIsLoading(false);
            setAnswers([...answers, ...messages, {
                role: "error",
                content: 'Error occurred'
            }]);
        }
    }

    return {
        answers,
        isLoading,
        showLoading,
        callConversationApi,
        resetConversation,
        stopGenerating,
        disableForm
    }
}
import { Dimensions } from "./Dimensions";

/*
Keep Metrics in Alphabetical order
*/
export const Metrics = {
    AddUserTemplateMappingAPICounter: {
        metricName: 'AddUserTemplateMappingAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.TemplateId,
            Dimensions.DemoType
        ],
        enabled: true,
    },

    AddUserTemplateMappingAPIFailure: {
        metricName: 'AddUserTemplateMappingAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.TemplateId,
            Dimensions.DemoType
        ],
        enabled: true,
    },

    GetUserTemplatesByDemoTypeAPICounter: {
        metricName: 'GetUserTemplatesByDemoTypeAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.TemplateId,
            Dimensions.DemoType
        ],
        enabled: true,
    },

    GetUserTemplatesByDemoTypeAPIFailure: {
        metricName: 'GetUserTemplatesByDemoTypeAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.TemplateId,
            Dimensions.DemoType
        ],
        enabled: true,
    },

    GetTemplatesByNameAPICounter: {
        metricName: 'GetTemplatesByNameAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.TemplateName
        ],
        enabled: true,
    },

    GetTemplatesByNameAPIFailure: {
        metricName: 'GetTemplatesByNameAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.TemplateName
        ],
        enabled: true,
    },

    OpenClickthroughAPICounter: {
        metricName: 'OpenClickthroughAPICounter',
        dimensionList: [
            Dimensions.TemplateUri
        ],
        enabled: true,
    },

    RemoveUserTemplateMappingAPICounter: {
        metricName: 'RemoveUserTemplateMappingAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.TemplateId,
            Dimensions.DemoType
        ],
        enabled: true,
    },

    RemoveUserTemplateMappingAPIFailure: {
        metricName: 'RemoveUserTemplateMappingAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.TemplateId,
            Dimensions.DemoType
        ],
        enabled: true,
    },
};

import { createEnableMocksFn } from 'Shared/utils/mock-utilities';
import { apiSuccessResponse, apiFailureResponse } from '../base/getApiResponse';
import { ApiName } from '../base/enums';
import { ApiResult } from '../base/models';
import { create } from '../base/client';
import { FeatureFlagContract } from './featureFlagSvcContract';

const client = create(
    ApiName.FeatureFlags,
    createEnableMocksFn(async () => import('./featureFlagSvc.mock'))
);

export class FeatureFlagService implements FeatureFlagContract {
    flags = async (): Promise<ApiResult<string[]>> => {
        try {
            const apiEndpoint = 'featureflags';
            let response = await client.get(apiEndpoint);
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error,
                {
                    errorCode: error?.data?.errorCode,
                    errorMessage: error?.data
                });
        }
    }
}
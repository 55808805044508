import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DomElementEventListeners } from 'Shared/Utils';
import {renderNsatSurvey} from 'Services/nsatSvc/nsatSvcClient';

// High order component for executing common logic for all pages.
export const withParentComponent = (Component: any) => (props: any) => {
    const location = useLocation();
    
    useEffect(() => {
        // add Nsat event binding for capturing Nsat event
        DomElementEventListeners.addNsatEventListeners();
    }, [])

    useEffect(() => {
        // Nsat survey eligible check goes here whenever page url changes
        renderNsatSurvey();
    }, [location])

    return (
        <>
            <Component {...props} />
        </>
    )
};
/*
Keep Error Codes in Alphabetical order
*/
export const ErrorCode = {
    CESRenderSurveyApiError: 'CESRenderSurveyApiError',
    FailedToCheckNsatSurveyEligibility: 'FailedToCheckNsatSurveyEligibility',
    NoError: 'NoError',
    NsatCheckEligibilityUnhandledError: 'NsatCheckEligibilityUnhandledError',
    NsatRenderSurveyAccessTokenEmpty: 'NsatRenderSurveyAccessTokenEmpty',
    NsatRenderSurveyError: 'NsatRenderSurveyError',
    NsatRenderSurveyNullArguments: 'NsatRenderSurveyNullArguments',
    NsatRenderSurveyUnhandledError: 'NsatRenderSurveyUnhandledError',
    NsatSendNsatEmailError: 'NsatSendNsatEmailError',
    NsatSendNsatEmailNullArguments: 'NsatSendNsatEmailNullArguments',
    NsatSendNsatEmailUnhandledError: 'NsatSendNsatEmailUnhandledError',

}
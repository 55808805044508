import { AxiosRequestConfig } from 'axios';

import { CORRELATION_REQUEST_HEADER } from '../constants';
import * as constants from 'Shared/constants';

export const portalInterceptors = {
    addCorrelationId: (conf: AxiosRequestConfig) => {
        if (conf.headers) {
            conf.headers[CORRELATION_REQUEST_HEADER] = sessionStorage.getItem(constants.CORRELATION_ID);
        }
        return conf;
    }
};
import { Template } from "Models";
import { RoleHelper } from "Shared/helpers/role-helper";

export interface ITemplateManager {
    disableChooseTemplateButton(template: Template): boolean;
}

export class TemplateManager implements ITemplateManager {        
    disableChooseTemplateButton = (template: Template): boolean => {
        return !template.isActive || !RoleHelper.IsDemoHubSellerRole();
    }
}
import { metricsLogger, telemetryLogger } from 'Providers/Telemetry';
import { AuthProvider, NsatAccessTokenRequest } from 'Providers/Authentication';
import { EligibilityParam } from './models/EligibilityParam';
import { RenderSurveyParam } from './models/RenderSurveyParam';
import { EligibilityResponse } from './models/EligibilityResponse';
import { operationName } from 'Shared/constants';
import { CheckArgumentNullException, ArgumentKeyValuePair } from 'Shared/ErrorHandler';
import { createEnableMocksFn } from 'Shared/utils/mock-utilities';
import { apiSuccessResponse, apiFailureResponse, getErrorResponse } from '../base/getApiResponse';
import { ApiResult, ErrorResponse } from '../base/models';
import { ApiName } from '../base/enums';
import { create } from '../base/client';
import { NsatContract } from './nsatSvcContract';
import { MapApiResponseToData } from './nsatSvc.transforms';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { ErrorCode, Metrics } from './telemetry';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { logAndSetApiSuccessMetrics, logAndSetMetrics, logErrorAndSetApiFailureMetrics, logUnhandledExceptionAndSetMetrics } from 'Shared/telemetry/telemetryHelper';
import { Dimensions, ExtendedMap, IDimension } from 'Telemetry';
import { OperationStatus } from 'Shared/enums';
import { OK_STATUS, UNKNOWN_STATUS } from 'Services/base/constants';
import { telemetryMessages } from 'Services/base/telemetryMessages';
import { NsatInput } from './models/NsatInput';

const baseUrl = process.env.REACT_APP_NSAT_BASE_URL || 'https://world.tip1.ces.microsoftcloud.com';

const emailSurveyLink = process.env.REACT_APP_NSAT_SURVEY_URL || 'https://aka.ms/demohub/rateyourexperience';

export const client = create(
    ApiName.Nsat, 
    createEnableMocksFn(async () => import('./nsatSvc.mock'))
);
export const clientForNsat = create(
    ApiName.Nsats,
    createEnableMocksFn(async () => import('./nsatSvc.mock'))
);

export class NsatService implements NsatContract {

    /**
      * Checks the eligibility of the user for prompting the NSAT survey by hitting the ELigibility API
      * Ref - https://msazure.visualstudio.com/One/_wiki/wikis/DTP%20Insights%20and%20Analytics%20wiki/499234/Back-End-Integration 
      * @param eligibilityParams
      * @returns EligibilityResponse 
      */
    public checkEligibility = async (eligibilityParams: EligibilityParam): Promise<ApiResult<EligibilityResponse>> => {
        try {
            // check for argument null exception
            let argumentList: ArgumentKeyValuePair[] = [
                { argumentName: 'eligibilityParams.userId', argumentValue: eligibilityParams.userId },
                { argumentName: 'eligibilityParams.tenantId', argumentValue: eligibilityParams.tenantId },
                { argumentName: 'eligibilityParams.surveyName', argumentValue: eligibilityParams.surveyName },
                { argumentName: 'eligibilityParams.teamName', argumentValue: eligibilityParams.teamName },
                { argumentName: 'eligibilityParams.eventrigger', argumentValue: eligibilityParams.eventrigger }];

            if (CheckArgumentNullException(argumentList)) {
                telemetryLogger.trackTrace('NsatService.checkEligibility returning without eligibility check due to null argument(s)');
                return apiFailureResponse(null, { errorCode: 'Invalid Input', errorMessage: 'One or more arguments are null or empty.' });
            }

            let endpoint = `${eligibilityParams.teamName}/Eligibilities/${eligibilityParams.surveyName}?userId=${eligibilityParams.userId}&tenantId=${eligibilityParams.tenantId}&eventName=${eligibilityParams.eventrigger}`;
            let response = await client.post(endpoint);

            return apiSuccessResponse(response, MapApiResponseToData);
        }
        catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }

    /**
   * Adds event for the user based on user actions
   * Ref - https://msazure.visualstudio.com/One/_wiki/wikis/DTP%20Insights%20and%20Analytics%20wiki/499234/Back-End-Integration
   * @param userId
   * @param teamName
   * @param eventName
   */
    public addEvent = async (userId: string, teamName: string, eventName: string): Promise<void> => {
        try {
            // check for argument null error
            let argumentList: ArgumentKeyValuePair[] = [
                { argumentName: 'userId', argumentValue: userId },
                { argumentName: 'teamName', argumentValue: teamName },
                { argumentName: 'eventName', argumentValue: eventName }];

            if (CheckArgumentNullException(argumentList)) {
                telemetryLogger.trackTrace('NsatService.addEvent returning without adding event due to null argument');
                return;
            }

            let apiEndpoint = `${teamName}/Events?userId=${userId}&eventName=${eventName}`;
            const response = await client.post(apiEndpoint);
        }
        catch (error: any) {
            telemetryLogger.trackException('Error adding event', { error: error.message, exceptionType: ExceptionType.NsatServiceException, detailedError: error.stack });
            metricsLogger.emitExceptionLoggedMetric({ UIOperation: "NsatService.addEvent" });
        }
    }

    /**
   * Renders the survey for the user.
   * Ref - https://msazure.visualstudio.com/One/_wiki/wikis/DTP%20Insights%20and%20Analytics%20wiki/499234/Back-End-Integration
   * @param renderParams
   */
    public renderSurvey = async (renderParams: RenderSurveyParam): Promise<void> => {

        let telemetryProps: TelemetryProperties = {
            operationName: operationName.NsatSvcRenderSurvey,
            exceptionType: ExceptionType.NsatServiceException,
            defaultErrorCode: ErrorCode.NsatRenderSurveyError
        }

        let dimensions = new ExtendedMap<IDimension, string>();

        try {
            logAndSetMetrics({ ...telemetryProps, message: telemetryMessages.nsatRenderSurveyInitiated }, Metrics.NsatRenderSurveyCounter);

            telemetryLogger.trackTrace(`${operationName.NsatSvcRenderSurvey} started`);
            var accessToken = await AuthProvider.getApiAccessTokenAsync(undefined, NsatAccessTokenRequest.scopes);

            if (!accessToken) {
                dimensions.addDimension(Dimensions.ErrorCode, ErrorCode.NsatRenderSurveyAccessTokenEmpty);
                dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.FAILURE);
                dimensions.addDimension(Dimensions.ApiStatus, UNKNOWN_STATUS);

                // Log exception and metrics
                logAndSetMetrics({ ...telemetryProps, message: telemetryMessages.nsatRenderSurveyAccessTokenEmpty }, Metrics.NsatRenderSurveyFailure, dimensions);

                return;
            }

            if (CheckArgumentNullException([{ argumentName: 'renderParams.renderDetails', argumentValue: renderParams.renderDetails }, { argumentName: 'baseUrl', argumentValue: baseUrl }])) {
                dimensions.addDimension(Dimensions.ErrorCode, ErrorCode.NsatRenderSurveyNullArguments);
                dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.FAILURE);
                dimensions.addDimension(Dimensions.ApiStatus, UNKNOWN_STATUS);

                // Log exception and metrics
                logAndSetMetrics({ ...telemetryProps, message: telemetryMessages.nsatRenderSurveyNullArguments }, Metrics.NsatRenderSurveyFailure, dimensions);

                return;
            }

            var cesProperties = {
                renderSurveyObject: renderParams,
                baseUrl: baseUrl,
                accessToken: accessToken,
                feedbackCallbackFunc: null,
                dismissCallbackFunc: null
            };

            window.ces.renderSurvey(cesProperties)
                .then(() => {
                    dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.SUCCESS);
                    dimensions.addDimension(Dimensions.ApiStatus, OK_STATUS);

                    // Log exception and metrics
                    logAndSetMetrics({ ...telemetryProps, message: telemetryMessages.cesRenderSurveySuccess }, Metrics.CESRenderSurveyCounter, dimensions);
                })
                .catch((error: any) => {
                    // Log Unhandled exception and metrics
                    logUnhandledExceptionAndSetMetrics(
                        error,
                        {
                            ...telemetryProps,
                            message: telemetryMessages.cesRenderSurveyError,
                            defaultErrorCode: ErrorCode.CESRenderSurveyApiError
                        },
                        Metrics.CESRenderSurveyFailure
                    );
                });
        }
        catch (error: any) {
            // Log Unhandled exception and metrics
            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    ...telemetryProps,
                    message: telemetryMessages.nsatCheckEligibilityUnhandledException,
                    defaultErrorCode: ErrorCode.NsatRenderSurveyUnhandledError
                },
                Metrics.NsatRenderSurveyFailure
            );
        }
    }

    public sendNsatEmail = async (nsatInput: NsatInput): Promise<ApiResult<string>> => {

        let telemetryProps: TelemetryProperties = {
            operationName: operationName.NsatSvcSendNsatEmail,
            exceptionType: ExceptionType.NsatServiceException,
            defaultErrorCode: ErrorCode.NsatSendNsatEmailError
        }
        let dimensions = new ExtendedMap<IDimension, string>();

        try {
            nsatInput.surveyLink = emailSurveyLink;
            let argumentList: ArgumentKeyValuePair[] = [
                { argumentName: 'userEmail', argumentValue: nsatInput.userEmail },
                { argumentName: 'userId', argumentValue: nsatInput.userId },
                { argumentName: 'userName', argumentValue: nsatInput.userName },
                { argumentName: 'surveyLink', argumentValue: nsatInput.surveyLink }];

            if (CheckArgumentNullException(argumentList)) {
                dimensions.addDimension(Dimensions.ErrorCode, ErrorCode.NsatSendNsatEmailNullArguments);
                dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.FAILURE);
                dimensions.addDimension(Dimensions.ApiStatus, UNKNOWN_STATUS);

                // Log exception and metrics
                logAndSetMetrics({ ...telemetryProps, message: telemetryMessages.nsatSendNsatEmailNullArguments }, Metrics.NsatSendEmailFailure, dimensions);
                return apiFailureResponse(null, { errorCode: ErrorCode.NsatSendNsatEmailNullArguments, errorMessage: telemetryMessages.nsatSendNsatEmailNullArguments });
            }

            const response = await clientForNsat.post('nsats/nsatEmail', nsatInput);
            return apiSuccessResponse(response);
        } catch (error: any) {
            telemetryLogger.trackException('Error sending nsat email', { error: error.message, exceptionType: ExceptionType.NsatServiceException, detailedError: error.stack });
            metricsLogger.emitExceptionLoggedMetric({ UIOperation: "NsatService.sendNsatEmail" });
            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    ...telemetryProps,
                    message: telemetryMessages.nsatSendNsatEmailUnhandledException,
                    defaultErrorCode: ErrorCode.NsatSendNsatEmailUnhandledError
                },
                Metrics.NsatSendEmailFailure
            );
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }
}
import { TemplateResponse } from 'Models';
import { createEnableMocksFn } from 'Shared/utils/mock-utilities';
import { ApiResult } from '../base/models';
import { ApiName } from '../base/enums';
import { create } from '../base/client';
import { TemplatesContract } from './templatesSvcContract';
import { mapApiResponse } from './templatesSvc.transform';
import { apiSuccessResponse, apiFailureResponse, getErrorResponse } from '../base/getApiResponse';

const client = create(
    ApiName.Templates,
    createEnableMocksFn(async () => import('./templatesSvc.mock'))
);

export class TemplatesService implements TemplatesContract {

    public getAllTemplates = async (): Promise<ApiResult<TemplateResponse>> => {
        try {
            const response = await client.get('demos/templates');
            return apiSuccessResponse(response, mapApiResponse);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }
}
import { useEffect, useRef } from "react";
import {
    CopilotProvider,
    AiGeneratedDisclaimer
} from '@fluentai/react-copilot';
import { Tag } from "@fluentui/react-components";
import {
    QuestionInputBox,
    StarterPrompts,
    CopilotMenu,
    AnswerPanel
} from 'DemoHubCopilot';
import { useDemoHubCopilot } from './hooks/useDemoHubCopilot';

import styles from './copilot.module.scss';

export interface CoPilotProps {
    newChat: () => void;
}

export const DemoHubCopilot = () => {
    const { answers,
        resetConversation,
        callConversationApi,
        isLoading,
        stopGenerating,
        disableForm } = useDemoHubCopilot();
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    return (
        <CopilotProvider className={styles.demoHubCopilot} mode='canvas' >
            <div className={styles.header}>
                <Tag title="This feature is currently in preview." shape="circular" className={styles.tagStyle}>Preview</Tag>
                <CopilotMenu onNewChatClick={resetConversation} />
            </div>
            <div className={styles.body}>
                <div className={styles.welcomeMessage}>
                    <div className={styles.iconWithText}>
                        <img className={styles.img1} src="/images/chatcopilot/copilot-icon.png" alt="copilot icon" />
                        <h2>Copilot</h2>
                    </div>
                    <div>
                        <span>Your AI companion for</span>
                    </div>
                    <div className={styles.iconWithText}>
                        {/* <img className={styles.img2} src="/images/chatcopilot/copilot-icon.png" alt="copilot icon" /> */}
                        <h6>Demo Hub</h6>
                    </div>
                    <AiGeneratedDisclaimer className={styles.aiDisclaimer}>
                        AI-generated content may be incomplete or incorrect please review to ensure it is accurate, complete, and appropriate before using.<a href="https://go.microsoft.com/fwlink/?LinkId=518021" target="_blank" rel="noopener noreferrer">
  Privacy
</a>
                    </AiGeneratedDisclaimer>
                </div>
                <StarterPrompts onPromptClick={callConversationApi} />
                <AnswerPanel
                    answers={answers} loading={isLoading}
                    onPromptClick={callConversationApi}
                    onStopGenerating={stopGenerating}
                    disableForm={disableForm} />
                <div ref={chatMessageStreamEnd} />
            </div>
            <div className={styles.footer}>
                <QuestionInputBox onSend={callConversationApi} />
            </div>
        </CopilotProvider>
    )
}
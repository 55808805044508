import { useState } from 'react';

export const useCopyToClipboard = (textToCopy:string) => {
    const [isCopied, setIsCopied] = useState(false);

    async function copyTextToClipboard(copyText:string) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(copyText);
        } else {
            return document.execCommand('copy', true, copyText);
        }
    };

    const handleCopyClick = () => {
        copyTextToClipboard(textToCopy)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2500);
            })
            .catch((err) => {
                // TODO: Add logging here
                console.log(err);
            });
    };

    return {
        onCopyClick: handleCopyClick,
        isCopied
    };
};
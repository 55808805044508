import { BaseProps } from 'Shared/Components';
import { CommonUtils } from 'Shared/Utils';
import { DeployPreConfiguredDemo } from '../../models/deploy-preconfigured-demos';
import styles from './deploy-preconfigured-demo.module.scss';

interface DeployPreconfiguredDemoProps extends BaseProps {
    data: DeployPreConfiguredDemo[] | null;
}

export const DeployPreconfiguredDemo = (props: DeployPreconfiguredDemoProps) => {
    const { data } = props;
    return (
        <div className={styles.deployDemoContainer}>
            <div className={styles.stepCards}>
                {data && data?.length && data.map((step: DeployPreConfiguredDemo, index: number) => {
                    return (
                        <div className={styles.stepItem} key={`deploy-demo-step${index + 1}`} id={`deploy-demo-step${index + 1}`} >
                            <div>
                                <img className={styles.imageStyle} src={step.ImageUrl} alt={step.ImageAltText} />
                            </div>
                            <div className={styles.contentContainer }>
                                <span className={styles.numberStyle}>{step.StepNo}</span>
                                <span className={styles.textStyle}>
                                    {CommonUtils.parseStringToHtml(step.Description)}
                                </span>
                            </div>                            
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
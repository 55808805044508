import { OutputCard } from '@fluentai/react-copilot';
import {
    bundleIcon,
    Person24Regular,
    Person24Filled   
} from "@fluentui/react-icons";
import { ChatActionResponseType, ChatResponse, CopilotRequest, ChatResponseAction } from 'DemoHubCopilot/models/CopilotRequest';
import { DataGenerationInputForm } from '../data-generation-form/DataGenerationInputForm';

import styles from './answer.module.scss';

export interface UserResponseProps extends ChatResponse {
    onPromptClick: (copilotRequest: CopilotRequest) => void;
}
const UserIcon = bundleIcon(Person24Regular, Person24Filled);

export const UserResponse = (props: UserResponseProps) => {

    const getResponseActionType = (action: ChatResponseAction) => {
        if (action.type === ChatActionResponseType.DatagenFormSelectedInputView) {
            return <DataGenerationInputForm
                key="data-gen-form-input-preview"
                onPromptClick={props.onPromptClick}
                dataGenerationProvidedInput={action.actionData} />
        }
    }

    return (
        <div className={`${styles.answerCard} ${styles.user}`}>            
            <OutputCard className={styles.responseCard}>
                <div>
                    {props.content}
                    {props.responseActions && props.responseActions.length > 0 && props.responseActions.map((action: ChatResponseAction, index) => {
                        return getResponseActionType(action);
                    })}
                </div>
            </OutputCard>
            <UserIcon color="#0078D4"/>
        </div>
    )
}
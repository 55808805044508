import { Icon, IIconProps } from '@fluentui/react';
import { useState } from 'react';
import styles from '../../copilotassets-page.module.scss';


const iconProps: IIconProps = { iconName: 'Search' };
const clearIconProps: IIconProps = { iconName: 'Clear' }; 

interface SearchTextBoxProps {
    value: string;
    onChange: (event: any) => void;
    placeholder: string;
    onClear: () => void; // Handler for clearing the input
}

export const SearchTextBox = (props: SearchTextBoxProps) => {  
    const [isInputFocused, setInputFocused] = useState(false);

    const handleFocus = () => {
        setInputFocused(true);
    };

    const handleBlur = () => {
        setInputFocused(false);
    };

    const handleClear = () => {
        props.onClear(); // Call the onClear handler
    };

    return (
        <div className={`${styles.searchTextBox} ${isInputFocused ? styles.focused : ''}`}>
            <input
                type="text"
                value={props.value}
                onChange={props.onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={props.placeholder}
            />
            <Icon
                iconName={isInputFocused ? clearIconProps.iconName : iconProps.iconName}
                className={styles.searchIcon}
                onClick={handleClear} 
            />
        </div>
    );
};
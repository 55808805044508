import { Icon } from '@fluentui/react';

interface IIconProps {
    iconName: string;
    className?: string;
    ariaLabel?: string;
}

export const IconLoader = (props: IIconProps) => {
    return (
        <Icon
            aria-label={props.ariaLabel}          
            iconName={props.iconName}
            className={props.className} />
    )
};
interface AuthConstantsType {
    AUTH_STATE: string;
    MSA_AUTH: string;
    AAD_AUTH: string;
    MSA_TENANT_ID: string;
    PROMPT_TYPE: string;
}

interface AuthErrorCode {
    SILENT_SSO_ERROR: string;
    POPUP_WINDOW_ERROR: string;
    UNKNOWN_ERROR: string;
    CONSENT_REQUIRED: string;
    INTERACTION_REQUIRED: string;
    LOGIN_REQUIRED: string;
    NO_TOKENS_FOUND: string;
    INVALID_GRANT: string;
    INVALID_ACCESS_TOKEN: string;
    NO_ACCOUNT_ERROR: string;
}

export const AuthConstants: AuthConstantsType = {
    AUTH_STATE: 'signedIn',
    MSA_AUTH: 'msa',
    AAD_AUTH: 'aad',
    MSA_TENANT_ID: '9188040d-6c67-4c5b-b112-36a304b66dad',
    PROMPT_TYPE: 'select_account'
}

export const AuthErrorCode: AuthErrorCode = {
    SILENT_SSO_ERROR: 'silent_sso_error',
    POPUP_WINDOW_ERROR: 'popup_window_error',
    UNKNOWN_ERROR: 'unknown_error',
    CONSENT_REQUIRED: 'consent_required',
    INTERACTION_REQUIRED: 'interaction_required',
    LOGIN_REQUIRED: 'login_required',
    NO_TOKENS_FOUND: 'no_tokens_found',
    INVALID_GRANT: 'invalid_grant',
    INVALID_ACCESS_TOKEN: 'invalid_access_token',

    // Error message: No account object provided to acquireTokenSilent and no active account has been set. Please call setActiveAccount or provide an account on the request.
    NO_ACCOUNT_ERROR: 'no_account_error'
}

export const operationName = {
    MsalAcquireSilentToken: 'MsalAuthProvider.acquireTokenSilentAsync',
    MsalSignInRedirect: 'MsalAuthProvider.signInRedirect'
}
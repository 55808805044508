import { useState } from 'react';

const INPUT_TYPE_CHECKBOX: string = 'checkbox';

export interface IFormModel {
    props: any;
}

export const useForm = (model: IFormModel) => {

    const [values, setValues] = useState(model.props());
    const [errors, setErrors] = useState(<any>{});

    const getInputValue = (element: any) => {
        // if checkbox , set value to checked property
        if (element.target.type === INPUT_TYPE_CHECKBOX) {
            return element.target.checked;
        }

        return element.target.value;
    }

    const handleInputChange = (e: any) => {
        const name = e.target.name;
        const value = getInputValue(e);

        setValues({
            ...values,
            [name]: value
        })
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange
    }
}
// This function make the copilot chat window resizable
export const enableCopilotWindowResizing = () => {
    // Query the element
    const resizer = document.getElementById('dragMe') as HTMLElement;
    const resizerParent = document.getElementById('demoHubHost');
    const leftSide = document.getElementById('demohubPortalHost') as HTMLElement;
    const rightSide = document.getElementById('demohubCopilotHost') as HTMLElement;

    // The current position of mouse
    let x = 0;
    let y = 0;

    // Width of left side
    var leftWidth: number = 0;

    // Handle the mousedown event
    // that's triggered when user drags the resizer
    const mouseDownHandler = function (e: any) {
        // Get the current mouse position
        x = e.clientX;
        y = e.clientY;
        leftWidth = leftSide?.getBoundingClientRect().width || 0;

        // Attach the listeners to `document`
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e: any) {
        // How far the mouse has been moved
        const dx = e.clientX - x;
        const dy = e.clientY - y;

        const parentReactWidth = resizerParent?.getBoundingClientRect()?.width || 1;

        let newLeftWidth = ((leftWidth + dx) * 100) / parentReactWidth;
        leftSide.style.width = newLeftWidth + '%';

        console.log('parentWidth:', parentReactWidth);
        rightSide.style.width = `${100 - newLeftWidth}%`;
        console.log('leftSideWidth:', newLeftWidth);
        resizer.style.cursor = 'col-resize';
        document.body.style.cursor = 'col-resize';

        leftSide.style.userSelect = 'none';
        leftSide.style.pointerEvents = 'none';

        rightSide.style.userSelect = 'none';
        rightSide.style.pointerEvents = 'none';
    };


    const mouseUpHandler = function () {
        resizer?.style.removeProperty('cursor');
        document.body.style.removeProperty('cursor');

        rightSide.style.removeProperty('user-select');
        rightSide.style.removeProperty('pointer-events');

        leftSide.style.removeProperty('user-select');
        leftSide.style.removeProperty('pointer-events');

        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    // Set the cursor to col-resize
     if (resizer) {
        resizer.style.cursor = 'col-resize';
    }
     
    resizer?.addEventListener('mousedown', mouseDownHandler);
}
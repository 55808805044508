import { useEffect } from 'react';
import { DefaultButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import { DomElementEventListeners } from 'Shared/Utils';
import { pageRoutes } from 'Pages';
import { Nsat } from 'Shared/constants';
import {
    addIconProps,
    messages
} from './herobanner.settings';
import styles from './herobanner.module.scss';

export const HeroBanner = () => {
    const navigate = useNavigate();
    useEffect(() => {
        DomElementEventListeners.addNsatEventListeners('#mydemos-hero-banner');
    }, [])

    const handleNewDemoClick = () => {
        navigate(pageRoutes.newDemo);
    };

    return (
        <div className={styles.heroBanner} id="mydemos-hero-banner">
            <div className={styles.heroImage}>
                {/*TODO: find a better way for loading responsive images*/}
                <img src="/images/Home/hero_banner_desktop_1900.png" className={styles.largeDesktopImage} alt={messages.imageAltText} />
                <img src="/images/Home/hero_banner_desktop_1440.png" className={styles.desktopImage} alt={messages.imageAltText} />
                <img src="/images/Home/hero_banner_tablet_1023.png" className={styles.tabletImage} alt={messages.imageAltText} />
                <img src="/images/Home/hero_banner_tablet_767.png" className={styles.smalltabletImage} alt={messages.imageAltText} />
                <img src="/images/Home/hero_banner_mobile_520.png" className={styles.mobileImage} alt={messages.imageAltText} />
                <img src="/images/Home/hero_banner_mobile_320.png" className={styles.smallMobileImage} alt={messages.imageAltText} />
            </div>
            <div className={styles.heroCopy}>
                <div>
                    <div className={styles.heroSubText}>
                        <div>
                            <h1>{messages.heading}</h1>
                        </div>
                        <div>
                            <span>{messages.subHeading}</span>
                        </div>
                    </div>
                    <div className={styles.demoCreationButton}>
                        <DefaultButton
                            role="link"
                            id='btnRequestDemoEnvironment'
                            name="Request Demo Environment"
                            {...Nsat.EventTriggerAttribute}
                            text={messages.btnRequestDemoText}
                            iconProps={addIconProps}
                            allowDisabledFocus
                            onClick={handleNewDemoClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
import { createEnableMocksFn } from 'Shared/utils/mock-utilities';
import { apiSuccessResponse, apiFailureResponse, getErrorResponse } from '../base/getApiResponse';
import { ApiName } from '../base/enums';
import { ApiResult } from '../base/models';
import { create } from '../base/client';
import { RoleContract } from './roleSvcContract';

const client = create(
    ApiName.Roles,
    createEnableMocksFn(async () => import('./roleSvc.mock'))
);

export class RoleService implements RoleContract {
    roles = async (): Promise<ApiResult<string[]>> => {
        try {
            const apiEndpoint = 'roles';
            let response = await client.get(apiEndpoint);
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }
}
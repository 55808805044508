import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import { UserRequestSvc } from 'Services';
import { links } from 'Shared/links';
import { pageRoutes } from 'Pages';
import { telemetryLogger } from 'Providers/Telemetry';
import styles from './systempages.module.scss';

const securityGroup = process.env.REACT_APP_SELLER_SECURITY_GROUP || 'Biz Apps Demos Seller';

const messages: any = {
    switchingUserContextPopupClosingError: 'Sign in popup did not close automatically while signing using tenant admin credentials.',
}

export const Unauthorized = () => {
    const navigate = useNavigate();
    const isWhitelistedTenantUser = UserRequestSvc.isWhitelistedTenantUser();

    const handleBackButtonClick = () => {
        navigate(pageRoutes.home);
    };

    // if current page is opened in a popup.
    // This is to show a friendly message on this page when this page loads in msal auth pop
    // when pop doesn't get closed automatically.
    // also logs an error message to telemetry.
    if (window.opener) {
        telemetryLogger.trackException(messages.switchingUserContextPopupClosingError);

        return (
            <div className={styles.message}>
                <p>
                    Sign-in completed successfully. If the popup did not close automatically, please close it manually and click the "Try again" button to proceed.
                </p>
            </div>
        )
    }

    return (
        <div style={{ backgroundImage: "url('/images/home/systempage.png')", backgroundRepeat: 'no-repeat' }} className={styles.sytemPagesContainer}>
            <div>
                <h1>
                    401
                </h1>
            </div>

            <div className={styles.message}>
                {!isWhitelistedTenantUser &&
                    <h3>Access denied! You do not have permission to access this application.</h3>
                }
                {isWhitelistedTenantUser &&
                    <h3>To access this page, you should be part of <strong>{securityGroup}</strong>. If you are already a part of the security groups and still don't have access, please contact <a href={links.contactSupport}>Biz Apps Demo Hub Support</a> to get access.</h3>
                }
            </div>
            <div>
                <PrimaryButton text="Back to Home page" id='btnBackToHomeFromUnauthorized'
                    allowDisabledFocus
                    onClick={handleBackButtonClick}
                />
            </div>
        </div>
    )
};
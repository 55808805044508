import { useState } from 'react';
import { generateExcelFromJSON } from 'DemoHubCopilot';
import { copilotConfig } from 'DemoHubCopilot/copilot.settings';
interface DataPreviewParams {
    dataToExport: any[];
}

const getEntityData = (dataToExport: any[], entityName: string) => {
    const filterdData = dataToExport.filter(f => f.entityName === entityName);
    if (filterdData && filterdData.length > 0) {
        const jsonObject = JSON.parse(filterdData[0].dataGenerated);
        return jsonObject?.Entities;
    }
    else {
        for (const item of dataToExport) {
            if (item && item.linkedDataGenerated) {
                const linkedData = item.linkedDataGenerated.find((f: any) => f && f.entityName === entityName);
                if (linkedData && linkedData.dataGenerated) {
                    const jsonObject = JSON.parse(linkedData.dataGenerated);
                    return jsonObject?.Entities;
                }
            }
        }
    }

    return [];
}

function extractEntityNames(data: any): string[] {
    if (!data) {
        return [];
    }

    return Array.from(new Set(data.reduce((names: string[], item: any) => {
        if (item && item.entityName) {
            names.push(item.entityName);
        }
        if (item && item.linkedDataGenerated) {
            names = names.concat(extractEntityNames(item.linkedDataGenerated));
        }
        return names;
    }, [])));
}

export const useDataPreview = (hooksParams: DataPreviewParams) => {
    const dataToExport = hooksParams.dataToExport;
    const entities = dataToExport.length > 0 ? extractEntityNames(dataToExport) : ['N/A'];
    const defaultEntity = entities[0];
    const defaultTableRows = getEntityData(dataToExport, entities[0]);
    const defaultTableColumns = defaultTableRows && defaultTableRows.length > 0 ? defaultTableRows[0] : [];
    const [selectedEntity, setSelectedEntity] = useState<string>(defaultEntity);
    const [tableData, setTableData] = useState<any[]>(defaultTableRows);
    const [tableHeaders, setTableCols] = useState<any[]>(Object.keys(defaultTableColumns));

    const setCurrentTable = (currentEntityName: string) => {
        setSelectedEntity(currentEntityName);
        const entityData = getEntityData(dataToExport, currentEntityName);
        if (entityData && entityData.length > 0) {
            // slice the max no of rows for data preview
            setTableData(entityData.slice(0, copilotConfig.maxRowsForPreview));
            setTableCols(Object.keys(entityData[0]));
        }
    };

    const exportToExcel = () => {
        try {
            // const selectedEntityData = dataToExport.filter(f => f.entityName === selectedEntity);
            // // Call the function with JSON data and file path
            // if (selectedEntityData && selectedEntityData.length) {
            //     generateExcelFromJSON(selectedEntityData[0]);
            // }

            dataToExport.forEach((data) => {
                generateExcelFromJSON(data);
            });
        } catch (e) {
            // TODO: log error
        }
    }

    return {
        defaultEntity,
        entities,
        tableData,
        tableHeaders,
        setCurrentTable,
        exportToExcel
    }
}
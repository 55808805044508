import { useState, } from 'react';
import { OneDsAnalyticaProvider } from "Providers/Telemetry/OneDsAnalyticaProvider";
import { OneDSActionTypes } from "Shared/constants";
import { links } from "Shared/links";

export const useCopilotResponse = () => {
    const [selectedFeedback, setSelectedFeedback] = useState<"positive" | "negative" | undefined>();
    const sendOneDSTelemtry = (e: any) => {
        let actionType = e.key ? OneDSActionTypes.KeyboardEnter : undefined;
        OneDsAnalyticaProvider.CapturePageAction(e.currentTarget, actionType);
    };
    const handlePositiveFeedback = (e: any) => {
        setSelectedFeedback(selectedFeedback === "positive" ? undefined : "positive");
        sendOneDSTelemtry(e);
    };

    const handleNegativeFeedback = (e: any) => {
        setSelectedFeedback(selectedFeedback === "negative" ? undefined : "negative");
        sendOneDSTelemtry(e);
        window.open(links.copilotFeedbackForm, "_blank");
    };

    return {
        selectedFeedback,
        handlePositiveFeedback,
        handleNegativeFeedback
    }
}
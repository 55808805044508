export interface TemplateResponse {
    templates: Template[];
    tenantsList: string[];
}

export interface Template {
    id: string;
    name: string;
    iconUrl: string;
    category: string;
    description?: string;
    demoType?: number;
    isActive?: boolean;
    allowedDemoTenants?: string[];
    productIconsUrl?: string;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    templateDetailContentFile?: string;
    uniqueName?: string;
    clickthroughUrl?: string;
    templateUniqueName?: string;
    importantNote?: string;
    environmentNameRegex?: RegExp;
    environmentNameErrorMessage?: string
}

export enum TemplateActionType {
    ADD_TEMPLATES = 'NEW'
}
import { useParams } from 'react-router-dom';
import { DemoType } from 'Services/Enums';
import { ClickthroughRequests } from './clickthrough/ClickthroughRequests';
import { DemoList } from './DemoList';

export const AllDemoList = () => {
    const { demotype } = useParams<{ demotype: string }>();

    return (
        demotype && demotype === DemoType[DemoType.clickthroughs] ? <ClickthroughRequests /> : <DemoList />
    )
}
import Axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse
} from 'axios';

import MockAdapter from 'axios-mock-adapter';
import { Dictionary } from 'Shared/interfaces/Dictionary';
import {
    getApiConfig,
} from './utils'
import { areMocksEnabled } from './enableMocks';
import { ApiConfig } from './models';
import { ApiName } from './enums';
import { isTest } from 'Shared/constants';
import { authInterceptors } from './interceptors/authInterceptors';
import { loggingInterceptors } from './interceptors/loggingInterceptors';
import { portalInterceptors } from './interceptors/portalInterceptors';

export interface AxiosResponseInterceptor {
    response: (res: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
    error: (res: AxiosError) => Promise<never>;
}

export type AxiosRequestInterceptor = (conf: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;

export const clients: Dictionary<AxiosInstance> = {};

const getRequestInterceptors = (doNotAddNonessentialInterceptors: boolean, config: ApiConfig, userContext?: any) => {
    /**
     * For some reason, axios runs request interceptors in reverse order. (undocumented "feature")
     * https://github.com/axios/axios/issues/1663#issuecomment-405658641
     * We need to have the correlation id added before logRequest is ran so that
     * we can add those ids to the customProperties of the dependency logs
     */
    const requestInterceptors: AxiosRequestInterceptor[] = [loggingInterceptors.logRequest];

    if (!doNotAddNonessentialInterceptors) {
        requestInterceptors.push(portalInterceptors.addCorrelationId);
    }

    requestInterceptors.push(authInterceptors.addAadToken(config.Scopes));

    return requestInterceptors;
};

export const create = (
    apiName: ApiName,
    enableMocksFn?: (config: ApiConfig, mock: MockAdapter) => void | Promise<void>,
    userContext?: any,
    headers?: unknown,
    doNotAddNonessentialInterceptors = false,
    timeout = 300000

): AxiosInstance => {
    const config = getApiConfig(apiName);

    if (clients[apiName]) {
        // TOOD: add logging
        console.log(new Error(`Api client for ${apiName} already exists`));
    }

    if (!config) {
        console.log(new Error(`${apiName} missing from API_CONFIG in ./utils`));
    }

    config.BaseUri = config.BaseUriReference
        ? getApiConfig(config.BaseUriReference).BaseUri
        : config.BaseUri;

    const requestInterceptors = getRequestInterceptors(doNotAddNonessentialInterceptors, config, userContext);

    const responseInterceptors: AxiosResponseInterceptor[] = [
        { response: loggingInterceptors.logResponse, error: loggingInterceptors.logResponseError }
    ];

    const client = headers ? Axios.create({ baseURL: config.BaseUri, headers, timeout }) : Axios.create({ baseURL: config.BaseUri, timeout });

    if (!isTest) {
        requestInterceptors.forEach((interceptor: any) => {
            return client.interceptors.request.use(interceptor);
        });

        // add interceptors for retry here if needed

        responseInterceptors.forEach((interceptor: AxiosResponseInterceptor) => {
            client.interceptors.response.use(interceptor.response, interceptor.error);
        });
    }

    // enable mocks for client
    if ((isTest || areMocksEnabled[apiName]) && enableMocksFn) {
        const mock = isTest ? new MockAdapter(client) : new MockAdapter(client, { delayResponse: Math.max(1000, Math.floor(Math.random() * 3000)) });

        // Call enable mocks once and begin setting up even before the first request has come through
        const enablePromise = enableMocksFn(config, mock);

        client.interceptors.request.use(async (config) => {
            // Ensure mocks have been set up before processing a request
            await enablePromise;

            return config;
        });
    }

    // add to master api client object
    clients[apiName] = client;

    // Axios default configuration is to throw error for status >= 200 && status < 300;
    // we don't want to throw error implicitly, instead we want to handle them by checking the status code at our end
    // add axios.defaults.validationStatus = null to avoid throwing error in case of error response
    client.defaults.validateStatus = null;

    return client;
};

export const enableEndpoints = () => {
    Object.keys(clients).forEach((apiName: string) => {
        // for mocks, we want to maintain the original tokenized baseUris
        if (!isTest && !areMocksEnabled[apiName as ApiName]) {
            clients[apiName].defaults.baseURL = clients[apiName].defaults.baseURL;
        }
    });
};

export const getHeaderConfig = (envId: string, existingConfig?: AxiosRequestConfig) => {
    return envId ? { ...existingConfig, headers: { ...existingConfig?.headers } } : existingConfig;
};
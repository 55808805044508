import {
    DefaultButton,
    Label,
    PrimaryButton
} from '@fluentui/react';
import { BaseProps } from '../base/ComponentProps';
import styles from './card.module.scss';
import { CommonUtils } from 'Shared/utils/common-utils';
import { DemoType, TemplateDemoTypeDisplayNames } from 'Services/Enums';

interface ICardProps extends BaseProps {
    id: string;
    title: string;
    description?: string;
    demoType?: number;
    showColorBand?: boolean;
    isDescriptionRichtext?: boolean;
    imageUrl?: string;
    primaryButtonId?: string;
    primaryButtonText?: string;
    onPrimaryButtonClick?: () => void;
    disablePrimaryButton?: boolean;
    secondaryButtonId?: string;
    secondaryButtonText?: string;
    onSecondaryButtonClick?: () => void;
    disableSecondaryButton?: boolean;
}

export const Card = (props: ICardProps) => {

    const { demoType } = props;
    const cardBorderStyle = props.showColorBand ? `${styles.card} ${getCardStylingByDemoTypeClassName(demoType)}`: `${styles.card}`;

    return (
        <div
            className={styles.cardContainer}
            role="figure"
            id={props.id}
            data-testid={props.id}>
            <div
                className={cardBorderStyle}>
                <div className={styles.cardHeading}>
                    <Label
                        title={props.title}>
                        {props.title}
                    </Label>
                </div>
                {props.imageUrl &&
                    <div className={styles.cardImage}>
                        <img
                            src={props.imageUrl}
                            alt={props.title}>
                        </img>
                    </div>
                }
                <div className={styles.cardBody}>
                    {(demoType?.toString()) &&
                        <p>
                            <strong>Demo Type: </strong>
                            {TemplateDemoTypeDisplayNames[demoType as keyof typeof TemplateDemoTypeDisplayNames]}
                        </p>
                    }
                    {!props.isDescriptionRichtext && <p
                        title={props.description}>
                        {props.description}
                    </p>
                    }
                    {
                        props.isDescriptionRichtext && props.description && <div> {CommonUtils.parseStringToHtml(props.description)} </div>
                    }
                </div>
                <div className={styles.buttonContainer}>
                    {props.primaryButtonId &&
                        <div className={styles.actionButtons}>
                            <PrimaryButton
                                id={props.primaryButtonId}
                                data-testid={props.primaryButtonId}
                                text={props.primaryButtonText}
                                ariaLabel={props.primaryButtonText}
                                onClick={props.onPrimaryButtonClick}
                                disabled={props.disablePrimaryButton}
                                {...props.dataAttributes} />
                        </div>
                    }
                    {props.secondaryButtonId &&
                        <div className={styles.actionButtons}>
                            <DefaultButton
                                id={props.secondaryButtonId}
                                data-testid={props.secondaryButtonId}
                                text={props.secondaryButtonText}
                                ariaLabel={props.secondaryButtonText}
                                onClick={props.onSecondaryButtonClick}
                                disabled={props.disableSecondaryButton}
                                {...props.dataAttributes} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

const getCardStylingByDemoTypeClassName = (demoType: number | undefined): string => {
    if (demoType === DemoType.shareddemos) {
        return styles.cardSharedDemoType;
    } else if (demoType === DemoType.clickthroughs) {
        return styles.cardClickthroughDemoType;
    }
    return styles.cardDefaultDemoType;
}
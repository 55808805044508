import { Dimensions } from "./Dimensions";

/*
Keep Metrics in Alphabetical order
*/
export const Metrics = {
    MyDemosAPIFailure: {
        metricName: 'MyDemosAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus,
            Dimensions.DemoType
        ],
        enabled: true,
    },

    MyDemosAPICounter: {
        metricName: 'MyDemosAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.OperationStatus,
            Dimensions.DemoType
        ],
        enabled: true,
    },
    RefreshDemoOrgsAPIFailure: {
        metricName: 'RefreshDemoOrgsAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode
        ],
        enabled: true,
    }
};

import { DemoType } from "Services/Enums";

export interface RequestFilter {
    DemoType: DemoType;
}

export interface DemoRequest {
    DemoRequestId?: string;
    TemplateName?: string;
    TemplateIconUrl?: string;
    EnvironmentName?: string;
    EnvironmentUrl?: string;
    SecondaryEnvironmentUrl?: string;
    UserName: string;
    Status: number;
    CreatedOn: string;
    CreatedBy?: string;
    ValidationStatus?: string;
    IsEditing?: boolean;
    DemoUserAccount?: string;
    OperationStatus?: string;
    Description?: string;
    isDemoScriptsEnabled?: boolean;
    isConfigurationStepsEnabled?: boolean;
}

export const DefaultAPIResponse: DemoRequest[] = [];
import { ApiName } from '../base/enums';
import { create } from '../base/client';
import { apiSuccessResponse, apiFailureResponse, getErrorResponse } from '../base/getApiResponse';
import { ApiResult } from '../base/models';
import { ContentContract } from './contentSvcContract';

const client = create(
    ApiName.Content
);

export class ContentService implements ContentContract {

    public getContent = async (endpoint: string): Promise<ApiResult<string>> => {
        try {
            let response = await client.get(endpoint);
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error, getErrorResponse(error));
        }
    }
}
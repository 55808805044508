import parse from 'html-react-parser';
import { ErrorModel } from 'Models';
import { ErrorMessage } from 'Shared/constants';
import { ApiResult } from 'Services/base/models';
import { telemetryLogger } from 'Providers/Telemetry';
import { RequestStatus } from 'Services/base/enums';
import { SurveyType } from 'Shared/enums';

/**
 * @description Utility class for common operations
 */
export class CommonUtils {

    /**
     * Method to get the formatted error message
     * @param apiResponse  object
     * @returns formatted error model
     */
    static formatErrorResponse<T>(response: ApiResult<T>): ErrorModel {
        let errorModel: ErrorModel = {
            errorCode: response?.status,
            message: response?.data,
            correlationId: response?.correlationId,
        };
        return errorModel;
    }

    /**
     * Format given string in Slug/id format. i.e. witout any special chars or spaces.  
     * @param inputString
     * @returns formatted string
     */
    static formatStringInSlugFormat(inputString?: string): string {
        if (!inputString || inputString.length === 0) {
            return 'unknown-id';
        }

        return inputString.replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'<>,.\/? ])+/g, '-').replace(/^(-)+|(-)+$/g, '-')
    }

    /**
     * A method to convert string to Html element
     * @param inputText the raw Html string
     * @param originUrlForInlinelinks the origin url to replace the relative urls of inline links
     * @returns HTML element
     */
    static parseStringToHtml(inputText?: string, originUrlForInlinelinks?: string): string | JSX.Element | JSX.Element[] {
        try {
            if (!inputText || inputText.length === 0) {
                return '';
            }

            if (originUrlForInlinelinks) {
                inputText = this.replaceRelativeUrls(inputText, originUrlForInlinelinks);
            }

            const htmlText = parse(inputText);
            return htmlText;

        } catch (e) {
            telemetryLogger.trackException('An error occurred while parsing the string to html', e);
            return '';
        }
    }

    /**
     * A method to replace the relative Url link in the html
     * @param inputHtml The input html string
     * @param originUrl The Origin Url
     * @returns html string with replaced relative Urls.
     */
    static replaceRelativeUrls(inputHtml: string, originUrl: string): string {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = inputHtml;
        var links = tempDiv.getElementsByTagName('a');

        if (links && links.length) {
            for (let linkCounter = 0; linkCounter < links.length; linkCounter++) {
                let link = links[linkCounter];
                let href = link.getAttribute('href');
                if (href && !href.startsWith('http')) {
                    link.setAttribute('href', originUrl + href);
                    link.setAttribute('target', '_blank');
                }
            }
            inputHtml = tempDiv.innerHTML;
        }

        return inputHtml;
    }

    static redirectToErrorPage = (error: any): boolean => {
        // For access token error, we are silently renewing/generating the token by retrying the request. Hence, 500 page is not required
        return error && error.data?.errorMessage !== ErrorMessage.accessTokenError
    }

    static redirectToUnAuthorizedPage = (error: any): boolean => {
        // redirect to unauthorised page if the status is 401 or 403
        return error && (error.status === RequestStatus.FORBIDDEN || error.status === RequestStatus.UNAUTHORIZED) && error.data?.errorMessage !== ErrorMessage.accessTokenError;
    }

    static compareStringIgnoreCase = (inputString1: string | undefined, inputString2: string | undefined): boolean => {
        if (inputString1 && inputString2) {
            return inputString1.toLowerCase() === inputString2.toLowerCase();
        }
        return false;
    }

    static replaceSpaceWithHyphen = (input: string): string => {
        // return string with all spaces replaced to hyphen
        return input.replaceAll(' ','-');
    }

    /**
     * Extracts the URL type from the current window's pathname based on the provided input path.
     * @param inputPath The path pattern to match against the current window's pathname.
     * @returns {string} The URL type extracted from the input path, or an empty string if not found.
     */
    static getUrlTypeFromPath = (inputPath: string) => {
        if (typeof window === 'undefined') {
            return '';
        }
        const pathName = window.location.pathname;

        if (pathName && pathName.includes(inputPath)) {
            const splitPath = pathName.split(inputPath);
            // Check if splitPath exists and has at least two elements
            if (splitPath && splitPath.length > 1) {
                const urlType = splitPath[1];
                return urlType;                 
            }
        }
        return '';
    };
}
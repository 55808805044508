import { AuthProvider } from 'Providers/Authentication';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

export const SignIn = () => {
    const { inProgress } = useMsal();
    // if there is already an MSAL interaction in progress, do not trigger sign-in
    // trigger only if inProgress is none (no interaction is in progress)
    if (inProgress === InteractionStatus.None) {
        AuthProvider.signIn();
    }

    return <></>;
}
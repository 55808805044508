export interface RolesInformation {
    Roles?: Set<string>;
}

export enum Role {
    DEMOHUB_VISITOR = 'DemoHubVisitor',
    DEMOHUB_SELLER = 'DemoHubSeller',
    DEMOHUB_ADMIN = 'DemoHubAdmin'
}

export enum Permission {
    CHOOSE_DEMO_TEMPLATE = "ChooseDemoTemplate",
    CREATE_DEMO_REQUEST = "CreateDemoRequest",
    GET_DEMO_REQUEST = "GetDemoRequest",
    MANAGE_USERS = "ManageUsers",
    VIEW_PORTAL_CONTENT = "ViewPortalContent"
}

export enum RolesActionType {
    ADD_ROLES = 'ADD'
}
interface IMeControlConfig {
    containerId: string;
    apiVersion: string;
    authProvider: string;
}

export const MeControlConfig: IMeControlConfig = {
    containerId: 'me-control-container',
    apiVersion: 'GEN2',
    authProvider: 'msalJsAadOnly'
}
/**
 * @description Utility class for date operations
 */
export class DateUtils {

    static onlyDate: any = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    static defaultOption: any = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    static getTodayDate(date?: Date): string {

        if (!date) {
            date = new Date();
        }

        return date.toLocaleDateString('en-US', this.onlyDate);
    }

    static getYearName() {
        let date = new Date();
        return date.getFullYear();
    }

    static getFormattedDate(date: string): string {
        let utcDate = new Date(date + 'Z'); // Z is to mention that its UTC format
        return utcDate.toLocaleDateString('en-US', this.onlyDate);
    }

    static formatUtcDate(date?: string): string {
        if (date) {
            let utcDate = new Date(date);
            return utcDate.toLocaleDateString('en-US', this.onlyDate);
        }
        return '';
    }

    static formatUtcDateWithInputFormat(date?: string, format: Intl.DateTimeFormatOptions = this.onlyDate): string {
        if (date) {
            let utcDate = new Date(date);
            return utcDate.toLocaleDateString('en-US', format);
        }
        return '';
    }
    static formatDateToDDMonYYYY(date?: string): string {
        if (date) {
            const utcDate = new Date(date);
            
            // Define options for formatting the date
            const options: Intl.DateTimeFormatOptions = {
                day: '2-digit',
                month: 'short', // abbreviated month name
                year: 'numeric'
            };

            // Format date to string
            const formattedDate = utcDate.toLocaleDateString('en-GB', options);

            // Swap the order if necessary
            const [day, month, year] = formattedDate.split(' ');
            return `${day} ${month} ${year}`;
        }
        return '';
    }
}

import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import { pageRoutes } from 'Pages';
import styles from './systempages.module.scss'

export const NotFound = () => {
    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        navigate(pageRoutes.home);
    };

    return (
        <div style={{ backgroundImage: "url('/images/home/systempage.png')", backgroundRepeat: 'no-repeat' }} className={styles.sytemPagesContainer}>
                <div>
                    <h1>
                        404
                    </h1>
                </div>
                <div className={styles.message}>
                    <h3>Not found.</h3>
                </div>
                <div>
                    <PrimaryButton text="Back to Home page" id='btnBackToHomeFromNotfound'
                        allowDisabledFocus
                        onClick={handleBackButtonClick}
                    />
                </div>
        </div>
    )
}

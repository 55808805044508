import { combineReducers } from 'redux'
import {
    KeyValueData,
    KeyValueDataActionType,
    ReduxAction,
    Template,
    TemplateActionType
} from 'Models/index';
import { RolesActionType } from 'Services';

const templatesReducer = (state = [], action: ReduxAction<Template>) => {
    switch (action.type) {
        case TemplateActionType.ADD_TEMPLATES:
            return { ...state, templates: action.payload };
        default: return state
    }
};

const commonReducer = (state = [], action: ReduxAction<KeyValueData>) => {
    switch (action.type) {
        case KeyValueDataActionType.ADD_NEW:
            return [...state, { ...action.payload }];
        default: return state
    }
}

const rolesReducer = (state = [], action: ReduxAction<string[]>) => {
    switch (action.type) {
        case RolesActionType.ADD_ROLES:
            return { ...state, roles: action.payload };
        default: return state
    }
};

const rootReducer = combineReducers({
    templates: templatesReducer,
    common: commonReducer,
    rolesInformation: rolesReducer
});

export default rootReducer;
import { Dimensions } from "./Dimensions";

/*
Keep Metrics in Alphabetical order
*/
export const Metrics = {
    ContentAPIFailure: {
        metricName: 'ContentAPIFailure',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.ApiStatus,
            Dimensions.ErrorCode,
            Dimensions.OperationStatus,
            Dimensions.ContentType
        ],
        enabled: true,
    },

    ContentAPICounter: {
        metricName: 'ContentAPICounter',
        dimensionList: [
            Dimensions.ApiName,
            Dimensions.OperationStatus,
            Dimensions.ContentType
        ],
        enabled: true,
    }
};

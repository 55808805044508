import { useState, useEffect } from 'react';
import {
    RequestFilter
} from 'Services';
import { fetchText } from 'Providers/Localization';
import { Template } from 'Models/template';
import { ClickthroughRequest } from 'Services/userTemplateSvc/models/ClickthroughRequest';
import { DemoUserTemplate } from 'Services/userTemplateSvc/models/DemoUserTemplate';
import { UserTemplateMgr } from 'Managers';

const TEMPLATE_STRING_PREFIX: string = 'TemplatePage.Templates';

export const useClickthroughRequests = (requestFilter: RequestFilter) => {
    const [myClickthroughs, setMyClickthroughs] = useState<ClickthroughRequest[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [unPinRequestLoader, setUnPinRequestLoader] = useState<boolean>(false);

    const getMyClickthroughs = async () => {
        let myClickthroughs: ClickthroughRequest[] = [];

        // Get User templates by Demo Type
        let userTemplates = await UserTemplateMgr.getUserTemplatesByDemoTypeAsync(requestFilter.DemoType);

        if (userTemplates && userTemplates.length > 0) {
            myClickthroughs = mapClickthroughResponse(userTemplates);
        }

        setMyClickthroughs(myClickthroughs);
        setIsLoading(false);
    }

    const unPinClickthroughRequest = async (clickthroughRequest: ClickthroughRequest) => {
        setUnPinRequestLoader(true);

        let userTemplateRequest: DemoUserTemplate = {
            TemplateId: clickthroughRequest.ClickthroughRequestId
        }

        // Remove User Template Mapping
        await UserTemplateMgr.removeUserTemplateMappingAsync(userTemplateRequest, requestFilter.DemoType);

        // Reset Loaders
        setUnPinRequestLoader(false);
        setIsLoading(true);
    }

    const openClickthrough = (clickthroughRequestUrl: string): void => {
        // Open Clickthrough Url
        UserTemplateMgr.openClickthrough(clickthroughRequestUrl);
    }

    const mapClickthroughResponse = (response: Template[]): ClickthroughRequest[] => {
        let localizedTemplatesContent = response.map(m => {
            return {
                ...m,
                templateUniqueName:m.name,
                name: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.Title`) || m.name, // return name as display name if cannot find localized string
                description: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.Description`) || m.name,
                primaryButtonText: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.PrimaryButtonText`),
                secondaryButtonText: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.SecondaryButtonText`),
                clickthroughUrl: fetchText(`${TEMPLATE_STRING_PREFIX}.${m.name}.ClickthroughUrl`)
            };
        });

        let clickthroughRequests: ClickthroughRequest[] = localizedTemplatesContent.map((template: Template) => {
            return {
                ClickthroughRequestId: template.id,
                TemplateName: template.name,
                TemplateIconUrl: template.iconUrl,
                TemplateDescription: template.description,
                Url: template.clickthroughUrl,
                TemplateUniqueName: template.templateUniqueName
            } as ClickthroughRequest;
        });

        return clickthroughRequests;
    }

    useEffect(() => {
        if (window.location.pathname.includes('clickthroughs')) {
            getMyClickthroughs();
            setCurrentPage(1);
        }
    }, [requestFilter, isLoading]);

    return {
        Loading: isLoading,
        Clickthroughs: myClickthroughs,
        currentPage,
        setCurrentPage,
        openClickthrough,
        unPinClickthroughRequest,
        unPinRequestLoader
    };
};
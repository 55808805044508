import { CommonDimensions } from './Dimensions';

//TO-DO Add more relevant metrics here
export const Metrics = {
    DemoHubClientAPIFailure: {
        metricName: 'DemoHubClientAPIFailure',
        dimensionList: [CommonDimensions.ApiName, CommonDimensions.ApiStatus, CommonDimensions.ErrorCode],
        enabled: true,
    },
    DemoHubClientAPICounter: {
        metricName: 'DemoHubClientAPICounter',
        dimensionList: [CommonDimensions.ApiName, CommonDimensions.ApiStatus],
        enabled: true,
    },
    DemoHubClientAPILatency: {
        metricName: 'DemoHubClientAPILatency',
        dimensionList: [CommonDimensions.ApiName],
        enabled: true,
    },
};

import { SpinnerSize } from '@fluentui/react';
import { DemoHubStatus } from 'Shared/enums';
import {
    IconLoader,
    Loader
} from 'Shared/Controls';
import styles from '../demorequests.module.scss';

interface StatusIconProps {
    status: DemoHubStatus;
    statusDescription?: string
}

export const StatusIcon = (props: StatusIconProps) => {

    const statusIconMapper =
    {
        [DemoHubStatus.NEW]: 'HourGlass',
        [DemoHubStatus.INPROGRESS]: '',
        [DemoHubStatus.SUCCEEDED]: 'CompletedSolid',
        [DemoHubStatus.FAILED]: 'Cancel'
    };

    const statusIconColorMapper =
    {
        [DemoHubStatus.NEW]: { color: styles.iconColorYellow },
        [DemoHubStatus.INPROGRESS]: { color: styles.iconColorYellow },
        [DemoHubStatus.SUCCEEDED]: { color: styles.iconColorGreen },
        [DemoHubStatus.FAILED]: { color: styles.iconColorRed }
    };

    const statusIcon: string = statusIconMapper[props.status] || 'Cancel';
    const colorClass: any = statusIconColorMapper[props.status] || {};

    if (props.status === DemoHubStatus.INPROGRESS) {
        return (
            <Loader
                Size={SpinnerSize.small}
                key="provisioning-status"
                Message=""
                ClassName={styles.provisioningStatusIcon} />
        )
    }

    return <IconLoader
        iconName={statusIcon}
        ariaLabel={props.statusDescription}
        key={statusIcon}
        className={colorClass.color}
    />
}
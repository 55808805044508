import { Textarea, TextareaValueData } from "@fluentai/textarea";
import { copilotConfig } from 'DemoHubCopilot';
import { useState } from "react";
import { PopoverTray } from '../popover-tray/PopoverTray';
import { CopilotRequest } from "../../models/CopilotRequest";

export interface QuestionProps {
    onSend: (copilotRequest: CopilotRequest) => void
}

export const QuestionInputBox = (props: QuestionProps) => {
    const [question, setQuestion] = useState<string>("");

    const onQuestionChange = (_ev: React.FormEvent<HTMLTextAreaElement>, newValue?: TextareaValueData) => {
        setQuestion(newValue?.value || "");
    };

    const onQuestionSend = (question:string) => {
        // send question to copilot
        const request: CopilotRequest = {
            question: question
        };

        props.onSend(request);

        // clear question textbox
        setQuestion("");
    }

    return (
        <>  
       
        <Textarea
            showCount={copilotConfig.showCharsCountCalculation}
            maxLength={copilotConfig.questionTextMaxChar}
            appearance="multi"
            aria-label="Copilot Chat"
            placeholder="Type in your response..."
            value={question}
            onChange={onQuestionChange}
            onSubmit={() => { onQuestionSend(question) }}
            send={{ title: 'Send' }}
/>
        </>
    );
};
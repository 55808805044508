import { useState, useEffect } from 'react';
import {
    DemoRequestSvc,
    RequestFilter,
    DemoRequest,
    DefaultAPIResponse
} from 'Services';
import { fetchText } from 'Providers/Localization';
import * as constants from 'Shared/constants';
import { DemoHubStatus } from 'Shared/enums';
import { DemoType } from 'Services/Enums';
import { logAndSetApiSuccessMetrics, logUnhandledExceptionAndSetMetrics, } from 'Shared/telemetry/telemetryHelper';
import { operationName } from 'Shared/constants';
import { ExceptionType } from 'Services/base/exceptionTypes';
import { TelemetryProperties } from 'Shared/interfaces/TelemetryProperties';
import { telemetryMessages } from 'Services/base/telemetryMessages';
import { Metrics } from '../telemetry/Metrics';
import { ErrorCode } from '../telemetry/ErrorCode';
import { ExtendedMap, IDimension } from 'Telemetry';
import { Dimensions } from '../telemetry';
import { telemetryLogger } from 'Providers/Telemetry';

const TEMPLATE_STRING_PREFIX: string = 'TemplatePage.Templates';

export const useMyRequests = (requestFilter: RequestFilter) => {
    const [myRequests, setMyRequests] = useState<DemoRequest[]>(DefaultAPIResponse);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [error, setError] = useState<any>(null);
    const [pollingRequired, setPollingRequired] = useState<boolean>(true);

    function callMyDemos() {

        let telemetryProps: TelemetryProperties = {
            operationName: operationName.DemoRequestSvcGetMyDemos,
            exceptionType: ExceptionType.DemoServiceException,
            defaultErrorCode: ErrorCode.FailedToFetchMyDemos
        }

        let dimensions = new ExtendedMap<IDimension, string>([
            [Dimensions.DemoType, DemoType[requestFilter.DemoType]]
        ]);

        try {
            DemoRequestSvc.myDemos(requestFilter).then(apiResult => {
                setIsLoading(false);
                setPollingRequired(false);

                if (!apiResult?.hasError) {
                    if (apiResult.data && apiResult.data.length > 0) {
                        // If Status Id is "1 (New)" or "2 (In Progress)" for any of the demos then we need polling to check updated demo status
                        const hasActiveRequest: boolean = apiResult.data.some((request) => request.Status === DemoHubStatus.NEW || request.Status === DemoHubStatus.INPROGRESS);
                        setPollingRequired(hasActiveRequest);
                        const data = apiResult.data.map(item => {
                        const demoScriptsUrl = fetchText(`${TEMPLATE_STRING_PREFIX}.${item.TemplateName}.DemoScriptsLink`);
                        const configurationStepsUrl = fetchText(`${TEMPLATE_STRING_PREFIX}.${item.TemplateName}.ConfigurationStepsLink`);
                            return {
                                ...item,
                                isDemoScriptsEnabled: demoScriptsUrl && demoScriptsUrl !== '' ? true : false,
                                isConfigurationStepsEnabled: configurationStepsUrl && configurationStepsUrl !== '' ? true : false
                            };
                        });
                        setMyRequests(data);
                    }

                    // Log trace and Set metrics
                    logAndSetApiSuccessMetrics(apiResult, { ...telemetryProps, message: telemetryMessages.fetchMyDemosSuccess }, Metrics.MyDemosAPICounter, dimensions);
                }
                else {
                    setError(error);
                    telemetryLogger.trackException('An error occurred while fetching the mydemos', error);
                }
            });
        } catch (error) {
            setError(error);

            logUnhandledExceptionAndSetMetrics(
                error,
                {
                    ...telemetryProps,
                    message: telemetryMessages.fetchMyDemosUnhandledException,
                    defaultErrorCode: ErrorCode.FetchDemosUnhandledError
                },
                Metrics.MyDemosAPIFailure,
                dimensions
            );
        }
    }

    /**
     * This hook will call the mydemos API every 30 seconds to check the updated status of the demo
     */
    useEffect(() => {
        const interval = setInterval(function () {
            // Call my demos only if polling required
            if (pollingRequired) {
                callMyDemos();
            }
        }, constants.DEMOLIST_REFRESH_DURATION)
        return () => {
            clearTimeout(interval);
        };
    }, [pollingRequired]);

    useEffect(() => {
        callMyDemos();
        setCurrentPage(1);
    }, [requestFilter]);

    const goToLink = (demoRequest: DemoRequest): void => {
        const demoAssetsUrl = fetchText(`${TEMPLATE_STRING_PREFIX}.${demoRequest.TemplateName}.DemoAssetsLink`);
        window.open(demoAssetsUrl, '_blank');
    }

    const goToDemoScriptsLink = (demoRequest: DemoRequest): void => {
        const demoScriptsUrl = fetchText(`${TEMPLATE_STRING_PREFIX}.${demoRequest.TemplateName}.DemoScriptsLink`);
        window.open(demoScriptsUrl, '_blank');
    }

    const goToConfigStepsLink = (demoRequest: DemoRequest): void => {
        const demoScriptsUrl = fetchText(`${TEMPLATE_STRING_PREFIX}.${demoRequest.TemplateName}.ConfigurationStepsLink`);
        window.open(demoScriptsUrl, '_blank');
    }

    const setEnvironmentLinkActivation = (demoRequest: DemoRequest): boolean => {
        // demo type specific Environment url activation
        if (requestFilter.DemoType === DemoType.shareddemos && demoRequest.Status !== DemoHubStatus.SUCCEEDED) {
            return false;
        }
        return true;
    }

    return {
        Loading: isLoading,
        DemoRequests: myRequests,
        GoToLinkHandler: goToLink,
        goToDemoScriptsLink,
        goToConfigStepsLink,
        currentPage,
        setCurrentPage,
        error,
        isEnvironmentLinkActive: setEnvironmentLinkActivation,
        loadDemos : callMyDemos
    };
};
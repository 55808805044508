import styles from '../../templatedetails-page.module.scss';
import { IconLoader } from 'Shared/Controls';

interface FieldsProps {
    fields: {
        [key: string]: { Value: string; IconName: string };
    };
}

export const FieldsListComponent = (props: FieldsProps) => {
    return (        
        <div>
            <div className={styles.fieldGrid}>
                {Object.entries(props.fields).map(([fieldName, fieldData]) => (
                    <div key={fieldName} className={styles.fieldRow}>
                        <div className={styles.fieldKey}>
                            <IconLoader iconName={fieldData.IconName} className={styles.icon} ariaLabel={fieldName} />
                            {fieldName}
                        </div>
                        <div className={styles.fieldValue}>{fieldData.Value}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

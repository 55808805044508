import { ICustomProperties, IMetricTelemetry } from '@microsoft/applicationinsights-web';
import * as constants from 'Shared/constants';
import { IDimension } from "./IDimension";
import { IDimensionData } from "./IDimensionData";
import { IMetric } from "./IMetric";
import { IMetricService } from "./IMetricService";
import { appInsights } from './ApplicationInsightsProvider';

export class AppInsightsMetricsService implements IMetricService {

    //
    /**
     * sets default properties for metrics
     * @returns custom properties to be sent to appInsights
     */
    getCustomProperties() {
        const customProperties: ICustomProperties = {
            clientCorrelationId: sessionStorage.getItem(constants.CORRELATION_ID)
        };
        return customProperties;
    }
    /**
     * sets dimensions for app insights
     * @param dimensionMap
     * @returns dimension data to be sent to appInsights
     */
    getDimensionData(dimensionMap: Map<IDimension, string>) {
        const dimensionData: IDimensionData = {};
        dimensionMap.forEach((value, dimension) => {
            const key = dimension.dimensionId.toString();
            dimensionData[key] = value;
        });
        return dimensionData;
    }
    /**
     * @param metric
     * @param dimensionMap
     * generic function to set metric and call appInsights trackMetric method to publish
     */
    set(metric: IMetric, dimensionMap: Map<IDimension, string>, value?: number): void {
        const metricData: IMetricTelemetry = {
            name: metric.metricName,
            average: value ?? 1, // To-Do : check the usage and make configurable
        }
        appInsights.trackMetric(metricData, { ...this.getDimensionData(dimensionMap), ...this.getCustomProperties() });
    }
}
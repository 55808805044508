import { DemoContract } from './demosSvc/demosSvcContract';
import { DemoService } from './demosSvc/demosSvc';

import { FeatureFlagContract } from './featureFlagSvc/featureFlagSvcContract';
import { FeatureFlagService } from './featureFlagSvc/featureFlagSvc';

import { UserContract } from './userSvc/userSvcContract';
import { UserService } from './userSvc/userSvc';

import { TemplatesContract } from './templatesSvc/templatesSvcContract';
import { TemplatesService } from './templatesSvc/templatesSvc';

import { NsatContract } from './nsatSvc/nsatSvcContract';
import { NsatService } from './nsatSvc/nsatSvc';

import { RoleService } from './roleSvc/roleSvc';
import { RoleContract } from './roleSvc/roleSvcContract';

import { ContentService } from './contentSvc/contentSvc';
import { ContentContract } from './contentSvc/contentSvcContract';

import { UserTemplateService } from './userTemplateSvc/userTemplateSvc';
import { UserTemplateContract } from './userTemplateSvc/userTemplateSvcContract';

import { CopilotService } from './copilotSvc/copilotSvc';
import { CopilotSvcContract } from './copilotSvc/copilotSvcContract';

export * from './demosSvc/models/DemoRequest';
export * from './demosSvc/models/NewDemo';
export * from './demosSvc/models/DemoProvisioning';
export * from './demosSvc/models/RefreshDemoRequest';

export * from './roleSvc/models/Roles';

export type { EligibilityResponse } from './nsatSvc/models/EligibilityResponse';

export const UserRequestSvc: UserContract = new UserService();
export const DemoRequestSvc: DemoContract = new DemoService();
export const FeatureFlagSvc: FeatureFlagContract = new FeatureFlagService();
export const TemplatesSvc: TemplatesContract = new TemplatesService();
export const NsatSvc: NsatContract = new NsatService();
export const ContentSvc: ContentContract = new ContentService();
export const RoleSvc: RoleContract = new RoleService();
export const UserTemplateSvc: UserTemplateContract = new UserTemplateService();
export const CopilotSvc: CopilotSvcContract = new CopilotService();
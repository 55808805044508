import { Template } from 'Models';
import { createEnableMocksFn } from 'Shared/utils/mock-utilities';
import { ApiResult } from '../base/models';
import { ApiName } from '../base/enums';
import { create } from '../base/client';
import { apiSuccessResponse, apiFailureResponse } from '../base/getApiResponse';
import { DemoType } from 'Services/Enums';
import { UserTemplateContract } from './userTemplateSvcContract';
import { DemoUserTemplate } from './models/DemoUserTemplate';
import { mapApiResponse, mapApiResponseTemplate } from './userTemplate.transform';

const client = create(
    ApiName.UserTemplates,
    createEnableMocksFn(async () => import('./userTemplateSvc.mock'))
);

export class UserTemplateService implements UserTemplateContract {
    public getUserTemplatesByDemoType = async (demoType: DemoType): Promise<ApiResult<Template[]>> => {
        try {
            let apiEndpoint = `usertemplates?demotype=${demoType}`;
            const response = await client.get(apiEndpoint);
            return apiSuccessResponse(response, mapApiResponse);
        } catch (error: any) {
            return apiFailureResponse(error,
                {
                    errorCode: error?.data?.errorCode,
                    errorMessage: error?.data
                });
        }
    }

    public addUserTemplateMappingAsync = async (demoUserTemplate: DemoUserTemplate): Promise<ApiResult<string>> => {
        try {
            const response = await client.post('usertemplates', demoUserTemplate);
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error,
                {
                    errorCode: error?.data?.errorCode,
                    errorMessage: error?.data
                });
        }
    }

    public removeUserTemplateMappingAsync = async (demoUserTemplate: DemoUserTemplate): Promise<ApiResult<string>> => {
        try {
            const response = await client.delete('usertemplates', { data: demoUserTemplate });
            return apiSuccessResponse(response);
        } catch (error: any) {
            return apiFailureResponse(error,
                {
                    errorCode: error?.data?.errorCode,
                    errorMessage: error?.data
                });
        }
    }

    public getUserTemplateByName = async (templateName : string): Promise<ApiResult<Template>> => {
        try {
            let apiEndpoint = `template/byname?templateName=${templateName}`;
            const response = await client.get(apiEndpoint);
            return apiSuccessResponse(response, mapApiResponseTemplate);
        } catch (error: any) {
            return apiFailureResponse(error,
                {
                    errorCode: error?.data?.errorCode,
                    errorMessage: error?.data
                });
        }
    }
}
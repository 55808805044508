export const CORRELATION_REQUEST_HEADER = 'x-ms-correlation-request-id';
export const CORRELATIONID_RESPONSE_HEADER = 'x-ms-correlation-id';
export const ACCESS_TOKEN_ERROR = 'Invalid Access Token';
export const UNEXPECTED_ERROR = 'Unexpected error';
export const UNKNOWN_STATUS = 'Unknown';
export const UNKNOWN_API = 'Unknown API';
export const OK_STATUS = '200';

export const operationName = {
    AuthInterceptorsAddAadToken: 'authInterceptors.addAadToken',
}
import { Navigate, Outlet } from 'react-router-dom';
import { SpinnerSize } from '@fluentui/react';
import { Loader } from 'Shared/Controls';
import { AuthProvider } from 'Providers/Authentication';
import { Navbar, SignIn, pageRoutes } from 'Pages';
import { CommonUtils } from 'Shared/utils/common-utils';
import { useGlobalContext } from 'Providers/ContextProvider/GlobalContextProvider';

export const AuthorizationProvider = () => {
    const { isUserRolesLoading, validAccess, error, isContentLoading } = useGlobalContext();
    const isAuthenticated = AuthProvider.isAuthenticated();

    if (!isAuthenticated) {
        return <SignIn />
    }

    if (!validAccess && CommonUtils.redirectToUnAuthorizedPage(error)) {
        return <Navigate to={pageRoutes.unauthorized} replace />
    }

    if (CommonUtils.redirectToErrorPage(error)) {
        return <Navigate to="/error" state={error} />
    }

    if (isUserRolesLoading || isContentLoading) {
        return <Loader
            Message="Please wait.."
            Size={SpinnerSize.large} />
    }

    if (validAccess) {
        return <>
            <Navbar />
            <Outlet />
        </>
    }

    return <Navigate to="/unauthorized" replace />
};
import {
    IIconProps,
    IconButton
} from '@fluentui/react';
import { BaseProps } from '../base/ComponentProps';

interface IIconButtonProps extends BaseProps {
    iconName: string;
    className?: string;
    disabled?: boolean;
    ariaLabel?: string;
    buttonTitle?: string;
    onClick?: () => void;
    id?: string;
}

export const ButtonIcon = (props: IIconButtonProps) => {
    const iconProps: IIconProps = { iconName: props.iconName };

    const OnlyIconButton = <IconButton
        id={props.id}
        iconProps={iconProps}
        className={props.className}
        title={props.ariaLabel}
        ariaLabel={props.ariaLabel}
        onClick={props.onClick}
        disabled={props.disabled}
        {...props.dataAttributes }
    />

    if (props?.buttonTitle?.length && props?.buttonTitle?.length > 0) {
        return (
            <div className={props.className}>
                {OnlyIconButton}
                <span>{props.buttonTitle}</span>
            </div>
        )
    }

    return OnlyIconButton;
};
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { CORRELATION_REQUEST_HEADER } from '../constants';
import { telemetryLogger } from 'Providers/Telemetry';
import { operationName } from 'Shared/constants';
import { OperationStatus } from 'Shared/enums';
import { ExtendedMap, IDimension, Dimensions, metricService, Metrics } from 'Telemetry';
import { ErrorCode } from '../errorCodes';
import { getErrorCode } from 'Shared/telemetry/telemetryHelper';

const isAbsoluteUrl = (s: string) => {
    return !!s.match(/^https?:\/\//i);
};

const safeguard = (action: () => void) => {
    try {
        action();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};


const trackRequestData = (request: AxiosRequestConfig, defaultStatus: number, isError?: boolean) => {

    let dimensions = new ExtendedMap<IDimension, string>([
        [Dimensions.ApiName, request.apiMethod ?? operationName.Unknown]
    ]);

    const latestReqInfo = request.bapDemosRequestInfo?.pop();
    const retryCount = request?.bapDemosRequestInfo?.length || 2;

    const endTime = latestReqInfo?.endTime ?? performance.now();
    const startTime = latestReqInfo?.startTime ?? performance.now();
    const correlationId = latestReqInfo?.correlationId ?? request?.headers?.[CORRELATION_REQUEST_HEADER];
    const status = latestReqInfo?.status ?? defaultStatus;
    const success = !isError && status >= 200 && status < 400; // 310,309 are custom statuses for new demo validation errors

    const elapsedTime = endTime - (latestReqInfo?.startTime ?? 0);
    const requestUrl = request?.url ?? ''; // TODO: revisit this
    const absoluteUrl = isAbsoluteUrl(requestUrl) ? requestUrl : request?.baseURL + requestUrl;
    const method = request?.method?.toLocaleUpperCase();

    const trackDate: Date = new Date(performance.timeOrigin + startTime);

    // Set latency metrics
    metricService.set(Metrics.DemoHubClientAPILatency, dimensions, elapsedTime);

    dimensions.addDimension(Dimensions.ApiStatus, status.toString());

    if (success) {
        dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.SUCCESS);
        dimensions.addDimension(Dimensions.ErrorCode, ErrorCode.NoError);
    } else {
        dimensions.addDimension(Dimensions.OperationStatus, OperationStatus.FAILURE);
        dimensions.addDimension(Dimensions.ErrorCode, getErrorCode(request, ErrorCode.DemoHubApiFailureException));
    }

    metricService.set(success ? Metrics.DemoHubClientAPICounter : Metrics.DemoHubClientAPIFailure, dimensions)

    telemetryLogger.trackOutgoingRequest(
        `${method} ${request.apiMethod}`,
        absoluteUrl,
        elapsedTime,
        success,
        status,
        correlationId,
        retryCount,
        trackDate
    );
};

export const loggingInterceptors = {
    logRequest: (conf: AxiosRequestConfig) => {
        conf.apiMethod = conf.url;
        (conf.bapDemosRequestInfo ??= []).push({
            startTime: performance.now(),
            correlationId: conf.headers?.[CORRELATION_REQUEST_HEADER]
        });

        return conf;
    },

    logResponse: (res: AxiosResponse) => {
        safeguard(() => {
            const request = res.config;
            trackRequestData(request, res.status);
        });

        return res;
    },

    logResponseError: async (error: AxiosError) => {

        const request = error?.config;
        if (!request) {
            return Promise.reject(error);
        }

        safeguard(() => {
            const res = error.response;
            trackRequestData(request, res?.status || 0, true);
            telemetryLogger.trackException(`An error occurred in ${request?.method} ${request?.baseURL} ${request?.url}`, res?.data)
        });

        return Promise.reject(error);
    }
};
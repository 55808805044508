import { useState } from 'react';
import {
    ComboboxProps,
    InputProps
} from "@fluentui/react-components";
import { CopilotRequest, DataGenerationProvidedInput, ChatActionResponseType, AgentType } from 'DemoHubCopilot/models/CopilotRequest';
import { dataGenApps, dataGenEntities, InputOption } from 'DemoHubCopilot/copilot.settings';

const defaultFormValues: DataGenerationProvidedInput = {
    msxId: '',
    industry: '',
    apps: [],
    entities: [],
    noOfRecords: 10
}

var selectedFormData: DataGenerationProvidedInput = {
    msxId: '',
    industry: '',
    apps: [],
    entities: [],
    noOfRecords: 10
};

export interface DataGenerationInputParams {
    onPromptClick: (copilotRequest: CopilotRequest) => void;
    dataGenerationProvidedInput?: DataGenerationProvidedInput;
    disableForm?: boolean;
}

export const useDataGenerationForm = (hooksParams: DataGenerationInputParams) => {
    const [showInputFields, setShowInputFields] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<DataGenerationProvidedInput>(defaultFormValues);
    const [filteredEntities, setFilteredEntities] = useState<InputOption[]>([]);
    const [filteredApps, setFilteredApps] = useState<InputOption[]>([]);
    const [nlpPromptInput, setNlpPromptInput] = useState<string>('');

    const onRequiredDetailClicks = () => {
        setShowInputFields(true);
    }

    const onIndustryChange = (e: any, item?: string) => {
        if (item) {
            let filteredItems = dataGenApps.filter(f => f.industry === item);
            let apps: InputOption[] = [];
            if (filteredItems && filteredItems.length) {
                apps = filteredItems[0].apps;
            }
            setFilteredApps(apps);
            setFilteredEntities([]);
            setFormValues({
                ...formValues,
                industry: item,
                apps: [],
                entities: []
            });
        }
    }

    const onAppsChange: ComboboxProps["onOptionSelect"] = (event, data) => {
        selectedFormData.apps = data.selectedOptions;
    }

    // update value to selected options on blur
    const onAppsBlur = () => {

        let filteredItems = dataGenEntities.filter(f => f.industry === formValues?.industry && selectedFormData.apps.some(i => i === f.app));
        let entities: InputOption[] = [];
        if (filteredItems && filteredItems.length) {
            // Extract all entities from each item in filteredItems
            let allEntities: InputOption[] = [];
            for (let item of filteredItems) {
                allEntities = [...allEntities, ...item.entities];
            }
        
            // Remove duplicates
            for (let i = 0; i < allEntities.length; i++) {
                let isDuplicate = false;
                for (let j = 0; j < entities.length; j++) {
                    if (allEntities[i].name === entities[j].name && allEntities[i].name === entities[j].name) {
                        isDuplicate = true;
                        break;
                    }
                }
                if (!isDuplicate) {
                    entities.push(allEntities[i]);
                }
            }
        }

        setFilteredEntities(entities);

        setFormValues({
            ...formValues,
            apps: selectedFormData.apps,
            entities: []
        });
    };

    const onEntitiesChange: ComboboxProps["onOptionSelect"] = (event, data) => {
        selectedFormData.entities = data.selectedOptions;
    }

    const onEntitiesBlur = () => {
        setFormValues({
            ...formValues,
            entities: selectedFormData.entities
        });
    }

    const onInputCollectedActionClick = () => {
        hooksParams.onPromptClick({
            question: "",
            chatResponse: {
                role: AgentType.User,
                content: "Required details are as below.",
                responseActions: [
                    {
                        type: ChatActionResponseType.DatagenFormSelectedInputView,
                        actionData: formValues
                    }
                ]
            }
        });
    }

    const onInputPreviewCtaClick = () => {
        hooksParams.onPromptClick({
            question: "",
            chatResponse: {
                role: AgentType.Copilot,
                content: "Please describe your data to continue. Add as much information as possible to customize the data.",
                responseActions: [
                    {
                        type: ChatActionResponseType.DatagenFormCustomPromptView,
                        actionData: hooksParams.dataGenerationProvidedInput
                    }
                ]
            }
        });
    }

    const onFinalActionClick = () => {
        hooksParams.onPromptClick({
            question: `Generate ${hooksParams.dataGenerationProvidedInput?.noOfRecords} rows of data for ${(hooksParams.dataGenerationProvidedInput?.entities || []).join(", ")} Entities in ${hooksParams.dataGenerationProvidedInput?.industry} Industry for Apps ${(hooksParams.dataGenerationProvidedInput?.apps || []).join(", ")}. \n Description: ${nlpPromptInput}`,
            datagenerationRequest: hooksParams.dataGenerationProvidedInput,
            disableForm:true
        });       
    }

    const isFormSubmissionDisabled = () => {
        if (formValues.apps?.length === 0 ||
            formValues.entities?.length === 0 ||
            formValues.industry?.length === 0 ||
            (formValues.noOfRecords && formValues.noOfRecords <= 0)) {
            return true;
        }

        return false;
    }

    return {
        showInputFields,
        formValues,
        setFormValues,
        filteredEntities,
        filteredApps,
        nlpPromptInput,
        setNlpPromptInput,
        onRequiredDetailClicks,
        onIndustryChange,
        onAppsBlur,
        onAppsChange,
        onEntitiesBlur,
        onEntitiesChange,
        onFinalActionClick,
        onInputCollectedActionClick,
        onInputPreviewCtaClick,
        disableFormSubmitButton: isFormSubmissionDisabled()
    }
}